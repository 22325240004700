export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: any;
  Decimal4: any;
  Duration: any;
  ExchangeJSON: any;
  GraphQLUBigInt64: any;
  JSON: any;
  StringOrInt: any;
  Upload: any;
  _text: any;
  bigint: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

export type AccountNetworkCreateParams = {
  allow_pmp_third_party_revenue_write?: InputMaybe<Scalars['Boolean']>;
  dmp_sources?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  partner_uid?: InputMaybe<Scalars['String']>;
  third_party_partners?: InputMaybe<Array<Scalars['String']>>;
};

export type AccountNetworkUpdateParams = {
  allow_pmp_third_party_revenue_write?: InputMaybe<Scalars['Boolean']>;
  dmp_sources?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  partner_uid?: InputMaybe<Scalars['String']>;
  third_party_partners?: InputMaybe<Array<Scalars['String']>>;
};

export type AudienceReportStatusResponse = {
  __typename?: 'AudienceReportStatusResponse';
  id: Scalars['uuid'];
  report_status?: Maybe<Scalars['String']>;
};

export type AudienceShare = {
  __typename?: 'AudienceShare';
  account_id?: Maybe<Scalars['String']>;
  account_uid?: Maybe<Scalars['String']>;
  audience_external_openaudience_id?: Maybe<Scalars['String']>;
  cpm_cap?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instance_uid?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['String']>;
  recipient_account_id?: Maybe<Scalars['String']>;
  recipient_account_name?: Maybe<Scalars['String']>;
  revenue_method?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  segment_account?: Maybe<Scalars['String']>;
  segment_id?: Maybe<Scalars['String']>;
  segment_provider?: Maybe<Scalars['String']>;
  third_party_revenue?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['String']>;
};

export type AudienceShareCreateParams = {
  audience_external_openaudience_id: Scalars['String'];
  cpm_cap?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  recipient_account_id: Scalars['String'];
  revenue_method?: InputMaybe<Scalars['String']>;
  third_party_revenue?: InputMaybe<Scalars['String']>;
};

export type AudienceShareDeleteResult = AudienceShareDeleted | Error;

export type AudienceShareDeleted = {
  __typename?: 'AudienceShareDeleted';
  deletedUID: Scalars['uuid'];
};

export type AudienceShareResult = AudienceShare | Error;

export type AudienceShareUpdateParams = {
  third_party_revenue: Scalars['String'];
  uid: Scalars['uuid'];
};

export type Audience_Categories_Reach_All = {
  __typename?: 'Audience_Categories_Reach_All';
  connectedtv?: Maybe<Scalars['GraphQLUBigInt64']>;
  cookiesWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  desktop?: Maybe<Scalars['GraphQLUBigInt64']>;
  display?: Maybe<Scalars['GraphQLUBigInt64']>;
  idlXiWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  idlXyWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  ip?: Maybe<Scalars['GraphQLUBigInt64']>;
  ipWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  mobileDevicesWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  mobileapp?: Maybe<Scalars['GraphQLUBigInt64']>;
  mobileweb?: Maybe<Scalars['GraphQLUBigInt64']>;
  sfAccountId?: Maybe<Scalars['String']>;
  sfAccountName?: Maybe<Scalars['String']>;
  tablet?: Maybe<Scalars['GraphQLUBigInt64']>;
  total?: Maybe<Scalars['GraphQLUBigInt64']>;
  totalmaid5?: Maybe<Scalars['GraphQLUBigInt64']>;
  totalmaip?: Maybe<Scalars['GraphQLUBigInt64']>;
  totalmau?: Maybe<Scalars['GraphQLUBigInt64']>;
  uniqueConnectedTVDeviceIDsWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  uniqueUsersViaConnectedTVWithRequests?: Maybe<Scalars['GraphQLUBigInt64']>;
  video?: Maybe<Scalars['GraphQLUBigInt64']>;
};

export type BaseError = {
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CheckUserPermissionsResponse = {
  __typename?: 'CheckUserPermissionsResponse';
  forceRefreshToken?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ConnectionError = BaseError & {
  __typename?: 'ConnectionError';
  message: Scalars['String'];
};

export type DealGroupInfoOutput = {
  __typename?: 'DealGroupInfoOutput';
  count: Scalars['Int'];
  deal_id: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
};

export type ExchangeDeal = {
  __typename?: 'ExchangeDeal';
  created_date: Scalars['timestamptz'];
  currency: Scalars['String'];
  deal_id: Scalars['String'];
  deal_participants?: Maybe<Array<Maybe<ExchangeDealParticipants>>>;
  deal_price?: Maybe<Scalars['Decimal']>;
  enable_dv360_inventory_package?: Maybe<Scalars['Boolean']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['ExchangeJSON']>;
  expected_ad_category?: Maybe<Scalars['String']>;
  frequency_capping: Array<Maybe<FrequencyCapping>>;
  id: Scalars['String'];
  is_pub_visible: Scalars['Boolean'];
  modified_date: Scalars['timestamptz'];
  name: Scalars['String'];
  package?: Maybe<Package>;
  pmp_deal_type: Scalars['String'];
  rbo_config?: Maybe<RboConfig>;
  start_date: Scalars['timestamptz'];
  status: Scalars['String'];
  third_party_fees?: Maybe<Array<ExchangeThirdPartyFee>>;
  third_party_fees_config?: Maybe<Array<Maybe<ExchangeThirdPartyFeesConfig>>>;
  third_party_revenue?: Maybe<ExchangeThirdPartyRevenue>;
  uid: Scalars['String'];
};

export type ExchangeDealCreateParams = {
  account_uid?: InputMaybe<Scalars['String']>;
  created_with?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  deal_id?: InputMaybe<Scalars['String']>;
  deal_participants: Array<ExchangeDealParticipantsCreateParams>;
  deal_price?: InputMaybe<Scalars['Decimal']>;
  enable_dv360_inventory_package?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  expected_ad_category?: InputMaybe<Scalars['String']>;
  is_pub_visible?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  package_id?: InputMaybe<Scalars['String']>;
  package_uid?: InputMaybe<Scalars['String']>;
  pmp_deal_type: Scalars['String'];
  rbo_config?: InputMaybe<RboConfigParams>;
  start_date: Scalars['timestamptz'];
  status?: InputMaybe<Scalars['String']>;
  third_party_fees_config?: InputMaybe<Array<ExchangeThirdPartyFeesConfigCreateParams>>;
  third_party_revenue?: InputMaybe<ExchangeThirdPartyRevenueCreateParams>;
};

export type ExchangeDealForecastingDealParams = {
  created_with?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  deal_id?: InputMaybe<Scalars['String']>;
  deal_participants: Array<ExchangeDealParticipantsCreateParams>;
  deal_price?: InputMaybe<Scalars['Decimal']>;
  enable_dv360_inventory_package?: InputMaybe<Scalars['Boolean']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  is_pub_visible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  pmp_deal_type: Scalars['String'];
  start_date?: InputMaybe<Scalars['timestamptz']>;
  third_party_fees_config?: InputMaybe<Array<ExchangeThirdPartyFeesConfigCreateParams>>;
  third_party_revenue?: InputMaybe<ExchangeThirdPartyRevenueCreateParams>;
};

export type ExchangeDealParticipants = {
  __typename?: 'ExchangeDealParticipants';
  brand_ids?: Maybe<Scalars['ExchangeJSON']>;
  buyer_ids?: Maybe<Scalars['ExchangeJSON']>;
  demand_partner: Scalars['String'];
};

export type ExchangeDealParticipantsCreateParams = {
  brand_ids?: InputMaybe<Array<Scalars['StringOrInt']>>;
  buyer_ids?: InputMaybe<Array<Scalars['StringOrInt']>>;
  demand_partner: Scalars['StringOrInt'];
};

export type ExchangeDealParticipantsUpdateParams = {
  brand_ids?: InputMaybe<Array<Scalars['StringOrInt']>>;
  buyer_ids?: InputMaybe<Array<Scalars['StringOrInt']>>;
  demand_partner: Scalars['StringOrInt'];
};

export type ExchangeDealUpdateParams = {
  deal_participants?: InputMaybe<Array<ExchangeDealParticipantsUpdateParams>>;
  deal_price?: InputMaybe<Scalars['Decimal']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  expected_ad_category?: InputMaybe<Scalars['String']>;
  frequency_capping?: InputMaybe<Array<FrequencyCappingInput>>;
  is_pub_visible?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  package_id?: InputMaybe<Scalars['String']>;
  pmp_deal_type?: InputMaybe<Scalars['String']>;
  rbo_config?: InputMaybe<RboConfigParams>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  third_party_fees_config?: InputMaybe<Array<ExchangeThirdPartyFeesConfigUpdateParams>>;
  third_party_revenue?: InputMaybe<ExchangeThirdPartyRevenueUpdateParams>;
};

export type ExchangeThirdPartyFee = {
  __typename?: 'ExchangeThirdPartyFee';
  cpm_cap?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  fee_type?: Maybe<Scalars['String']>;
  partner_id: Scalars['String'];
  revenue_method: Scalars['String'];
  share?: Maybe<Scalars['String']>;
};

export type ExchangeThirdPartyFeesConfig = {
  __typename?: 'ExchangeThirdPartyFeesConfig';
  gross_cpm_cap?: Maybe<Scalars['String']>;
  gross_share?: Maybe<Scalars['String']>;
  partner_id: Scalars['String'];
  platform_partner_id?: Maybe<Scalars['String']>;
  platform_share?: Maybe<Scalars['String']>;
  revenue_method: Scalars['String'];
};

export type ExchangeThirdPartyFeesConfigCreateParams = {
  gross_cpm_cap?: InputMaybe<Scalars['Decimal']>;
  gross_share?: InputMaybe<Scalars['Decimal']>;
  partner_id: Scalars['String'];
  platform_partner_id?: InputMaybe<Scalars['String']>;
  platform_share?: InputMaybe<Scalars['Decimal']>;
  revenue_method: Scalars['String'];
};

export type ExchangeThirdPartyFeesConfigUpdateParams = {
  gross_cpm_cap?: InputMaybe<Scalars['Decimal']>;
  gross_share?: InputMaybe<Scalars['Decimal']>;
  partner_id: Scalars['String'];
  platform_partner_id?: InputMaybe<Scalars['String']>;
  platform_share?: InputMaybe<Scalars['Decimal']>;
  revenue_method: Scalars['String'];
};

export type ExchangeThirdPartyRevenue = {
  __typename?: 'ExchangeThirdPartyRevenue';
  cpm_cap?: Maybe<Scalars['Decimal']>;
  partner: Scalars['String'];
  revenue_method: Scalars['String'];
  share?: Maybe<Scalars['Decimal']>;
};

export type ExchangeThirdPartyRevenueCreateParams = {
  cpm_cap?: InputMaybe<Scalars['Decimal']>;
  partner: Scalars['String'];
  revenue_method: Scalars['String'];
  share?: InputMaybe<Scalars['Decimal']>;
};

export type ExchangeThirdPartyRevenueUpdateParams = {
  cpm_cap?: InputMaybe<Scalars['Decimal']>;
  partner: Scalars['String'];
  revenue_method: Scalars['String'];
  share?: InputMaybe<Scalars['Decimal']>;
};

export type FrequencyCapping = {
  __typename?: 'FrequencyCapping';
  impressions_limit: Scalars['String'];
  time_period_unit: Scalars['String'];
  time_period_value: Scalars['Int'];
};

export type FrequencyCappingInput = {
  impressions_limit: Scalars['String'];
  time_period_unit: Scalars['String'];
  time_period_value: Scalars['Int'];
};

export type GeoSearchOutput = {
  __typename?: 'GeoSearchOutput';
  city?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  dma?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  msa?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  type_id: Scalars['String'];
};

export type InputFieldValidation = {
  __typename?: 'InputFieldValidation';
  field: Scalars['String'];
  message: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InvPackageSearchOutput = {
  __typename?: 'InvPackageSearchOutput';
  account_uid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  parent_name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  type_full?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

export type InvSearchOperatorOutput = {
  __typename?: 'InvSearchOperatorOutput';
  account_uid?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  instance_uid: Scalars['String'];
  name: Scalars['String'];
  parent_name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  type_full?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

export type InvalidInputError = BaseError & {
  __typename?: 'InvalidInputError';
  inputs: Array<InputFieldValidation>;
  message: Scalars['String'];
};

export type ModifySegmentResponse = {
  __typename?: 'ModifySegmentResponse';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateAudience?: Maybe<Scalars['JSON']>;
  activateAudienceV2?: Maybe<Scalars['JSON']>;
  addUsersToSegment: ModifySegmentResponse;
  /** Archive a network account in MGMT API, and return the modified account, the id is the account id in the MGMT API */
  archiveAccountNetwork?: Maybe<Scalars['ExchangeJSON']>;
  archiveDealAndPackage: Scalars['String'];
  archivePackage?: Maybe<Scalars['String']>;
  audienceShareCreate: Array<Maybe<AudienceShareResult>>;
  audienceShareDelete: Array<AudienceShareDeleteResult>;
  audienceShareUpdate: Array<Maybe<AudienceShareResult>>;
  cloneAudience?: Maybe<Audience>;
  clonePackage: Package;
  /** Create a network account in MGMT API, and return the modified account */
  createAccountNetwork?: Maybe<Scalars['ExchangeJSON']>;
  createDealAndPackage: ExchangeDeal;
  createDealForecastingJob?: Maybe<Scalars['ExchangeJSON']>;
  createDealTroubleshootingJob?: Maybe<Scalars['ExchangeJSON']>;
  createLog?: Maybe<LogResponse>;
  createPackage: Package;
  createSafeDealAndPackage: SafeExchangeDeal;
  createServiceAccountUnderParentAccount?: Maybe<Scalars['JSON']>;
  createUserUnderParentAccount?: Maybe<Scalars['JSON']>;
  createUserUnderRootAccount?: Maybe<Scalars['JSON']>;
  /** delete data from the table: "account" */
  delete_account?: Maybe<Account_Mutation_Response>;
  /** delete single row from the table: "account" */
  delete_account_by_pk?: Maybe<Account>;
  /** delete data from the table: "account_feature_flags" */
  delete_account_feature_flags?: Maybe<Account_Feature_Flags_Mutation_Response>;
  /** delete single row from the table: "account_feature_flags" */
  delete_account_feature_flags_by_pk?: Maybe<Account_Feature_Flags>;
  /** delete data from the table: "account_provider" */
  delete_account_provider?: Maybe<Account_Provider_Mutation_Response>;
  /** delete single row from the table: "account_provider" */
  delete_account_provider_by_pk?: Maybe<Account_Provider>;
  /** delete data from the table: "audience" */
  delete_audience?: Maybe<Audience_Mutation_Response>;
  /** delete single row from the table: "audience" */
  delete_audience_by_pk?: Maybe<Audience>;
  /** delete data from the table: "audience_segment" */
  delete_audience_segment?: Maybe<Audience_Segment_Mutation_Response>;
  /** delete single row from the table: "audience_segment" */
  delete_audience_segment_by_pk?: Maybe<Audience_Segment>;
  /** delete data from the table: "bq_resource" */
  delete_bq_resource?: Maybe<Bq_Resource_Mutation_Response>;
  /** delete single row from the table: "bq_resource" */
  delete_bq_resource_by_pk?: Maybe<Bq_Resource>;
  /** delete data from the table: "deal" */
  delete_deal?: Maybe<Deal_Mutation_Response>;
  /** delete single row from the table: "deal" */
  delete_deal_by_pk?: Maybe<Deal>;
  /** delete data from the table: "deal_package" */
  delete_deal_package?: Maybe<Deal_Package_Mutation_Response>;
  /** delete data from the table: "export" */
  delete_export?: Maybe<Export_Mutation_Response>;
  /** delete single row from the table: "export" */
  delete_export_by_pk?: Maybe<Export>;
  /** delete data from the table: "export_status_timeline" */
  delete_export_status_timeline?: Maybe<Export_Status_Timeline_Mutation_Response>;
  /** delete single row from the table: "export_status_timeline" */
  delete_export_status_timeline_by_pk?: Maybe<Export_Status_Timeline>;
  /** delete data from the table: "feature_flags" */
  delete_feature_flags?: Maybe<Feature_Flags_Mutation_Response>;
  /** delete single row from the table: "feature_flags" */
  delete_feature_flags_by_pk?: Maybe<Feature_Flags>;
  /** delete data from the table: "hasura_package" */
  delete_hasura_package?: Maybe<Hasura_Package_Mutation_Response>;
  /** delete single row from the table: "hasura_package" */
  delete_hasura_package_by_pk?: Maybe<Hasura_Package>;
  /** delete data from the table: "hasura_package_list" */
  delete_hasura_package_list?: Maybe<Hasura_Package_List_Mutation_Response>;
  /** delete data from the table: "provider" */
  delete_provider?: Maybe<Provider_Mutation_Response>;
  /** delete single row from the table: "provider" */
  delete_provider_by_pk?: Maybe<Provider>;
  /** delete data from the table: "segment" */
  delete_segment?: Maybe<Segment_Mutation_Response>;
  /** delete single row from the table: "segment" */
  delete_segment_by_pk?: Maybe<Segment>;
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>;
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>;
  exportAudience?: Maybe<Scalars['JSON']>;
  exportAudienceBulk?: Maybe<Scalars['JSON']>;
  generate_reach_report_overall?: Maybe<AudienceReportStatusResponse>;
  generate_reach_report_publisher?: Maybe<AudienceReportStatusResponse>;
  /** insert data into the table: "account" */
  insert_account?: Maybe<Account_Mutation_Response>;
  /** insert data into the table: "account_feature_flags" */
  insert_account_feature_flags?: Maybe<Account_Feature_Flags_Mutation_Response>;
  /** insert a single row into the table: "account_feature_flags" */
  insert_account_feature_flags_one?: Maybe<Account_Feature_Flags>;
  /** insert a single row into the table: "account" */
  insert_account_one?: Maybe<Account>;
  /** insert data into the table: "account_provider" */
  insert_account_provider?: Maybe<Account_Provider_Mutation_Response>;
  /** insert a single row into the table: "account_provider" */
  insert_account_provider_one?: Maybe<Account_Provider>;
  /** insert data into the table: "audience" */
  insert_audience?: Maybe<Audience_Mutation_Response>;
  /** insert a single row into the table: "audience" */
  insert_audience_one?: Maybe<Audience>;
  /** insert data into the table: "audience_segment" */
  insert_audience_segment?: Maybe<Audience_Segment_Mutation_Response>;
  /** insert a single row into the table: "audience_segment" */
  insert_audience_segment_one?: Maybe<Audience_Segment>;
  /** insert data into the table: "bq_resource" */
  insert_bq_resource?: Maybe<Bq_Resource_Mutation_Response>;
  /** insert a single row into the table: "bq_resource" */
  insert_bq_resource_one?: Maybe<Bq_Resource>;
  /** insert data into the table: "deal" */
  insert_deal?: Maybe<Deal_Mutation_Response>;
  /** insert a single row into the table: "deal" */
  insert_deal_one?: Maybe<Deal>;
  /** insert data into the table: "deal_package" */
  insert_deal_package?: Maybe<Deal_Package_Mutation_Response>;
  /** insert a single row into the table: "deal_package" */
  insert_deal_package_one?: Maybe<Deal_Package>;
  /** insert data into the table: "export" */
  insert_export?: Maybe<Export_Mutation_Response>;
  /** insert a single row into the table: "export" */
  insert_export_one?: Maybe<Export>;
  /** insert data into the table: "export_status_timeline" */
  insert_export_status_timeline?: Maybe<Export_Status_Timeline_Mutation_Response>;
  /** insert a single row into the table: "export_status_timeline" */
  insert_export_status_timeline_one?: Maybe<Export_Status_Timeline>;
  /** insert data into the table: "feature_flags" */
  insert_feature_flags?: Maybe<Feature_Flags_Mutation_Response>;
  /** insert a single row into the table: "feature_flags" */
  insert_feature_flags_one?: Maybe<Feature_Flags>;
  /** insert data into the table: "hasura_package" */
  insert_hasura_package?: Maybe<Hasura_Package_Mutation_Response>;
  /** insert data into the table: "hasura_package_list" */
  insert_hasura_package_list?: Maybe<Hasura_Package_List_Mutation_Response>;
  /** insert a single row into the table: "hasura_package_list" */
  insert_hasura_package_list_one?: Maybe<Hasura_Package_List>;
  /** insert a single row into the table: "hasura_package" */
  insert_hasura_package_one?: Maybe<Hasura_Package>;
  /** insert data into the table: "provider" */
  insert_provider?: Maybe<Provider_Mutation_Response>;
  /** insert a single row into the table: "provider" */
  insert_provider_one?: Maybe<Provider>;
  /** insert data into the table: "segment" */
  insert_segment?: Maybe<Segment_Mutation_Response>;
  /** insert a single row into the table: "segment" */
  insert_segment_one?: Maybe<Segment>;
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>;
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>;
  modifyPackage: Package;
  removeUsersFromSegment: ModifySegmentResponse;
  /** Lookup the given network account in the MGMT API, and set the exchange_account_network_id, exchange_account_network_revision_applied and exchange_account_network for the hasura account for 'id' */
  setAccountNetwork?: Maybe<Scalars['ExchangeJSON']>;
  /** Given an account id, and the default values, set the third party fees defaults for that account and return the modified account */
  setThirdPartyFeesDefaults?: Maybe<Scalars['ExchangeJSON']>;
  syncDealAndPackage: Scalars['ExchangeJSON'];
  syncPackage: Package;
  /** Update a network account in MGMT API, and return the modified account, the id is the account id in the MGMT API */
  updateAccountNetwork?: Maybe<Scalars['ExchangeJSON']>;
  updateDealAndPackage: ExchangeDeal;
  updateSafeDealAndPackage: SafeExchangeDeal;
  /** update data of the table: "account" */
  update_account?: Maybe<Account_Mutation_Response>;
  /** update single row of the table: "account" */
  update_account_by_pk?: Maybe<Account>;
  /** update data of the table: "account_feature_flags" */
  update_account_feature_flags?: Maybe<Account_Feature_Flags_Mutation_Response>;
  /** update single row of the table: "account_feature_flags" */
  update_account_feature_flags_by_pk?: Maybe<Account_Feature_Flags>;
  /** update multiples rows of table: "account_feature_flags" */
  update_account_feature_flags_many?: Maybe<Array<Maybe<Account_Feature_Flags_Mutation_Response>>>;
  /** update multiples rows of table: "account" */
  update_account_many?: Maybe<Array<Maybe<Account_Mutation_Response>>>;
  /** update data of the table: "account_provider" */
  update_account_provider?: Maybe<Account_Provider_Mutation_Response>;
  /** update single row of the table: "account_provider" */
  update_account_provider_by_pk?: Maybe<Account_Provider>;
  /** update multiples rows of table: "account_provider" */
  update_account_provider_many?: Maybe<Array<Maybe<Account_Provider_Mutation_Response>>>;
  /** update data of the table: "audience" */
  update_audience?: Maybe<Audience_Mutation_Response>;
  /** update single row of the table: "audience" */
  update_audience_by_pk?: Maybe<Audience>;
  /** update multiples rows of table: "audience" */
  update_audience_many?: Maybe<Array<Maybe<Audience_Mutation_Response>>>;
  /** update data of the table: "audience_segment" */
  update_audience_segment?: Maybe<Audience_Segment_Mutation_Response>;
  /** update single row of the table: "audience_segment" */
  update_audience_segment_by_pk?: Maybe<Audience_Segment>;
  /** update multiples rows of table: "audience_segment" */
  update_audience_segment_many?: Maybe<Array<Maybe<Audience_Segment_Mutation_Response>>>;
  /** update data of the table: "bq_resource" */
  update_bq_resource?: Maybe<Bq_Resource_Mutation_Response>;
  /** update single row of the table: "bq_resource" */
  update_bq_resource_by_pk?: Maybe<Bq_Resource>;
  /** update multiples rows of table: "bq_resource" */
  update_bq_resource_many?: Maybe<Array<Maybe<Bq_Resource_Mutation_Response>>>;
  /** update data of the table: "deal" */
  update_deal?: Maybe<Deal_Mutation_Response>;
  /** update single row of the table: "deal" */
  update_deal_by_pk?: Maybe<Deal>;
  /** update multiples rows of table: "deal" */
  update_deal_many?: Maybe<Array<Maybe<Deal_Mutation_Response>>>;
  /** update data of the table: "deal_package" */
  update_deal_package?: Maybe<Deal_Package_Mutation_Response>;
  /** update multiples rows of table: "deal_package" */
  update_deal_package_many?: Maybe<Array<Maybe<Deal_Package_Mutation_Response>>>;
  /** update data of the table: "export" */
  update_export?: Maybe<Export_Mutation_Response>;
  /** update single row of the table: "export" */
  update_export_by_pk?: Maybe<Export>;
  /** update multiples rows of table: "export" */
  update_export_many?: Maybe<Array<Maybe<Export_Mutation_Response>>>;
  /** update data of the table: "export_status_timeline" */
  update_export_status_timeline?: Maybe<Export_Status_Timeline_Mutation_Response>;
  /** update single row of the table: "export_status_timeline" */
  update_export_status_timeline_by_pk?: Maybe<Export_Status_Timeline>;
  /** update multiples rows of table: "export_status_timeline" */
  update_export_status_timeline_many?: Maybe<Array<Maybe<Export_Status_Timeline_Mutation_Response>>>;
  /** update data of the table: "feature_flags" */
  update_feature_flags?: Maybe<Feature_Flags_Mutation_Response>;
  /** update single row of the table: "feature_flags" */
  update_feature_flags_by_pk?: Maybe<Feature_Flags>;
  /** update multiples rows of table: "feature_flags" */
  update_feature_flags_many?: Maybe<Array<Maybe<Feature_Flags_Mutation_Response>>>;
  /** update data of the table: "hasura_package" */
  update_hasura_package?: Maybe<Hasura_Package_Mutation_Response>;
  /** update single row of the table: "hasura_package" */
  update_hasura_package_by_pk?: Maybe<Hasura_Package>;
  /** update data of the table: "hasura_package_list" */
  update_hasura_package_list?: Maybe<Hasura_Package_List_Mutation_Response>;
  /** update multiples rows of table: "hasura_package_list" */
  update_hasura_package_list_many?: Maybe<Array<Maybe<Hasura_Package_List_Mutation_Response>>>;
  /** update multiples rows of table: "hasura_package" */
  update_hasura_package_many?: Maybe<Array<Maybe<Hasura_Package_Mutation_Response>>>;
  /** update data of the table: "provider" */
  update_provider?: Maybe<Provider_Mutation_Response>;
  /** update single row of the table: "provider" */
  update_provider_by_pk?: Maybe<Provider>;
  /** update multiples rows of table: "provider" */
  update_provider_many?: Maybe<Array<Maybe<Provider_Mutation_Response>>>;
  /** update data of the table: "segment" */
  update_segment?: Maybe<Segment_Mutation_Response>;
  /** update single row of the table: "segment" */
  update_segment_by_pk?: Maybe<Segment>;
  /** update multiples rows of table: "segment" */
  update_segment_many?: Maybe<Array<Maybe<Segment_Mutation_Response>>>;
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>;
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>;
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>;
};


export type MutationActivateAudienceArgs = {
  audienceId: Scalars['uuid'];
};


export type MutationActivateAudienceV2Args = {
  audienceId?: InputMaybe<Scalars['String']>;
};


export type MutationAddUsersToSegmentArgs = {
  data: UserDataInput;
  segmentId: Scalars['uuid'];
};


export type MutationArchiveAccountNetworkArgs = {
  exchange_account_network_id: Scalars['String'];
};


export type MutationArchiveDealAndPackageArgs = {
  altId?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationArchivePackageArgs = {
  id: Scalars['String'];
};


export type MutationAudienceShareCreateArgs = {
  input: Array<AudienceShareCreateParams>;
};


export type MutationAudienceShareDeleteArgs = {
  input: Array<Scalars['uuid']>;
};


export type MutationAudienceShareUpdateArgs = {
  input: Array<AudienceShareUpdateParams>;
};


export type MutationCloneAudienceArgs = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};


export type MutationClonePackageArgs = {
  id: Scalars['String'];
  package?: InputMaybe<PackageUpdateParams>;
};


export type MutationCreateAccountNetworkArgs = {
  account: AccountNetworkCreateParams;
};


export type MutationCreateDealAndPackageArgs = {
  deal: ExchangeDealCreateParams;
  deal_id_prefix?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PackageCreateParams>;
};


export type MutationCreateDealForecastingJobArgs = {
  deal: ExchangeDealForecastingDealParams;
  package: PackageCreateParams;
};


export type MutationCreateDealTroubleshootingJobArgs = {
  deal_id: Scalars['String'];
  dsp_id?: InputMaybe<Scalars['String']>;
  end_date: Scalars['timestamptz'];
  start_date: Scalars['timestamptz'];
};


export type MutationCreateLogArgs = {
  email?: InputMaybe<Scalars['String']>;
  errorType?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  msg?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type MutationCreatePackageArgs = {
  package: PackageCreateParams;
};


export type MutationCreateSafeDealAndPackageArgs = {
  deal: ExchangeDealCreateParams;
  deal_id_prefix?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  package?: InputMaybe<PackageCreateParams>;
};


export type MutationCreateServiceAccountUnderParentAccountArgs = {
  expiresIn: Scalars['Duration'];
  name: Scalars['String'];
  parent_account_id: Scalars['String'];
  rateLimit?: InputMaybe<RateLimit>;
};


export type MutationCreateUserUnderParentAccountArgs = {
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  parent_account_id?: InputMaybe<Scalars['String']>;
};


export type MutationCreateUserUnderRootAccountArgs = {
  email_address?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};


export type MutationDelete_AccountArgs = {
  where: Account_Bool_Exp;
};


export type MutationDelete_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Account_Feature_FlagsArgs = {
  where: Account_Feature_Flags_Bool_Exp;
};


export type MutationDelete_Account_Feature_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Account_ProviderArgs = {
  where: Account_Provider_Bool_Exp;
};


export type MutationDelete_Account_Provider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_AudienceArgs = {
  where: Audience_Bool_Exp;
};


export type MutationDelete_Audience_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Audience_SegmentArgs = {
  where: Audience_Segment_Bool_Exp;
};


export type MutationDelete_Audience_Segment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Bq_ResourceArgs = {
  where: Bq_Resource_Bool_Exp;
};


export type MutationDelete_Bq_Resource_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_DealArgs = {
  where: Deal_Bool_Exp;
};


export type MutationDelete_Deal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Deal_PackageArgs = {
  where: Deal_Package_Bool_Exp;
};


export type MutationDelete_ExportArgs = {
  where: Export_Bool_Exp;
};


export type MutationDelete_Export_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Export_Status_TimelineArgs = {
  where: Export_Status_Timeline_Bool_Exp;
};


export type MutationDelete_Export_Status_Timeline_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_Feature_FlagsArgs = {
  where: Feature_Flags_Bool_Exp;
};


export type MutationDelete_Feature_Flags_By_PkArgs = {
  value: Scalars['String'];
};


export type MutationDelete_Hasura_PackageArgs = {
  where: Hasura_Package_Bool_Exp;
};


export type MutationDelete_Hasura_Package_By_PkArgs = {
  id: Scalars['String'];
};


export type MutationDelete_Hasura_Package_ListArgs = {
  where: Hasura_Package_List_Bool_Exp;
};


export type MutationDelete_ProviderArgs = {
  where: Provider_Bool_Exp;
};


export type MutationDelete_Provider_By_PkArgs = {
  id: Scalars['String'];
};


export type MutationDelete_SegmentArgs = {
  where: Segment_Bool_Exp;
};


export type MutationDelete_Segment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationDelete_UserArgs = {
  where: User_Bool_Exp;
};


export type MutationDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


export type MutationExportAudienceArgs = {
  audienceId?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Export_Audience_Config_Input>;
};


export type MutationExportAudienceBulkArgs = {
  exportBulkConfig?: InputMaybe<Array<InputMaybe<Export_Bulk_Audience_Config_Input>>>;
};


export type MutationGenerate_Reach_Report_OverallArgs = {
  id: Scalars['uuid'];
};


export type MutationGenerate_Reach_Report_PublisherArgs = {
  id: Scalars['uuid'];
};


export type MutationInsert_AccountArgs = {
  objects: Array<Account_Insert_Input>;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


export type MutationInsert_Account_Feature_FlagsArgs = {
  objects: Array<Account_Feature_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Account_Feature_Flags_On_Conflict>;
};


export type MutationInsert_Account_Feature_Flags_OneArgs = {
  object: Account_Feature_Flags_Insert_Input;
  on_conflict?: InputMaybe<Account_Feature_Flags_On_Conflict>;
};


export type MutationInsert_Account_OneArgs = {
  object: Account_Insert_Input;
  on_conflict?: InputMaybe<Account_On_Conflict>;
};


export type MutationInsert_Account_ProviderArgs = {
  objects: Array<Account_Provider_Insert_Input>;
  on_conflict?: InputMaybe<Account_Provider_On_Conflict>;
};


export type MutationInsert_Account_Provider_OneArgs = {
  object: Account_Provider_Insert_Input;
  on_conflict?: InputMaybe<Account_Provider_On_Conflict>;
};


export type MutationInsert_AudienceArgs = {
  objects: Array<Audience_Insert_Input>;
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};


export type MutationInsert_Audience_OneArgs = {
  object: Audience_Insert_Input;
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};


export type MutationInsert_Audience_SegmentArgs = {
  objects: Array<Audience_Segment_Insert_Input>;
  on_conflict?: InputMaybe<Audience_Segment_On_Conflict>;
};


export type MutationInsert_Audience_Segment_OneArgs = {
  object: Audience_Segment_Insert_Input;
  on_conflict?: InputMaybe<Audience_Segment_On_Conflict>;
};


export type MutationInsert_Bq_ResourceArgs = {
  objects: Array<Bq_Resource_Insert_Input>;
  on_conflict?: InputMaybe<Bq_Resource_On_Conflict>;
};


export type MutationInsert_Bq_Resource_OneArgs = {
  object: Bq_Resource_Insert_Input;
  on_conflict?: InputMaybe<Bq_Resource_On_Conflict>;
};


export type MutationInsert_DealArgs = {
  objects: Array<Deal_Insert_Input>;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};


export type MutationInsert_Deal_OneArgs = {
  object: Deal_Insert_Input;
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};


export type MutationInsert_Deal_PackageArgs = {
  objects: Array<Deal_Package_Insert_Input>;
};


export type MutationInsert_Deal_Package_OneArgs = {
  object: Deal_Package_Insert_Input;
};


export type MutationInsert_ExportArgs = {
  objects: Array<Export_Insert_Input>;
  on_conflict?: InputMaybe<Export_On_Conflict>;
};


export type MutationInsert_Export_OneArgs = {
  object: Export_Insert_Input;
  on_conflict?: InputMaybe<Export_On_Conflict>;
};


export type MutationInsert_Export_Status_TimelineArgs = {
  objects: Array<Export_Status_Timeline_Insert_Input>;
  on_conflict?: InputMaybe<Export_Status_Timeline_On_Conflict>;
};


export type MutationInsert_Export_Status_Timeline_OneArgs = {
  object: Export_Status_Timeline_Insert_Input;
  on_conflict?: InputMaybe<Export_Status_Timeline_On_Conflict>;
};


export type MutationInsert_Feature_FlagsArgs = {
  objects: Array<Feature_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Feature_Flags_On_Conflict>;
};


export type MutationInsert_Feature_Flags_OneArgs = {
  object: Feature_Flags_Insert_Input;
  on_conflict?: InputMaybe<Feature_Flags_On_Conflict>;
};


export type MutationInsert_Hasura_PackageArgs = {
  objects: Array<Hasura_Package_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Package_On_Conflict>;
};


export type MutationInsert_Hasura_Package_ListArgs = {
  objects: Array<Hasura_Package_List_Insert_Input>;
};


export type MutationInsert_Hasura_Package_List_OneArgs = {
  object: Hasura_Package_List_Insert_Input;
};


export type MutationInsert_Hasura_Package_OneArgs = {
  object: Hasura_Package_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Package_On_Conflict>;
};


export type MutationInsert_ProviderArgs = {
  objects: Array<Provider_Insert_Input>;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


export type MutationInsert_Provider_OneArgs = {
  object: Provider_Insert_Input;
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};


export type MutationInsert_SegmentArgs = {
  objects: Array<Segment_Insert_Input>;
  on_conflict?: InputMaybe<Segment_On_Conflict>;
};


export type MutationInsert_Segment_OneArgs = {
  object: Segment_Insert_Input;
  on_conflict?: InputMaybe<Segment_On_Conflict>;
};


export type MutationInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


export type MutationInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


export type MutationModifyPackageArgs = {
  id: Scalars['String'];
  package: PackageUpdateParams;
};


export type MutationRemoveUsersFromSegmentArgs = {
  data: UserDataInput;
  segmentId: Scalars['uuid'];
};


export type MutationSetAccountNetworkArgs = {
  exchange_account_network_id: Scalars['String'];
  id: Scalars['uuid'];
};


export type MutationSetThirdPartyFeesDefaultsArgs = {
  default_platform_share?: InputMaybe<Scalars['Decimal']>;
  id: Scalars['String'];
  max_cpm_cap?: InputMaybe<Scalars['Decimal']>;
  max_share?: InputMaybe<Scalars['Decimal']>;
};


export type MutationSyncDealAndPackageArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  internal?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSyncPackageArgs = {
  force?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationUpdateAccountNetworkArgs = {
  account: AccountNetworkUpdateParams;
  exchange_account_network_id: Scalars['String'];
};


export type MutationUpdateDealAndPackageArgs = {
  altId?: InputMaybe<Scalars['Boolean']>;
  deal?: InputMaybe<ExchangeDealUpdateParams>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  package?: InputMaybe<PackageUpdateParams>;
};


export type MutationUpdateSafeDealAndPackageArgs = {
  altId?: InputMaybe<Scalars['Boolean']>;
  deal?: InputMaybe<ExchangeDealUpdateParams>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  package?: InputMaybe<PackageUpdateParams>;
};


export type MutationUpdate_AccountArgs = {
  _append?: InputMaybe<Account_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  _inc?: InputMaybe<Account_Inc_Input>;
  _prepend?: InputMaybe<Account_Prepend_Input>;
  _set?: InputMaybe<Account_Set_Input>;
  where: Account_Bool_Exp;
};


export type MutationUpdate_Account_By_PkArgs = {
  _append?: InputMaybe<Account_Append_Input>;
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  _inc?: InputMaybe<Account_Inc_Input>;
  _prepend?: InputMaybe<Account_Prepend_Input>;
  _set?: InputMaybe<Account_Set_Input>;
  pk_columns: Account_Pk_Columns_Input;
};


export type MutationUpdate_Account_Feature_FlagsArgs = {
  _set?: InputMaybe<Account_Feature_Flags_Set_Input>;
  where: Account_Feature_Flags_Bool_Exp;
};


export type MutationUpdate_Account_Feature_Flags_By_PkArgs = {
  _set?: InputMaybe<Account_Feature_Flags_Set_Input>;
  pk_columns: Account_Feature_Flags_Pk_Columns_Input;
};


export type MutationUpdate_Account_Feature_Flags_ManyArgs = {
  updates: Array<Account_Feature_Flags_Updates>;
};


export type MutationUpdate_Account_ManyArgs = {
  updates: Array<Account_Updates>;
};


export type MutationUpdate_Account_ProviderArgs = {
  _set?: InputMaybe<Account_Provider_Set_Input>;
  where: Account_Provider_Bool_Exp;
};


export type MutationUpdate_Account_Provider_By_PkArgs = {
  _set?: InputMaybe<Account_Provider_Set_Input>;
  pk_columns: Account_Provider_Pk_Columns_Input;
};


export type MutationUpdate_Account_Provider_ManyArgs = {
  updates: Array<Account_Provider_Updates>;
};


export type MutationUpdate_AudienceArgs = {
  _append?: InputMaybe<Audience_Append_Input>;
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  _inc?: InputMaybe<Audience_Inc_Input>;
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  _set?: InputMaybe<Audience_Set_Input>;
  where: Audience_Bool_Exp;
};


export type MutationUpdate_Audience_By_PkArgs = {
  _append?: InputMaybe<Audience_Append_Input>;
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  _inc?: InputMaybe<Audience_Inc_Input>;
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  _set?: InputMaybe<Audience_Set_Input>;
  pk_columns: Audience_Pk_Columns_Input;
};


export type MutationUpdate_Audience_ManyArgs = {
  updates: Array<Audience_Updates>;
};


export type MutationUpdate_Audience_SegmentArgs = {
  _set?: InputMaybe<Audience_Segment_Set_Input>;
  where: Audience_Segment_Bool_Exp;
};


export type MutationUpdate_Audience_Segment_By_PkArgs = {
  _set?: InputMaybe<Audience_Segment_Set_Input>;
  pk_columns: Audience_Segment_Pk_Columns_Input;
};


export type MutationUpdate_Audience_Segment_ManyArgs = {
  updates: Array<Audience_Segment_Updates>;
};


export type MutationUpdate_Bq_ResourceArgs = {
  _set?: InputMaybe<Bq_Resource_Set_Input>;
  where: Bq_Resource_Bool_Exp;
};


export type MutationUpdate_Bq_Resource_By_PkArgs = {
  _set?: InputMaybe<Bq_Resource_Set_Input>;
  pk_columns: Bq_Resource_Pk_Columns_Input;
};


export type MutationUpdate_Bq_Resource_ManyArgs = {
  updates: Array<Bq_Resource_Updates>;
};


export type MutationUpdate_DealArgs = {
  _append?: InputMaybe<Deal_Append_Input>;
  _delete_at_path?: InputMaybe<Deal_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Deal_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Deal_Delete_Key_Input>;
  _inc?: InputMaybe<Deal_Inc_Input>;
  _prepend?: InputMaybe<Deal_Prepend_Input>;
  _set?: InputMaybe<Deal_Set_Input>;
  where: Deal_Bool_Exp;
};


export type MutationUpdate_Deal_By_PkArgs = {
  _append?: InputMaybe<Deal_Append_Input>;
  _delete_at_path?: InputMaybe<Deal_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Deal_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Deal_Delete_Key_Input>;
  _inc?: InputMaybe<Deal_Inc_Input>;
  _prepend?: InputMaybe<Deal_Prepend_Input>;
  _set?: InputMaybe<Deal_Set_Input>;
  pk_columns: Deal_Pk_Columns_Input;
};


export type MutationUpdate_Deal_ManyArgs = {
  updates: Array<Deal_Updates>;
};


export type MutationUpdate_Deal_PackageArgs = {
  _append?: InputMaybe<Deal_Package_Append_Input>;
  _delete_at_path?: InputMaybe<Deal_Package_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Deal_Package_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Deal_Package_Delete_Key_Input>;
  _prepend?: InputMaybe<Deal_Package_Prepend_Input>;
  _set?: InputMaybe<Deal_Package_Set_Input>;
  where: Deal_Package_Bool_Exp;
};


export type MutationUpdate_Deal_Package_ManyArgs = {
  updates: Array<Deal_Package_Updates>;
};


export type MutationUpdate_ExportArgs = {
  _append?: InputMaybe<Export_Append_Input>;
  _delete_at_path?: InputMaybe<Export_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Export_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Export_Delete_Key_Input>;
  _prepend?: InputMaybe<Export_Prepend_Input>;
  _set?: InputMaybe<Export_Set_Input>;
  where: Export_Bool_Exp;
};


export type MutationUpdate_Export_By_PkArgs = {
  _append?: InputMaybe<Export_Append_Input>;
  _delete_at_path?: InputMaybe<Export_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Export_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Export_Delete_Key_Input>;
  _prepend?: InputMaybe<Export_Prepend_Input>;
  _set?: InputMaybe<Export_Set_Input>;
  pk_columns: Export_Pk_Columns_Input;
};


export type MutationUpdate_Export_ManyArgs = {
  updates: Array<Export_Updates>;
};


export type MutationUpdate_Export_Status_TimelineArgs = {
  _set?: InputMaybe<Export_Status_Timeline_Set_Input>;
  where: Export_Status_Timeline_Bool_Exp;
};


export type MutationUpdate_Export_Status_Timeline_By_PkArgs = {
  _set?: InputMaybe<Export_Status_Timeline_Set_Input>;
  pk_columns: Export_Status_Timeline_Pk_Columns_Input;
};


export type MutationUpdate_Export_Status_Timeline_ManyArgs = {
  updates: Array<Export_Status_Timeline_Updates>;
};


export type MutationUpdate_Feature_FlagsArgs = {
  _set?: InputMaybe<Feature_Flags_Set_Input>;
  where: Feature_Flags_Bool_Exp;
};


export type MutationUpdate_Feature_Flags_By_PkArgs = {
  _set?: InputMaybe<Feature_Flags_Set_Input>;
  pk_columns: Feature_Flags_Pk_Columns_Input;
};


export type MutationUpdate_Feature_Flags_ManyArgs = {
  updates: Array<Feature_Flags_Updates>;
};


export type MutationUpdate_Hasura_PackageArgs = {
  _append?: InputMaybe<Hasura_Package_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Package_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Package_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Package_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Package_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Package_Prepend_Input>;
  _set?: InputMaybe<Hasura_Package_Set_Input>;
  where: Hasura_Package_Bool_Exp;
};


export type MutationUpdate_Hasura_Package_By_PkArgs = {
  _append?: InputMaybe<Hasura_Package_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Package_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Package_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Package_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Package_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Package_Prepend_Input>;
  _set?: InputMaybe<Hasura_Package_Set_Input>;
  pk_columns: Hasura_Package_Pk_Columns_Input;
};


export type MutationUpdate_Hasura_Package_ListArgs = {
  _append?: InputMaybe<Hasura_Package_List_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Package_List_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Package_List_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Package_List_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Package_List_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Package_List_Prepend_Input>;
  _set?: InputMaybe<Hasura_Package_List_Set_Input>;
  where: Hasura_Package_List_Bool_Exp;
};


export type MutationUpdate_Hasura_Package_List_ManyArgs = {
  updates: Array<Hasura_Package_List_Updates>;
};


export type MutationUpdate_Hasura_Package_ManyArgs = {
  updates: Array<Hasura_Package_Updates>;
};


export type MutationUpdate_ProviderArgs = {
  _append?: InputMaybe<Provider_Append_Input>;
  _delete_at_path?: InputMaybe<Provider_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Provider_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Provider_Delete_Key_Input>;
  _inc?: InputMaybe<Provider_Inc_Input>;
  _prepend?: InputMaybe<Provider_Prepend_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  where: Provider_Bool_Exp;
};


export type MutationUpdate_Provider_By_PkArgs = {
  _append?: InputMaybe<Provider_Append_Input>;
  _delete_at_path?: InputMaybe<Provider_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Provider_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Provider_Delete_Key_Input>;
  _inc?: InputMaybe<Provider_Inc_Input>;
  _prepend?: InputMaybe<Provider_Prepend_Input>;
  _set?: InputMaybe<Provider_Set_Input>;
  pk_columns: Provider_Pk_Columns_Input;
};


export type MutationUpdate_Provider_ManyArgs = {
  updates: Array<Provider_Updates>;
};


export type MutationUpdate_SegmentArgs = {
  _append?: InputMaybe<Segment_Append_Input>;
  _delete_at_path?: InputMaybe<Segment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Segment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Segment_Delete_Key_Input>;
  _inc?: InputMaybe<Segment_Inc_Input>;
  _prepend?: InputMaybe<Segment_Prepend_Input>;
  _set?: InputMaybe<Segment_Set_Input>;
  where: Segment_Bool_Exp;
};


export type MutationUpdate_Segment_By_PkArgs = {
  _append?: InputMaybe<Segment_Append_Input>;
  _delete_at_path?: InputMaybe<Segment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Segment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Segment_Delete_Key_Input>;
  _inc?: InputMaybe<Segment_Inc_Input>;
  _prepend?: InputMaybe<Segment_Prepend_Input>;
  _set?: InputMaybe<Segment_Set_Input>;
  pk_columns: Segment_Pk_Columns_Input;
};


export type MutationUpdate_Segment_ManyArgs = {
  updates: Array<Segment_Updates>;
};


export type MutationUpdate_UserArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


export type MutationUpdate_User_By_PkArgs = {
  _append?: InputMaybe<User_Append_Input>;
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Prepend_Input>;
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


export type MutationUpdate_User_ManyArgs = {
  updates: Array<User_Updates>;
};

export type NotAllowedError = BaseError & {
  __typename?: 'NotAllowedError';
  message: Scalars['String'];
};

export type NotFoundError = BaseError & {
  __typename?: 'NotFoundError';
  message: Scalars['String'];
};

export type OptionsByPathResult = {
  __typename?: 'OptionsByPathResult';
  extra: Scalars['ExchangeJSON'];
  filter: Scalars['ExchangeJSON'];
  id: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
};

/** Package represents the targeting criteria and/or URL lists that are applicable to the deal. */
export type Package = {
  __typename?: 'Package';
  /** The ID of the package that this package was cloned from */
  cloned_from?: Maybe<Scalars['String']>;
  /** The date when the Package was created. */
  created_date: Scalars['timestamptz'];
  /** Merchandising Details - Domains or App names. */
  domains?: Maybe<Scalars['String']>;
  /** The unique identifier for the Package. */
  id: Scalars['String'];
  /**
   * Package is curated and only managed by curators, if true then only curators can manage this package, otherwise package behaves normally.
   * For internal OpenX use only.
   */
  is_curated?: Maybe<Scalars['Boolean']>;
  /**
   * Package is locked for editing by external users.
   * For internal OpenX use only.
   */
  locked_internal?: Maybe<Scalars['Boolean']>;
  /** Merchandising Details - Package Logo link. */
  logo?: Maybe<Scalars['String']>;
  /** The date when the Package was last modified. */
  modified_date: Scalars['timestamptz'];
  /** The name of the Package */
  name: Scalars['String'];
  /** Any notes about the Package */
  notes?: Maybe<Scalars['String']>;
  /** Private Market Package setup. */
  private_market?: Maybe<PackagePrivateMarket>;
  /** Rate Card Price. */
  rate_card_cpm?: Maybe<Scalars['Decimal4']>;
  /** Targeting criteria using audience, content, context and placement attributes. */
  targeting: Targeting;
  /** The unique uid identifier for the Package. */
  uid: Scalars['String'];
  /** Targeting using a URL (domain) allowlist or blocklist. */
  url_targeting?: Maybe<UrlTargeting>;
  /** Merchandising Details - Sample URLs. */
  urls?: Maybe<Scalars['JSON']>;
};

export type PackageCreateParams = {
  /**
   * The account which owns the package, will default to logged in user's account
   * For internal OpenX use only.
   */
  account_uid?: InputMaybe<Scalars['String']>;
  /** Merchandising Details - Domains or App names. */
  domains?: InputMaybe<Scalars['String']>;
  /**
   * Package is curated and only managed by curators, if true then only curators can manage this package, otherwise package behaves normally.
   * For internal OpenX use only.
   */
  is_curated?: InputMaybe<Scalars['Boolean']>;
  /**
   * Package is locked for editing by external users.
   * For internal OpenX use only.
   */
  locked_internal?: InputMaybe<Scalars['Boolean']>;
  /** Merchandising Details - Package Logo link. */
  logo?: InputMaybe<Scalars['String']>;
  /** The name of the Package */
  name?: InputMaybe<Scalars['String']>;
  /** Any notes about the Package */
  notes?: InputMaybe<Scalars['String']>;
  /** Private Market Package setup. */
  private_market?: InputMaybe<PackagePrivateMarketCreateParams>;
  /** Rate Card Price. */
  rate_card_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** The associated Targeting object. */
  targeting?: InputMaybe<TargetingCreateParams>;
  /** Targeting using a URL (domain) allowlist or blocklist. */
  url_targeting?: InputMaybe<UrlTargetingCreateParams>;
  /** Merchandising Details - Sample URLs. */
  urls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PackageInvalidInputError = BaseError & {
  __typename?: 'PackageInvalidInputError';
  inputs: Array<InputFieldValidation>;
  message: Scalars['String'];
  uid: Scalars['String'];
};

/** Private Market Package setup. */
export type PackagePrivateMarket = {
  __typename?: 'PackagePrivateMarket';
  /** Default cpm for participants, can be overridden either per demand_partner/buyer in participants. */
  default_discounted_cpm?: Maybe<Scalars['Decimal4']>;
  /** Default cpm for demand_partner/buyers listed in participants field, it's the rate associated with Private Auction deals. */
  default_rate_card_cpm?: Maybe<Scalars['Decimal4']>;
  /** Must be "1" (true) or "0" (false). If "1", the package is discoverable to all demand_partner/buyers with default_rate_card_cpm or an overridden cpm. */
  discoverable: Scalars['String'];
};

/** Private Market Package setup. */
export type PackagePrivateMarketCreateParams = {
  /** Default cpm for participants, can be overridden either per demand_partner/buyer in participants. */
  default_discounted_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** Default cpm for demand_partner/buyers listed in participants field, it's the rate associated with Private Auction deals. */
  default_rate_card_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** Must be "1" (true) or "0" (false). If "1", the package is discoverable to all demand_partner/buyers with default_rate_card_cpm or an overridden cpm. */
  discoverable: Scalars['String'];
};

/** Private Market Package setup. */
export type PackagePrivateMarketUpdateParams = {
  /** Default cpm for participants, can be overridden either per demand_partner/buyer in participants. */
  default_discounted_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** Default cpm for demand_partner/buyers listed in participants field, it's the rate associated with Private Auction deals. */
  default_rate_card_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** Must be "1" (true) or "0" (false). If "1", the package is discoverable to all demand_partner/buyers with default_rate_card_cpm or an overridden cpm. */
  discoverable: Scalars['String'];
};

export type PackageUpdateParams = {
  /**
   * The id of the deal associated with the package
   * For internal OpenX use only.
   */
  deal_id?: InputMaybe<Scalars['String']>;
  /**
   * The uid of the deal associated with the package
   * For internal OpenX use only.
   */
  deal_uid?: InputMaybe<Scalars['String']>;
  /** Merchandising Details - Domains or App names. */
  domains?: InputMaybe<Scalars['String']>;
  /**
   * Package is curated and only managed by curators, if true then only curators can manage this package, otherwise package behaves normally.
   * For internal OpenX use only.
   */
  is_curated?: InputMaybe<Scalars['Boolean']>;
  /**
   * Package is locked for editing by external users.
   * For internal OpenX use only.
   */
  locked_internal?: InputMaybe<Scalars['Boolean']>;
  /** Merchandising Details - Package Logo link. */
  logo?: InputMaybe<Scalars['String']>;
  /** The name of the Package */
  name?: InputMaybe<Scalars['String']>;
  /** Any notes about the Package */
  notes?: InputMaybe<Scalars['String']>;
  /** Private Market Package setup. */
  private_market?: InputMaybe<PackagePrivateMarketUpdateParams>;
  /** Rate Card Price. */
  rate_card_cpm?: InputMaybe<Scalars['Decimal4']>;
  /** The associated Targeting object. */
  targeting?: InputMaybe<TargetingUpdateParams>;
  /** Targeting using a URL (domain) allowlist or blocklist. */
  url_targeting?: InputMaybe<UrlTargetingUpdateParams>;
  /** Merchandising Details - Sample URLs. */
  urls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PackagesUsedByOutput = {
  __typename?: 'PackagesUsedByOutput';
  deals_referenced?: Maybe<Scalars['Int']>;
  floorrules_referenced?: Maybe<Scalars['Int']>;
  uid: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_feature_flags" */
  account_feature_flags: Array<Account_Feature_Flags>;
  /** fetch aggregated fields from the table: "account_feature_flags" */
  account_feature_flags_aggregate: Account_Feature_Flags_Aggregate;
  /** fetch data from the table: "account_feature_flags" using primary key columns */
  account_feature_flags_by_pk?: Maybe<Account_Feature_Flags>;
  /** fetch data from the table: "account_provider" */
  account_provider: Array<Account_Provider>;
  /** fetch aggregated fields from the table: "account_provider" */
  account_provider_aggregate: Account_Provider_Aggregate;
  /** fetch data from the table: "account_provider" using primary key columns */
  account_provider_by_pk?: Maybe<Account_Provider>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  audienceSharesByAudience: Array<Maybe<AudienceShare>>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  audience_categories_reach_all?: Maybe<Array<Maybe<Audience_Categories_Reach_All>>>;
  audience_reach: Audience_Reach;
  /** fetch data from the table: "audience_segment" */
  audience_segment: Array<Audience_Segment>;
  /** fetch aggregated fields from the table: "audience_segment" */
  audience_segment_aggregate: Audience_Segment_Aggregate;
  /** fetch data from the table: "audience_segment" using primary key columns */
  audience_segment_by_pk?: Maybe<Audience_Segment>;
  /** fetch data from the table: "bq_resource" */
  bq_resource: Array<Bq_Resource>;
  /** fetch aggregated fields from the table: "bq_resource" */
  bq_resource_aggregate: Bq_Resource_Aggregate;
  /** fetch data from the table: "bq_resource" using primary key columns */
  bq_resource_by_pk?: Maybe<Bq_Resource>;
  /** Given third_party_fees_config, generate the expected third_party_fees object */
  calculateThirdPartyFees?: Maybe<Array<ExchangeThirdPartyFee>>;
  checkDealAndPackage: Scalars['ExchangeJSON'];
  checkUserPermissions?: Maybe<CheckUserPermissionsResponse>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  dealForecastingCreateJob?: Maybe<Scalars['ExchangeJSON']>;
  dealForecastingGetJob?: Maybe<Scalars['ExchangeJSON']>;
  dealForecastingJob?: Maybe<Scalars['ExchangeJSON']>;
  /** Fetch info about a group of deals with the same external deal id */
  dealGroupInfo: Array<DealGroupInfoOutput>;
  dealTroubleshootingJob?: Maybe<Scalars['ExchangeJSON']>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using 'id' or 'exchange_id' or 'exchange_uid' column */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_package" */
  deal_package: Array<Deal_Package>;
  /** fetch aggregated fields from the table: "deal_package" */
  deal_package_aggregate: Deal_Package_Aggregate;
  enrichEstimate?: Maybe<Scalars['JSON']>;
  /** fetch data from the table: "export" */
  export: Array<Export>;
  /** fetch aggregated fields from the table: "export" */
  export_aggregate: Export_Aggregate;
  /** fetch data from the table: "export" using primary key columns */
  export_by_pk?: Maybe<Export>;
  /** fetch data from the table: "export_status_timeline" */
  export_status_timeline: Array<Export_Status_Timeline>;
  /** fetch aggregated fields from the table: "export_status_timeline" */
  export_status_timeline_aggregate: Export_Status_Timeline_Aggregate;
  /** fetch data from the table: "export_status_timeline" using primary key columns */
  export_status_timeline_by_pk?: Maybe<Export_Status_Timeline>;
  /** fetch data from the table: "feature_flags" */
  feature_flags: Array<Feature_Flags>;
  /** fetch aggregated fields from the table: "feature_flags" */
  feature_flags_aggregate: Feature_Flags_Aggregate;
  /** fetch data from the table: "feature_flags" using primary key columns */
  feature_flags_by_pk?: Maybe<Feature_Flags>;
  geoSearch: Array<GeoSearchOutput>;
  getMgmtSession?: Maybe<Scalars['ExchangeJSON']>;
  /** Given a partner_id lookup the account in the MGMT API and return the account object */
  getPartnerAccount?: Maybe<Scalars['ExchangeJSON']>;
  getSession?: Maybe<Scalars['JSON']>;
  getUdsAccountId?: Maybe<Scalars['String']>;
  /** fetch data from the table: "hasura_package" */
  hasura_package: Array<Hasura_Package>;
  /** fetch aggregated fields from the table: "hasura_package" */
  hasura_package_aggregate: Hasura_Package_Aggregate;
  /** fetch data from the table: "hasura_package" using primary key columns */
  hasura_package_by_pk?: Maybe<Hasura_Package>;
  /** fetch data from the table: "hasura_package_list" */
  hasura_package_list: Array<Hasura_Package_List>;
  /** fetch aggregated fields from the table: "hasura_package_list" */
  hasura_package_list_aggregate: Hasura_Package_List_Aggregate;
  invOperatorSearch: Array<InvSearchOperatorOutput>;
  invPackageSearch: Array<InvPackageSearchOutput>;
  optionsByPath: Array<Maybe<OptionsByPathResult>>;
  packageUsedBy?: Maybe<Array<Maybe<PackagesUsedByOutput>>>;
  ping?: Maybe<Scalars['String']>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table: "segment" */
  segment: Array<Segment>;
  /** fetch aggregated fields from the table: "segment" */
  segment_aggregate: Segment_Aggregate;
  /** fetch data from the table: "segment" using primary key columns */
  segment_by_pk?: Maybe<Segment>;
  sf_account_names?: Maybe<Array<Maybe<Scalars['String']>>>;
  troubleshooting: Array<TroubleshootingOutput>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
};


export type QueryAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type QueryAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type QueryAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryAccount_Feature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


export type QueryAccount_Feature_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


export type QueryAccount_Feature_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryAccount_ProviderArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


export type QueryAccount_Provider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


export type QueryAccount_Provider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryAudienceArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type QueryAudienceSharesByAudienceArgs = {
  audience_external_openaudience_id: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryAudience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type QueryAudience_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryAudience_Categories_Reach_AllArgs = {
  id: Scalars['String'];
};


export type QueryAudience_ReachArgs = {
  id?: InputMaybe<Scalars['String']>;
  sfAccountName?: InputMaybe<Scalars['String']>;
};


export type QueryAudience_SegmentArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


export type QueryAudience_Segment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


export type QueryAudience_Segment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryBq_ResourceArgs = {
  distinct_on?: InputMaybe<Array<Bq_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bq_Resource_Order_By>>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};


export type QueryBq_Resource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bq_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bq_Resource_Order_By>>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};


export type QueryBq_Resource_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryCalculateThirdPartyFeesArgs = {
  third_party_fees_config?: InputMaybe<Array<ThirdPartyFeesConfigInput>>;
};


export type QueryCheckDealAndPackageArgs = {
  full?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type QueryCheckUserPermissionsArgs = {
  uid: Scalars['String'];
};


export type QueryDealArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type QueryDealForecastingCreateJobArgs = {
  deal: ExchangeDealForecastingDealParams;
  package: PackageCreateParams;
};


export type QueryDealForecastingGetJobArgs = {
  job_id: Scalars['String'];
};


export type QueryDealForecastingJobArgs = {
  job_id: Scalars['String'];
};


export type QueryDealGroupInfoArgs = {
  deal_ids: Array<Scalars['String']>;
};


export type QueryDealTroubleshootingJobArgs = {
  deal_id: Scalars['String'];
  job_id: Scalars['String'];
};


export type QueryDeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type QueryDeal_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryDeal_PackageArgs = {
  distinct_on?: InputMaybe<Array<Deal_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Package_Order_By>>;
  where?: InputMaybe<Deal_Package_Bool_Exp>;
};


export type QueryDeal_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Package_Order_By>>;
  where?: InputMaybe<Deal_Package_Bool_Exp>;
};


export type QueryEnrichEstimateArgs = {
  export_type?: InputMaybe<Scalars['String']>;
  normalized_definition?: InputMaybe<Scalars['JSON']>;
};


export type QueryExportArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


export type QueryExport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


export type QueryExport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryExport_Status_TimelineArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


export type QueryExport_Status_Timeline_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


export type QueryExport_Status_Timeline_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QueryFeature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Flags_Order_By>>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};


export type QueryFeature_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Flags_Order_By>>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};


export type QueryFeature_Flags_By_PkArgs = {
  value: Scalars['String'];
};


export type QueryGeoSearchArgs = {
  isRawQuery?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  phrase: Scalars['String'];
};


export type QueryGetPartnerAccountArgs = {
  id: Scalars['String'];
};


export type QueryGetUdsAccountIdArgs = {
  accountName?: InputMaybe<Scalars['String']>;
};


export type QueryHasura_PackageArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_Order_By>>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};


export type QueryHasura_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_Order_By>>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};


export type QueryHasura_Package_By_PkArgs = {
  id: Scalars['String'];
};


export type QueryHasura_Package_ListArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_List_Order_By>>;
  where?: InputMaybe<Hasura_Package_List_Bool_Exp>;
};


export type QueryHasura_Package_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_List_Order_By>>;
  where?: InputMaybe<Hasura_Package_List_Bool_Exp>;
};


export type QueryInvOperatorSearchArgs = {
  instance_uid: Scalars['String'];
  isRawQuery?: InputMaybe<Scalars['Boolean']>;
  phrase: Scalars['String'];
};


export type QueryInvPackageSearchArgs = {
  account_uid: Scalars['String'];
  isRawQuery?: InputMaybe<Scalars['Boolean']>;
  phrase: Scalars['String'];
};


export type QueryOptionsByPathArgs = {
  filter?: InputMaybe<Scalars['ExchangeJSON']>;
  path: Scalars['String'];
};


export type QueryPackageUsedByArgs = {
  objects: Array<Scalars['String']>;
  package_uid: Array<Scalars['String']>;
};


export type QueryProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type QueryProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type QueryProvider_By_PkArgs = {
  id: Scalars['String'];
};


export type QuerySegmentArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


export type QuerySegment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


export type QuerySegment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type QuerySf_Account_NamesArgs = {
  id: Scalars['String'];
};


export type QueryTroubleshootingArgs = {
  dealId: Scalars['uuid'];
};


export type QueryUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type QueryUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Rate Limit for API Keys */
export type RateLimit = {
  /** Allowed count per interval, this should be a positive integer */
  count: Scalars['Int'];
  /**
   * Interval is an ISO8601 duration, and is the time window for which the count
   * is accumulated and eventually results in blocking requests until the next
   * interval.  Example, with a count of 1000 and a duration of "PT24H" you would
   * get to make 1000 request per 24 hours.  An alternative way to express this could also
   * be an interval of "P1D".
   */
  interval: Scalars['Duration'];
};

export type RboConfig = {
  __typename?: 'RboConfig';
  daily_goal_target?: Maybe<Scalars['StringOrInt']>;
  format: Scalars['String'];
  goal?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  tactic?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['String']>;
};

export type RboConfigParams = {
  daily_goal_target?: InputMaybe<Scalars['StringOrInt']>;
  format: Scalars['String'];
  goal?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  tactic?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['String']>;
};

export type SafeExchangeDeal = ConnectionError | ExchangeDeal | InvalidInputError | NotAllowedError | NotFoundError | PackageInvalidInputError | UnknownError;

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** fetch data from the table: "account" */
  account: Array<Account>;
  /** fetch aggregated fields from the table: "account" */
  account_aggregate: Account_Aggregate;
  /** fetch data from the table: "account" using primary key columns */
  account_by_pk?: Maybe<Account>;
  /** fetch data from the table: "account_feature_flags" */
  account_feature_flags: Array<Account_Feature_Flags>;
  /** fetch aggregated fields from the table: "account_feature_flags" */
  account_feature_flags_aggregate: Account_Feature_Flags_Aggregate;
  /** fetch data from the table: "account_feature_flags" using primary key columns */
  account_feature_flags_by_pk?: Maybe<Account_Feature_Flags>;
  /** fetch data from the table in a streaming manner: "account_feature_flags" */
  account_feature_flags_stream: Array<Account_Feature_Flags>;
  /** fetch data from the table: "account_provider" */
  account_provider: Array<Account_Provider>;
  /** fetch aggregated fields from the table: "account_provider" */
  account_provider_aggregate: Account_Provider_Aggregate;
  /** fetch data from the table: "account_provider" using primary key columns */
  account_provider_by_pk?: Maybe<Account_Provider>;
  /** fetch data from the table in a streaming manner: "account_provider" */
  account_provider_stream: Array<Account_Provider>;
  /** fetch data from the table in a streaming manner: "account" */
  account_stream: Array<Account>;
  /** fetch data from the table: "audience" */
  audience: Array<Audience>;
  /** fetch aggregated fields from the table: "audience" */
  audience_aggregate: Audience_Aggregate;
  /** fetch data from the table: "audience" using primary key columns */
  audience_by_pk?: Maybe<Audience>;
  /** fetch data from the table: "audience_segment" */
  audience_segment: Array<Audience_Segment>;
  /** fetch aggregated fields from the table: "audience_segment" */
  audience_segment_aggregate: Audience_Segment_Aggregate;
  /** fetch data from the table: "audience_segment" using primary key columns */
  audience_segment_by_pk?: Maybe<Audience_Segment>;
  /** fetch data from the table in a streaming manner: "audience_segment" */
  audience_segment_stream: Array<Audience_Segment>;
  /** fetch data from the table in a streaming manner: "audience" */
  audience_stream: Array<Audience>;
  /** fetch data from the table: "bq_resource" */
  bq_resource: Array<Bq_Resource>;
  /** fetch aggregated fields from the table: "bq_resource" */
  bq_resource_aggregate: Bq_Resource_Aggregate;
  /** fetch data from the table: "bq_resource" using primary key columns */
  bq_resource_by_pk?: Maybe<Bq_Resource>;
  /** fetch data from the table in a streaming manner: "bq_resource" */
  bq_resource_stream: Array<Bq_Resource>;
  /** fetch data from the table: "deal" */
  deal: Array<Deal>;
  /** fetch aggregated fields from the table: "deal" */
  deal_aggregate: Deal_Aggregate;
  /** fetch data from the table: "deal" using primary key columns */
  deal_by_pk?: Maybe<Deal>;
  /** fetch data from the table: "deal_package" */
  deal_package: Array<Deal_Package>;
  /** fetch aggregated fields from the table: "deal_package" */
  deal_package_aggregate: Deal_Package_Aggregate;
  /** fetch data from the table in a streaming manner: "deal_package" */
  deal_package_stream: Array<Deal_Package>;
  /** fetch data from the table in a streaming manner: "deal" */
  deal_stream: Array<Deal>;
  /** fetch data from the table: "export" */
  export: Array<Export>;
  /** fetch aggregated fields from the table: "export" */
  export_aggregate: Export_Aggregate;
  /** fetch data from the table: "export" using primary key columns */
  export_by_pk?: Maybe<Export>;
  /** fetch data from the table: "export_status_timeline" */
  export_status_timeline: Array<Export_Status_Timeline>;
  /** fetch aggregated fields from the table: "export_status_timeline" */
  export_status_timeline_aggregate: Export_Status_Timeline_Aggregate;
  /** fetch data from the table: "export_status_timeline" using primary key columns */
  export_status_timeline_by_pk?: Maybe<Export_Status_Timeline>;
  /** fetch data from the table in a streaming manner: "export_status_timeline" */
  export_status_timeline_stream: Array<Export_Status_Timeline>;
  /** fetch data from the table in a streaming manner: "export" */
  export_stream: Array<Export>;
  /** fetch data from the table: "feature_flags" */
  feature_flags: Array<Feature_Flags>;
  /** fetch aggregated fields from the table: "feature_flags" */
  feature_flags_aggregate: Feature_Flags_Aggregate;
  /** fetch data from the table: "feature_flags" using primary key columns */
  feature_flags_by_pk?: Maybe<Feature_Flags>;
  /** fetch data from the table in a streaming manner: "feature_flags" */
  feature_flags_stream: Array<Feature_Flags>;
  /** fetch data from the table: "hasura_package" */
  hasura_package: Array<Hasura_Package>;
  /** fetch aggregated fields from the table: "hasura_package" */
  hasura_package_aggregate: Hasura_Package_Aggregate;
  /** fetch data from the table: "hasura_package" using primary key columns */
  hasura_package_by_pk?: Maybe<Hasura_Package>;
  /** fetch data from the table: "hasura_package_list" */
  hasura_package_list: Array<Hasura_Package_List>;
  /** fetch aggregated fields from the table: "hasura_package_list" */
  hasura_package_list_aggregate: Hasura_Package_List_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_package_list" */
  hasura_package_list_stream: Array<Hasura_Package_List>;
  /** fetch data from the table in a streaming manner: "hasura_package" */
  hasura_package_stream: Array<Hasura_Package>;
  /** fetch data from the table: "provider" */
  provider: Array<Provider>;
  /** fetch aggregated fields from the table: "provider" */
  provider_aggregate: Provider_Aggregate;
  /** fetch data from the table: "provider" using primary key columns */
  provider_by_pk?: Maybe<Provider>;
  /** fetch data from the table in a streaming manner: "provider" */
  provider_stream: Array<Provider>;
  /** fetch data from the table: "segment" */
  segment: Array<Segment>;
  /** fetch aggregated fields from the table: "segment" */
  segment_aggregate: Segment_Aggregate;
  /** fetch data from the table: "segment" using primary key columns */
  segment_by_pk?: Maybe<Segment>;
  /** fetch data from the table in a streaming manner: "segment" */
  segment_stream: Array<Segment>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>;
};


export type SubscriptionAccountArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type SubscriptionAccount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Order_By>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type SubscriptionAccount_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAccount_Feature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


export type SubscriptionAccount_Feature_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


export type SubscriptionAccount_Feature_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAccount_Feature_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Feature_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


export type SubscriptionAccount_ProviderArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


export type SubscriptionAccount_Provider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


export type SubscriptionAccount_Provider_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAccount_Provider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Provider_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


export type SubscriptionAccount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Account_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Bool_Exp>;
};


export type SubscriptionAudienceArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type SubscriptionAudience_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type SubscriptionAudience_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAudience_SegmentArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


export type SubscriptionAudience_Segment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


export type SubscriptionAudience_Segment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionAudience_Segment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audience_Segment_Stream_Cursor_Input>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


export type SubscriptionAudience_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audience_Stream_Cursor_Input>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


export type SubscriptionBq_ResourceArgs = {
  distinct_on?: InputMaybe<Array<Bq_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bq_Resource_Order_By>>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};


export type SubscriptionBq_Resource_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bq_Resource_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bq_Resource_Order_By>>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};


export type SubscriptionBq_Resource_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionBq_Resource_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bq_Resource_Stream_Cursor_Input>>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};


export type SubscriptionDealArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type SubscriptionDeal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type SubscriptionDeal_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionDeal_PackageArgs = {
  distinct_on?: InputMaybe<Array<Deal_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Package_Order_By>>;
  where?: InputMaybe<Deal_Package_Bool_Exp>;
};


export type SubscriptionDeal_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Package_Order_By>>;
  where?: InputMaybe<Deal_Package_Bool_Exp>;
};


export type SubscriptionDeal_Package_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Deal_Package_Stream_Cursor_Input>>;
  where?: InputMaybe<Deal_Package_Bool_Exp>;
};


export type SubscriptionDeal_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Deal_Stream_Cursor_Input>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


export type SubscriptionExportArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


export type SubscriptionExport_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


export type SubscriptionExport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionExport_Status_TimelineArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


export type SubscriptionExport_Status_Timeline_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


export type SubscriptionExport_Status_Timeline_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionExport_Status_Timeline_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Export_Status_Timeline_Stream_Cursor_Input>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


export type SubscriptionExport_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Export_Stream_Cursor_Input>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


export type SubscriptionFeature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Flags_Order_By>>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};


export type SubscriptionFeature_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Flags_Order_By>>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};


export type SubscriptionFeature_Flags_By_PkArgs = {
  value: Scalars['String'];
};


export type SubscriptionFeature_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Feature_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};


export type SubscriptionHasura_PackageArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_Order_By>>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};


export type SubscriptionHasura_Package_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_Order_By>>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};


export type SubscriptionHasura_Package_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionHasura_Package_ListArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_List_Order_By>>;
  where?: InputMaybe<Hasura_Package_List_Bool_Exp>;
};


export type SubscriptionHasura_Package_List_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Package_List_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Package_List_Order_By>>;
  where?: InputMaybe<Hasura_Package_List_Bool_Exp>;
};


export type SubscriptionHasura_Package_List_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Package_List_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Package_List_Bool_Exp>;
};


export type SubscriptionHasura_Package_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Package_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};


export type SubscriptionProviderArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type SubscriptionProvider_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provider_Order_By>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type SubscriptionProvider_By_PkArgs = {
  id: Scalars['String'];
};


export type SubscriptionProvider_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Provider_Stream_Cursor_Input>>;
  where?: InputMaybe<Provider_Bool_Exp>;
};


export type SubscriptionSegmentArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


export type SubscriptionSegment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


export type SubscriptionSegment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionSegment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Segment_Stream_Cursor_Input>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


export type SubscriptionUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type SubscriptionUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type SubscriptionUser_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Targeting specifies the request-time traffic filters that are used to target the deal. */
export type Targeting = {
  __typename?: 'Targeting';
  /** Targeting object specifying an Audience. */
  audience?: Maybe<TargetingAudience>;
  /** Targeting object specifying some supply-side inventory content (adunit, page, site, etc). */
  content?: Maybe<TargetingContent>;
  /** Targeting object specifying custom key/value pairs. */
  custom?: Maybe<TargetingCustom>;
  /** Targeting object specifying one or more domain categories. */
  domain?: Maybe<TargetingDomain>;
  /** Boolean if true, the package will not be matched against indirect inventory */
  exclude_non_direct?: Maybe<Scalars['Boolean']>;
  /** Targeting object specifying inferred geolocation at request time. */
  geographic?: Maybe<TargetingGeographic>;
  /** Value of 1, the package will include open bidding inventory */
  include_open_bidding_inventory?: Maybe<Scalars['String']>;
  /** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
  instream_content?: Maybe<TargetingInstreamContent>;
  /** Specifies whether targeting specs must all match ('AND') or if only one is required ('OR'). */
  inter_dimension_operator: Scalars['String'];
  /** Targeting object specifying inventory formats. */
  inventory_format?: Maybe<TargetingInventoryFormat>;
  /** OpenX additional categories and keywords - allows to target or block specific inventory tagged with internal metacategories, and to target/block specific keywords on pages, based on OpenX Contextual service. */
  metacategory?: Maybe<TargetingMetaCategory>;
  /** Targeting object specifying technographic characteristics of the request. */
  technographic?: Maybe<TargetingTechnographic>;
  /** Targeting object specifying video characteristics. */
  video?: Maybe<TargetingVideo>;
  /** Targeting object specifying viewability characteristics. */
  viewability?: Maybe<TargetingViewability>;
  /** Targeting object specifying VTR (view-through rate) characteristics. */
  vtr?: Maybe<TargetingVtr>;
};

/** Specifies an ad placement type for targeting (ie, display vs video). */
export type TargetingAdPlacement = {
  __typename?: 'TargetingAdPlacement';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more ad placement types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.ad_placement")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingAdPlacementCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more ad placement types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.ad_placement")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingAdPlacementUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more ad placement types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.ad_placement")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Specifies video inventory by its maximum duration. */
export type TargetingAdunitMaxDuration = {
  __typename?: 'TargetingAdunitMaxDuration';
  /** Must be one of "<=", "==", "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more linear video duration options.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.adunit_max_duration")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingAdunitMaxDurationCreateParams = {
  /** Must be one of "<=", "==", "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more linear video duration options.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.adunit_max_duration")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingAdunitMaxDurationUpdateParams = {
  /** Must be one of "<=", "==", "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more linear video duration options.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.adunit_max_duration")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Specifies an API framework for targeting. */
export type TargetingApiFramework = {
  __typename?: 'TargetingApiFramework';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video API frameworks.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.api_framework")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingApiFrameworkCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video API frameworks.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.api_framework")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingApiFrameworkUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video API frameworks.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.api_framework")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Represents an activated Audience that is used as the target of the deal. */
export type TargetingAudience = {
  __typename?: 'TargetingAudience';
  /** OpenAudience custom audience to be targeted by this deal. */
  openaudience_custom?: Maybe<TargetingOpenaudienceCustom>;
};

export type TargetingAudienceCreateParams = {
  /** OpenAudience custom audience to be targeted by this deal. */
  openaudience_custom?: InputMaybe<TargetingOpenaudienceCustomCreateParams>;
};

export type TargetingAudienceUpdateParams = {
  /** OpenAudience custom audience to be targeted by this deal. */
  openaudience_custom?: InputMaybe<TargetingOpenaudienceCustomUpdateParams>;
};

/** Specifies a browser for targeting, derived from User-Agent headers or similar client hints. */
export type TargetingBrowser = {
  __typename?: 'TargetingBrowser';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more browsers.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.browser")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingBrowserCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more browsers.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.browser")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingBrowserUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more browsers.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.browser")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Used to target inventory via content attributes. */
export type TargetingContent = {
  __typename?: 'TargetingContent';
  /**
   * Inventory items to be included/excluded arranged by customer ID for display purposes.
   * For internal OpenX use only.
   */
  _idsByInstances?: Maybe<Scalars['JSON']>;
  /** Content Targeting object specifying one or more Publisher IDs. */
  account?: Maybe<TargetingContentPublisherId>;
  /** Content Targeting object specifying one or more Ad Unit Sizes. */
  adunit_size?: Maybe<TargetingContentAdunitSize>;
  /** Content Targeting object specifying one or more AppBundle IDs. */
  app_bundle_id?: Maybe<TargetingContentAppBundleId>;
  /** The operator to apply to content criteria defined in this object, must be "AND" or "OR". */
  content_inter_dimension_operator?: Maybe<Scalars['String']>;
  /**
   * Content Targeting object specifying one or more inventory items to be excluded.
   * For internal OpenX use only.
   */
  excludes?: Maybe<TargetingContentInventory>;
  /**
   * Content Targeting object specifying one or more inventory items to be included.
   * For internal OpenX use only.
   */
  includes?: Maybe<TargetingContentInventory>;
  /** Content Targeting object specifying one or more Instance Hashes. */
  instance?: Maybe<TargetingContentInstanceHash>;
  /** OpenX Contextual Targeting Service keywords targeting */
  keywords?: Maybe<TargetingContentKeywords>;
  /** Content Targeting object specifying one or more page URLs. */
  page_url?: Maybe<TargetingContentPageUrl>;
};

/** Used to specify ad unit size(s) for targeting. */
export type TargetingContentAdunitSize = {
  __typename?: 'TargetingContentAdunitSize';
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Ad Unit Size(s) to target.  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.content.adunit_size")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentAdunitSizeCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Ad Unit Size(s) to target.  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.content.adunit_size")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentAdunitSizeUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Ad Unit Size(s) to target.  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.content.adunit_size")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Used to target inventory via mobile app bundles. */
export type TargetingContentAppBundleId = {
  __typename?: 'TargetingContentAppBundleId';
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting App Bundle IDs.  A maximum of 2000 may be specified. */
  val?: Maybe<Array<TargetingContentAppBundleIdItem>>;
};

export type TargetingContentAppBundleIdCreateParams = {
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting App Bundle IDs.  A maximum of 2000 may be specified. */
  val?: InputMaybe<Array<TargetingContentAppBundleIdItemCreateParams>>;
};

/** Used to specify mobile app bundle IDs for targeting. */
export type TargetingContentAppBundleIdItem = {
  __typename?: 'TargetingContentAppBundleIdItem';
  /** Must be "==", "NOT INTERSECTS", "INTERSECTS", "NOT =^", "!=" or "=^" */
  op: Scalars['String'];
  /** The App Bundle ID to target. */
  val: Scalars['String'];
};

export type TargetingContentAppBundleIdItemCreateParams = {
  /** Must be "==", "NOT INTERSECTS", "INTERSECTS", "NOT =^", "!=" or "=^" */
  op: Scalars['String'];
  /** The App Bundle ID to target. */
  val: Scalars['String'];
};

export type TargetingContentAppBundleIdItemUpdateParams = {
  /** Must be "==", "NOT INTERSECTS", "INTERSECTS", "NOT =^", "!=" or "=^" */
  op: Scalars['String'];
  /** The App Bundle ID to target. */
  val: Scalars['String'];
};

export type TargetingContentAppBundleIdUpdateParams = {
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting App Bundle IDs.  A maximum of 2000 may be specified. */
  val?: InputMaybe<Array<TargetingContentAppBundleIdItemUpdateParams>>;
};

export type TargetingContentCreateParams = {
  /**
   * Inventory items to be included/excluded arranged by customer ID for display purposes.
   * For internal OpenX use only.
   */
  _idsByInstances?: InputMaybe<Scalars['JSON']>;
  /** Content Targeting object specifying one or more Publisher IDs. */
  account?: InputMaybe<TargetingContentPublisherIdCreateParams>;
  /** Content Targeting object specifying one or more Ad Unit Sizes. */
  adunit_size?: InputMaybe<TargetingContentAdunitSizeCreateParams>;
  /** Content Targeting object specifying one or more AppBundle IDs. */
  app_bundle_id?: InputMaybe<TargetingContentAppBundleIdCreateParams>;
  /** The operator to apply to category criteria defined in this object, must be "AND" or "OR". */
  categories_inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** The operator to apply to content criteria defined in this object, must be "AND" or "OR". */
  content_inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /**
   * Content Targeting object specifying one or more inventory items to be excluded.
   * For internal OpenX use only.
   */
  excludes?: InputMaybe<TargetingContentInventoryCreateParams>;
  /**
   * Content Targeting object specifying one or more inventory items to be included.
   * For internal OpenX use only.
   */
  includes?: InputMaybe<TargetingContentInventoryCreateParams>;
  /** Content Targeting object specifying one or more Instance Hashes. */
  instance?: InputMaybe<TargetingContentInstanceHashCreateParams>;
  /** OpenX Contextual Targeting Service keywords targeting. */
  keywords?: InputMaybe<TargetingContentKeywordsCreateParams>;
  /** Content Targeting object specifying one or more page URLs. */
  page_url?: InputMaybe<TargetingContentPageUrlCreateParams>;
};

export type TargetingContentInstanceHash = {
  __typename?: 'TargetingContentInstanceHash';
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Instace Hash to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentInstanceHashCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Instace Hash to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentInstanceHashUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Instace Hash to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Targets inventory operated by OpenX customer instances.  For internal use only. */
export type TargetingContentInventory = {
  __typename?: 'TargetingContentInventory';
  /**
   * Target accounts operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  account?: Maybe<Scalars['String']>;
  /**
   * Target adunits operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  adunit?: Maybe<Scalars['String']>;
  /**
   * Target OpenX customer instances.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  instance?: Maybe<Scalars['String']>;
  /**
   * Target networks operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  network?: Maybe<Scalars['String']>;
  /**
   * Target pages operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  page?: Maybe<Scalars['String']>;
  /**
   * Target sections operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  section?: Maybe<Scalars['String']>;
  /**
   * Target sites operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  site?: Maybe<Scalars['String']>;
  /**
   * Target sitesections operated by an OpenX customer instance.  For internal use only.
   * Use a comma-delimited list to specify multiple selections.
   */
  sitesection?: Maybe<Scalars['String']>;
};

export type TargetingContentInventoryCreateParams = {
  /** Target accounts operated by an OpenX customer instance.  For internal use only. */
  account?: InputMaybe<Scalars['String']>;
  /** Target adunits operated by an OpenX customer instance.  For internal use only. */
  adunit?: InputMaybe<Scalars['String']>;
  /** Target OpenX customer instances.  For internal use only. */
  instance?: InputMaybe<Scalars['String']>;
  /** Target networks operated by an OpenX customer instance.  For internal use only. */
  network?: InputMaybe<Scalars['String']>;
  /** Target pages operated by an OpenX customer instance.  For internal use only. */
  page?: InputMaybe<Scalars['String']>;
  /** Target sections operated by an OpenX customer instance.  For internal use only. */
  section?: InputMaybe<Scalars['String']>;
  /** Target sites operated by an OpenX customer instance.  For internal use only. */
  site?: InputMaybe<Scalars['String']>;
  /** Target sitesections operated by an OpenX customer instance.  For internal use only. */
  sitesection?: InputMaybe<Scalars['String']>;
};

export type TargetingContentInventoryUpdateParams = {
  /** Target accounts operated by an OpenX customer instance.  For internal use only. */
  account?: InputMaybe<Scalars['String']>;
  /** Target adunits operated by an OpenX customer instance.  For internal use only. */
  adunit?: InputMaybe<Scalars['String']>;
  /** Target OpenX customer instances.  For internal use only. */
  instance?: InputMaybe<Scalars['String']>;
  /** Target networks operated by an OpenX customer instance.  For internal use only. */
  network?: InputMaybe<Scalars['String']>;
  /** Target pages operated by an OpenX customer instance.  For internal use only. */
  page?: InputMaybe<Scalars['String']>;
  /** Target sections operated by an OpenX customer instance.  For internal use only. */
  section?: InputMaybe<Scalars['String']>;
  /** Target sites operated by an OpenX customer instance.  For internal use only. */
  site?: InputMaybe<Scalars['String']>;
  /** Target sitesections operated by an OpenX customer instance.  For internal use only. */
  sitesection?: InputMaybe<Scalars['String']>;
};

export type TargetingContentKeywords = {
  __typename?: 'TargetingContentKeywords';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * List of keywords.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  val: Array<Scalars['String']>;
};

export type TargetingContentKeywordsCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * List of keywords.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  val: Array<Scalars['String']>;
};

export type TargetingContentKeywordsUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * List of keywords.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  val: Array<Scalars['String']>;
};

/** Used to specify page-level URL patterns for targeting. */
export type TargetingContentPageUrl = {
  __typename?: 'TargetingContentPageUrl';
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting page level URLs.  A maximum of 2000 may be specified. */
  val?: Maybe<Array<TargetingContentPageUrlItem>>;
};

export type TargetingContentPageUrlCreateParams = {
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting page level URLs.  A maximum of 2000 may be specified. */
  val?: InputMaybe<Array<TargetingContentPageUrlItemCreateParams>>;
};

/** Represents a page-level URL pattern. */
export type TargetingContentPageUrlItem = {
  __typename?: 'TargetingContentPageUrlItem';
  /**
   * Must be "INTERSECTS", "NOT INTERSECTS", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   * To match any page containing the specified string, use the substring operator "=?".
   */
  op: Scalars['String'];
  /** The page URL to target. */
  val: Scalars['String'];
};

export type TargetingContentPageUrlItemCreateParams = {
  /**
   * Must be "INTERSECTS", "NOT INTERSECTS", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   * To match any page containing the specified string, use the substring operator "=?".
   */
  op: Scalars['String'];
  /** The page URL to target. */
  val: Scalars['String'];
};

export type TargetingContentPageUrlItemUpdateParams = {
  /**
   * Must be "INTERSECTS", "NOT INTERSECTS", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   * To match any page containing the specified string, use the substring operator "=?".
   */
  op: Scalars['String'];
  /** The page URL to target. */
  val: Scalars['String'];
};

export type TargetingContentPageUrlUpdateParams = {
  /** Must be "AND" or "OR" */
  op: Scalars['String'];
  /** One or more criteria targeting App Bundle IDs.  A maximum of 2000 may be specified. */
  val?: InputMaybe<Array<TargetingContentPageUrlItemUpdateParams>>;
};

export type TargetingContentPublisherId = {
  __typename?: 'TargetingContentPublisherId';
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Publisher ID to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentPublisherIdCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Publisher ID to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentPublisherIdUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS" */
  op: Scalars['String'];
  /**
   * The Publisher ID to target.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingContentUpdateParams = {
  /**
   * Inventory items to be included/excluded arranged by customer ID for display purposes.
   * For internal OpenX use only.
   */
  _idsByInstances?: InputMaybe<Scalars['JSON']>;
  /** Content Targeting object specifying one or more Publisher IDs. */
  account?: InputMaybe<TargetingContentPublisherIdUpdateParams>;
  /** Content Targeting object specifying one or more Ad Unit Sizes. */
  adunit_size?: InputMaybe<TargetingContentAdunitSizeUpdateParams>;
  /** Content Targeting object specifying one or more AppBundle IDs. */
  app_bundle_id?: InputMaybe<TargetingContentAppBundleIdUpdateParams>;
  /** The operator to apply to category criteria defined in this object, must be "AND" or "OR". */
  categories_inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** The operator to apply to content criteria defined in this object, must be "AND" or "OR". */
  content_inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /**
   * Content Targeting object specifying one or more inventory items to be excluded.
   * For internal OpenX use only.
   */
  excludes?: InputMaybe<TargetingContentInventoryUpdateParams>;
  /**
   * Content Targeting object specifying one or more inventory items to be included.
   * For internal OpenX use only.
   */
  includes?: InputMaybe<TargetingContentInventoryUpdateParams>;
  /** Content Targeting object specifying one or more Instance Hashes. */
  instance?: InputMaybe<TargetingContentInstanceHashUpdateParams>;
  /** OpenX Contextual Targeting Service keywords targeting. */
  keywords?: InputMaybe<TargetingContentKeywordsUpdateParams>;
  /** Content Targeting object specifying one or more page URLs. */
  page_url?: InputMaybe<TargetingContentPageUrlUpdateParams>;
};

export type TargetingCreateParams = {
  /** Targeting object specifying an Audience. */
  audience?: InputMaybe<TargetingAudienceCreateParams>;
  /** Targeting object specifying some supply-side inventory content (adunit, page, site, etc). */
  content?: InputMaybe<TargetingContentCreateParams>;
  /** Targeting object specifying custom key/value pairs. */
  custom?: InputMaybe<TargetingCustomCreateParams>;
  /** Targeting object specifying one or more domain categories. */
  domain?: InputMaybe<TargetingDomainCreateParams>;
  /** Boolean if true, the package will not be matched against indirect inventory */
  exclude_non_direct?: InputMaybe<Scalars['Boolean']>;
  /** Targeting object specifying inferred geolocation at request time. */
  geographic?: InputMaybe<TargetingGeographicCreateParams>;
  /** Value of 1, the package will include open bidding inventory */
  include_open_bidding_inventory?: InputMaybe<Scalars['String']>;
  /** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
  instream_content?: InputMaybe<TargetingInstreamContentCreateParams>;
  /** Specifies whether targeting specs must all match ('AND') or if only one is required ('OR'). */
  inter_dimension_operator: Scalars['String'];
  /** Targeting object specifying inventory formats. */
  inventory_format?: InputMaybe<TargetingInventoryFormatCreateParams>;
  /** OpenX additional categories and keywords - allows to target or block specific inventory tagged with internal metacategories, and to target/block specific keywords on pages, based on OpenX Contextual service. */
  metacategory?: InputMaybe<TargetingMetaCategoryCreateParams>;
  /** Targeting object specifying technographic characteristics of the request. */
  technographic?: InputMaybe<TargetingTechnographicCreateParams>;
  /** Targeting object specifying video characteristics. */
  video?: InputMaybe<TargetingVideoCreateParams>;
  /** Targeting object specifying viewability characteristics. */
  viewability?: InputMaybe<TargetingViewabilityCreateParams>;
  /** Targeting object specifying VTR (view-through rate) characteristics. */
  vtr?: InputMaybe<TargetingVtrCreateParams>;
};

/** Targets inventory by custom attributes, including contextual targeting. */
export type TargetingCustom = {
  __typename?: 'TargetingCustom';
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /** Target a custom variable.  Use only if instructed by OpenX. */
  val?: Maybe<Array<TargetingCustomItem>>;
};

export type TargetingCustomCreateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /** Target a custom variable.  Use only if instructed by OpenX. */
  val?: InputMaybe<Array<TargetingCustomItemCreateParams>>;
};

/** Specifies a single custom attribute for targeting. */
export type TargetingCustomItem = {
  __typename?: 'TargetingCustomItem';
  /**
   * The name of the custom targeting attribute.  This will be provided by OpenX if you are
   * using custom targeting fields.
   */
  attr: Scalars['String'];
  /**
   * Must be one of "AFTER", "BEFORE", "INVERSE IN", "INTERSECTS", "NOT INTERSECTS", "EQ", ">", ">=",
   * "<", "<=", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   */
  op: Scalars['String'];
  /**
   * The value to be compared with the request-time value of the custom targeting attribute.
   * Use a comma-delimited list to specify multiple selections when using a compatible operator.
   */
  val: Scalars['String'];
};

export type TargetingCustomItemCreateParams = {
  /**
   * The name of the custom targeting attribute.  This will be provided by OpenX if you are
   * using custom targeting fields.
   */
  attr: Scalars['String'];
  /**
   * Must be one of "AFTER", "BEFORE", "INVERSE IN", "INTERSECTS", "NOT INTERSECTS", "EQ", ">", ">=",
   * "<", "<=", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   */
  op: Scalars['String'];
  /** The value to be compared with the request-time value of the custom targeting attribute. */
  val: Scalars['String'];
};

export type TargetingCustomItemUpdateParams = {
  /**
   * The name of the custom targeting attribute.  This will be provided by OpenX if you are
   * using custom targeting fields.
   */
  attr: Scalars['String'];
  /**
   * Must be one of "AFTER", "BEFORE", "INVERSE IN", "INTERSECTS", "NOT INTERSECTS", "EQ", ">", ">=",
   * "<", "<=", "==", "!=", "NOT =?", "=?", "=^", "NOT =^", "=$", or "NOT =$".
   */
  op: Scalars['String'];
  /** The value to be compared with the request-time value of the custom targeting attribute. */
  val: Scalars['String'];
};

export type TargetingCustomUpdateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /** Target a custom variable.  Use only if instructed by OpenX. */
  val?: InputMaybe<Array<TargetingCustomItemUpdateParams>>;
};

/** Specifies a device type for targeting. */
export type TargetingDeviceType = {
  __typename?: 'TargetingDeviceType';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more device types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.device_type")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDeviceTypeCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more device types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.device_type")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDeviceTypeUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more device types.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.device_type")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Specifies a distribution channel for targeting (ie, site vs app). */
export type TargetingDistributionChannel = {
  __typename?: 'TargetingDistributionChannel';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more distribution channels.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.distribution_channel")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDistributionChannelCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more distribution channels.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.distribution_channel")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDistributionChannelUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more distribution channels.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.inventory_format.distribution_channel")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Targets domains by content category. */
export type TargetingDomain = {
  __typename?: 'TargetingDomain';
  /** Target domains matching (legacy) OpenX categories. */
  categories?: Maybe<TargetingDomainCategories>;
  /** Target domains matching content categories from the IAB v2 taxonomy. */
  categories_iab_v2?: Maybe<TargetingDomainCategoriesIabV2>;
  /** If set must be one of "AND" or "OR". */
  inter_dimension_operator?: Maybe<Scalars['String']>;
  /** If set must be one of "AND" or "OR". */
  op?: Maybe<Scalars['String']>;
};

/** Specifies a domain content category using the OpenX taxonomy. */
export type TargetingDomainCategories = {
  __typename?: 'TargetingDomainCategories';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * One or more OpenX domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDomainCategoriesCreateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /**
   * One or more OpenX domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Specifies a domain content category using the IAB v2 taxonomy. */
export type TargetingDomainCategoriesIabV2 = {
  __typename?: 'TargetingDomainCategoriesIabV2';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * One or more IAB v2 domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories_iab_v2")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDomainCategoriesIabV2CreateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /**
   * One or more IAB v2 domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories_iab_v2")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDomainCategoriesIabV2UpdateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /**
   * One or more IAB v2 domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories_iab_v2")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDomainCategoriesUpdateParams = {
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
  /**
   * One or more OpenX domain categories to target.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.domain.categories")`.
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingDomainCreateParams = {
  /** Target domains matching (legacy) OpenX categories. */
  categories?: InputMaybe<TargetingDomainCategoriesCreateParams>;
  /** Target domains matching content categories from the IAB v2 taxonomy. */
  categories_iab_v2?: InputMaybe<TargetingDomainCategoriesIabV2CreateParams>;
  /** If set must be one of "AND" or "OR". */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** If set must be one of "AND" or "OR". */
  op?: InputMaybe<Scalars['String']>;
};

export type TargetingDomainUpdateParams = {
  /** Target domains matching (legacy) OpenX categories. */
  categories?: InputMaybe<TargetingDomainCategoriesUpdateParams>;
  /** Target domains matching content categories from the IAB v2 taxonomy. */
  categories_iab_v2?: InputMaybe<TargetingDomainCategoriesIabV2UpdateParams>;
  /** If set must be one of "AND" or "OR". */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** If set must be one of "AND" or "OR". */
  op?: InputMaybe<Scalars['String']>;
};

/** Targets requests by the location of the user at request-time. */
export type TargetingGeographic = {
  __typename?: 'TargetingGeographic';
  /** Geographic Targeting object specifying one or more locations defined by latitude, longitude, and radius to be included. */
  circles?: Maybe<TargetingGeographicCircles>;
  /** Geographic Targeting object specifying one or more request-time locations to be excluded. */
  excludes?: Maybe<TargetingGeographicItem>;
  /** Geographic Targeting object specifying one or more request-time locations to be included. */
  includes?: Maybe<TargetingGeographicItem>;
};

export type TargetingGeographicCircles = {
  __typename?: 'TargetingGeographicCircles';
  /** Must be "<" */
  op: Scalars['String'];
  /** Geographic Targeting Array of latitude, longitude, and radius objects { lat: string, lon: string, rad: string} */
  val: Array<TargetingGeographicCirclesVal>;
};

export type TargetingGeographicCirclesCreateParams = {
  /** Must be "<" */
  op: Scalars['String'];
  /** Geographic Targeting Array of latitude, longitude, and radius objects { lat: string, lon: string, rad: string} */
  val: Scalars['String'];
};

export type TargetingGeographicCirclesUpdateParams = {
  /** Must be "<" */
  op: Scalars['String'];
  /** Geographic Targeting Array of latitude, longitude, and radius objects { lat: string, lon: string, rad: string} */
  val: Scalars['String'];
};

export type TargetingGeographicCirclesVal = {
  __typename?: 'TargetingGeographicCirclesVal';
  /** Geographic Targeting object property of latitude */
  lat: Scalars['Float'];
  /** Geographic Targeting object property of longitude */
  lon: Scalars['Float'];
  /** Geographic Targeting object property of radius */
  rad: Scalars['Float'];
};

export type TargetingGeographicCreateParams = {
  /** Geographic Targeting object specifying one or more circle locations to be included. */
  circles?: InputMaybe<TargetingGeographicCirclesCreateParams>;
  /** Geographic Targeting object specifying one or more request-time locations to be excluded. */
  excludes?: InputMaybe<TargetingGeographicItemCreateParams>;
  /** Geographic Targeting object specifying one or more request-time locations to be included. */
  includes?: InputMaybe<TargetingGeographicItemCreateParams>;
};

/** Specifies a geographic location for targeting. */
export type TargetingGeographicItem = {
  __typename?: 'TargetingGeographicItem';
  /**
   * Target one or more cities.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.city",
   * filter: { city: $wildcard })`, where `$wildcard` represents a wildcard expression
   * such as "los*".
   * Use a comma-delimited list to specify multiple selections.
   */
  city?: Maybe<Scalars['String']>;
  /**
   * Target one or more continents.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.continent",
   * filter: { continent: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  continent?: Maybe<Scalars['String']>;
  /**
   * Target one or more countries.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.country",
   * filter: { country: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  country?: Maybe<Scalars['String']>;
  /**
   * Target one or more DMAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.dma",
   * filter: { dma: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  dma?: Maybe<Scalars['String']>;
  /**
   * Target one or more MSAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.msa",
   * filter: { msa: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  msa?: Maybe<Scalars['String']>;
  /**
   * Target one or more Postal/ZIP Codes.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.postal_code",
   * filter: { postal_code: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  postal_code?: Maybe<Scalars['String']>;
  /**
   * Target one or more regions.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.region",
   * filter: { region: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  region?: Maybe<Scalars['String']>;
  /**
   * Target one or more states.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.state",
   * filter: { state: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  state?: Maybe<Scalars['String']>;
};

export type TargetingGeographicItemCreateParams = {
  /**
   * Target one or more cities.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.city",
   * filter: { city: $wildcard })`, where `$wildcard` represents a wildcard expression
   * such as "los*".
   * Use a comma-delimited list to specify multiple selections.
   */
  city?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more continents.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.continent",
   * filter: { continent: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  continent?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more countries.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.country",
   * filter: { country: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  country?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more DMAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.dma",
   * filter: { dma: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  dma?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more MSAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.msa",
   * filter: { msa: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  msa?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more Postal/ZIP Codes.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.postal_code",
   * filter: { postal_code: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  postal_code?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more regions.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.region",
   * filter: { region: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  region?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more states.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.state",
   * filter: { state: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  state?: InputMaybe<Scalars['String']>;
};

export type TargetingGeographicItemUpdateParams = {
  /**
   * Target one or more cities.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.city",
   * filter: { city: $wildcard })`, where `$wildcard` represents a wildcard expression
   * such as "los*".
   * Use a comma-delimited list to specify multiple selections.
   */
  city?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more continents.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.continent",
   * filter: { continent: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  continent?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more countries.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.country",
   * filter: { country: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  country?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more DMAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.dma",
   * filter: { dma: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  dma?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more MSAs.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.msa",
   * filter: { msa: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  msa?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more Postal/ZIP Codes.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.postal_code",
   * filter: { postal_code: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  postal_code?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more regions.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.region",
   * filter: { region: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  region?: InputMaybe<Scalars['String']>;
  /**
   * Target one or more states.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.geographic.state",
   * filter: { state: $wildcard })`, where `$wildcard` represents a wildcard expression.
   * Use a comma-delimited list to specify multiple selections.
   */
  state?: InputMaybe<Scalars['String']>;
};

export type TargetingGeographicUpdateParams = {
  /** Geographic Targeting object specifying one or more circle locations to be included. */
  circles?: InputMaybe<TargetingGeographicCirclesUpdateParams>;
  /** Geographic Targeting object specifying one or more request-time locations to be excluded. */
  excludes?: InputMaybe<TargetingGeographicItemUpdateParams>;
  /** Geographic Targeting object specifying one or more request-time locations to be included. */
  includes?: InputMaybe<TargetingGeographicItemUpdateParams>;
};

/** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
export type TargetingInstreamContent = {
  __typename?: 'TargetingInstreamContent';
  /**
   * Channel on which an ad will be displayed. According to OpenRTB v2.6 spec This object describes the channel
   * an ad will be displayed on. A Channel is defined as the entity that curates
   * a content library, or stream within a brand name for viewers.
   */
  channel?: Maybe<TargetingInstreamContentChannel>;
  /** Rating of the content (e.g. according to the MPAA classification). */
  contentrating?: Maybe<TargetingInstreamContentContentrating>;
  /** Episode number. */
  episode?: Maybe<TargetingInstreamContentEpisode>;
  /** Genre that best describes the content. */
  genre?: Maybe<TargetingInstreamContentGenre>;
  /** Specifies whether Content targeting dimensions must all match ('AND') or if only one is required ('OR').  The default is 'AND'. */
  inter_dimension_operator?: Maybe<Scalars['String']>;
  /** Content language using ISO-639-1 standard. */
  language?: Maybe<TargetingInstreamContentLanguage>;
  /** Setting this to "1" will target live content only. Setting this to "0" will target non-live content only. */
  livestream?: Maybe<Scalars['String']>;
  /** Attributes used for targeting of Network object nested in Content object defined in OpenRTB v2.6 spec */
  network?: Maybe<TargetingInstreamContentNetwork>;
  /**
   * Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief”
   * (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog).
   */
  series?: Maybe<TargetingInstreamContentSeries>;
};

/** Channel on which an ad will be displayed. */
export type TargetingInstreamContentChannel = {
  __typename?: 'TargetingInstreamContentChannel';
  name: TargetingInstreamContentChannelName;
};

/** Channel the content is on (e.g., a local channel like “WABC-TV") */
export type TargetingInstreamContentChannelCreateParams = {
  name: TargetingInstreamContentChannelNameCreateParams;
};

/** Channel name on which an ad will be displayed. */
export type TargetingInstreamContentChannelName = {
  __typename?: 'TargetingInstreamContentChannelName';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case sensitive.
   * Channel the content is on (e.g., a local channel like 'WABC-TV')
   */
  val: Array<Scalars['String']>;
};

/** Channel the content is on (e.g., a local channel like “WABC-TV") */
export type TargetingInstreamContentChannelNameCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case sensitive.
   * Channel the content is on (e.g., a local channel like 'WABC-TV')
   */
  val: Array<Scalars['String']>;
};

/** Channel name on which an ad will be displayed. */
export type TargetingInstreamContentChannelNameUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case sensitive.
   * Channel the content is on (e.g., a local channel like 'WABC-TV')
   */
  val: Array<Scalars['String']>;
};

/** Channel the content is on (e.g., a local channel like “WABC-TV") */
export type TargetingInstreamContentChannelUpdateParams = {
  name: TargetingInstreamContentChannelNameUpdateParams;
};

/** Rating of the content (e.g. according to the MPAA classification). */
export type TargetingInstreamContentContentrating = {
  __typename?: 'TargetingInstreamContentContentrating';
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  allow?: Maybe<Array<Scalars['String']>>;
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  block?: Maybe<Array<Scalars['String']>>;
};

/** Rating of the content (e.g. according to the MPAA classification). */
export type TargetingInstreamContentContentratingCreateParams = {
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  allow?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  block?: InputMaybe<Array<Scalars['String']>>;
};

/** Rating of the content (e.g. according to the MPAA classification). */
export type TargetingInstreamContentContentratingUpdateParams = {
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  allow?: InputMaybe<Array<Scalars['String']>>;
  /**
   * The rating of the content (e.g. according to the MPAA classification).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.contentrating")`.)
   */
  block?: InputMaybe<Array<Scalars['String']>>;
};

/** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
export type TargetingInstreamContentCreateParams = {
  /**
   * Channel on which an ad will be displayed. According to OpenRTB v2.6 spec This object describes the channel
   * an ad will be displayed on. A Channel is defined as the entity that curates
   * a content library, or stream within a brand name for viewers.
   */
  channel?: InputMaybe<TargetingInstreamContentChannelCreateParams>;
  /** Rating of the content (e.g. according to the MPAA classification). */
  contentrating?: InputMaybe<TargetingInstreamContentContentratingCreateParams>;
  /** Episode number. */
  episode?: InputMaybe<TargetingInstreamContentEpisodeCreateParams>;
  /** Genre that best describes the content. */
  genre?: InputMaybe<TargetingInstreamContentGenreCreateParams>;
  /** Specifies whether Content targeting dimensions must all match ('AND') or if only one is required ('OR').  The default is 'AND'. */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** Content language using ISO-639-1 standard. */
  language?: InputMaybe<TargetingInstreamContentLanguageCreateParams>;
  /** Setting this to "1" will target live content only. Setting this to "0" will target non-live content only. */
  livestream?: InputMaybe<Scalars['String']>;
  /** Network the content is on (e.g., a TV network like “ABC") */
  network?: InputMaybe<TargetingInstreamContentNetworkCreateParams>;
  /**
   * Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief”
   * (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog).
   */
  series?: InputMaybe<TargetingInstreamContentSeriesCreateParams>;
};

/** Episode number. */
export type TargetingInstreamContentEpisode = {
  __typename?: 'TargetingInstreamContentEpisode';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** List of episode numbers between 0 and 99999. */
  val: Array<Scalars['String']>;
};

/** Episode number. */
export type TargetingInstreamContentEpisodeCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** List of episode numbers between 0 and 99999. */
  val: Array<Scalars['String']>;
};

/** Episode number. */
export type TargetingInstreamContentEpisodeUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** List of episode numbers between 0 and 99999. */
  val: Array<Scalars['String']>;
};

/** Genre that best describes the content. */
export type TargetingInstreamContentGenre = {
  __typename?: 'TargetingInstreamContentGenre';
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  allow?: Maybe<Array<Scalars['String']>>;
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  block?: Maybe<Array<Scalars['String']>>;
};

/** Genre that best describes the content. */
export type TargetingInstreamContentGenreCreateParams = {
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  allow?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  block?: InputMaybe<Array<Scalars['String']>>;
};

/** Genre that best describes the content. */
export type TargetingInstreamContentGenreUpdateParams = {
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  allow?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Suggestions options contain a list of the most popular genres received by OpenX in Ad Requests. It is not complete nor standardized.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.genre")`.)
   */
  block?: InputMaybe<Array<Scalars['String']>>;
};

/** Content language using ISO-639-1 standard. */
export type TargetingInstreamContentLanguage = {
  __typename?: 'TargetingInstreamContentLanguage';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Language(s) of the content using ISO-639-1 standard.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.language")`.)
   */
  val: Array<Scalars['String']>;
};

/** Content language using ISO-639-1 standard. */
export type TargetingInstreamContentLanguageCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Language(s) of the content using ISO-639-1 standard.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.language")`.
   */
  val: Array<Scalars['String']>;
};

/** Content language using ISO-639-1 standard. */
export type TargetingInstreamContentLanguageUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Language(s) of the content using ISO-639-1 standard.
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.instream_content.language")`.
   */
  val: Array<Scalars['String']>;
};

/** Attributes used for targeting of Network object nested in Content object defined in OpenRTB v2.6 spec */
export type TargetingInstreamContentNetwork = {
  __typename?: 'TargetingInstreamContentNetwork';
  name: TargetingInstreamContentNetworkName;
};

/** Attributes used for targeting of Network object nested in Content object defined in OpenRTB v2.6 spec */
export type TargetingInstreamContentNetworkCreateParams = {
  name: TargetingInstreamContentNetworkNameCreateParams;
};

/** Network name the content is on (e.g., a TV network like “ABC") */
export type TargetingInstreamContentNetworkName = {
  __typename?: 'TargetingInstreamContentNetworkName';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case-insensitive.
   * Network the content is on (e.g., a TV network like “ABC")
   */
  val: Array<Scalars['String']>;
};

/** Network name the content is on (e.g., a TV network like “ABC") */
export type TargetingInstreamContentNetworkNameCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case-insensitive.
   * Network the content is on (e.g., a TV network like “ABC")
   */
  val: Array<Scalars['String']>;
};

/** Network name the content is on (e.g., a TV network like “ABC") */
export type TargetingInstreamContentNetworkNameUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * The field is freeform string without any suggestions. Is case-insensitive.
   * Network the content is on (e.g., a TV network like “ABC")
   */
  val: Array<Scalars['String']>;
};

/** Attributes used for targeting of Network object nested in Content object defined in OpenRTB v2.6 spec */
export type TargetingInstreamContentNetworkUpdateParams = {
  name: TargetingInstreamContentNetworkNameUpdateParams;
};

/** Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief” (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog). */
export type TargetingInstreamContentSeries = {
  __typename?: 'TargetingInstreamContentSeries';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** The field is freeform string without any suggestions. Is case-insensitive. */
  val: Array<Scalars['String']>;
};

/** Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief” (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog). */
export type TargetingInstreamContentSeriesCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** The field is freeform string without any suggestions. Is case-insensitive. */
  val: Array<Scalars['String']>;
};

/** Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief” (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog). */
export type TargetingInstreamContentSeriesUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /** The field is freeform string without any suggestions. Is case-insensitive. */
  val: Array<Scalars['String']>;
};

/** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
export type TargetingInstreamContentUpdateParams = {
  /**
   * Channel on which an ad will be displayed. According to OpenRTB v2.6 spec This object describes the channel
   * an ad will be displayed on. A Channel is defined as the entity that curates
   * a content library, or stream within a brand name for viewers.
   */
  channel?: InputMaybe<TargetingInstreamContentChannelUpdateParams>;
  /** Rating of the content (e.g. according to the MPAA classification). */
  contentrating?: InputMaybe<TargetingInstreamContentContentratingUpdateParams>;
  /** Episode number. */
  episode?: InputMaybe<TargetingInstreamContentEpisodeUpdateParams>;
  /** Genre that best describes the content. */
  genre?: InputMaybe<TargetingInstreamContentGenreUpdateParams>;
  /** Specifies whether Content targeting dimensions must all match ('AND') or if only one is required ('OR').  The default is 'AND'. */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** Content language using ISO-639-1 standard. */
  language?: InputMaybe<TargetingInstreamContentLanguageUpdateParams>;
  /** Setting this to "1" will target live content only. Setting this to "0" will target non-live content only. */
  livestream?: InputMaybe<Scalars['String']>;
  /** Network the content is on (e.g., a TV network like “ABC") */
  network?: InputMaybe<TargetingInstreamContentNetworkUpdateParams>;
  /**
   * Content series. Video Examples: “The Office” (television), “Star Wars” (movie), or “Arby ‘N’ The Chief”
   * (made for web). Non-Video Example: “Ecocentric” (Time Magazine blog).
   */
  series?: InputMaybe<TargetingInstreamContentSeriesUpdateParams>;
};

/** Targets inventory using the IAB Inventory Format taxonomy (ie, display vs video,  app vs web). */
export type TargetingInventoryFormat = {
  __typename?: 'TargetingInventoryFormat';
  /** Target an ad placement type as defined by IAB (ie, display vs video). */
  ad_placement?: Maybe<TargetingAdPlacement>;
  /** Target a distribution channel as defined by IAB (ie, site vs app). */
  distribution_channel?: Maybe<TargetingDistributionChannel>;
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
};

export type TargetingInventoryFormatCreateParams = {
  /** Target an ad placement type as defined by IAB (ie, display vs video). */
  ad_placement?: InputMaybe<TargetingAdPlacementCreateParams>;
  /** Target a distribution channel as defined by IAB (ie, site vs app). */
  distribution_channel?: InputMaybe<TargetingDistributionChannelCreateParams>;
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
};

export type TargetingInventoryFormatUpdateParams = {
  /** Target an ad placement type as defined by IAB (ie, display vs video). */
  ad_placement?: InputMaybe<TargetingAdPlacementUpdateParams>;
  /** Target a distribution channel as defined by IAB (ie, site vs app). */
  distribution_channel?: InputMaybe<TargetingDistributionChannelUpdateParams>;
  /** Must be "AND" or "OR". */
  op: Scalars['String'];
};

/** Specifies a language for targeting, derived from request headers or similar client hints. */
export type TargetingLanguage = {
  __typename?: 'TargetingLanguage';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more languages.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.language")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingLanguageCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more languages.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.language")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingLanguageUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more languages.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.language")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** OpenX additional categories and keywords - allows to target or block specific inventory tagged with internal metacategories, and to target/block specific keywords on pages, based on OpenX Contextual service. */
export type TargetingMetaCategory = {
  __typename?: 'TargetingMetaCategory';
  /** If true, traffic should be blocked for inventory tagged with mfa metacategory */
  exclude_mfa?: Maybe<Scalars['Boolean']>;
  /** The list of metacategory exclusions */
  excludes?: Maybe<Array<Scalars['String']>>;
  /** The list of metacategory inclusions */
  includes?: Maybe<Array<Scalars['String']>>;
  /** Logical connective (operator) between metacategory (`includes` and `excludes`) clauses and `keywords`. Can be `OR` (default) or `AND`. NOTE: `exclude_mfa` is always applied with `AND` operator, regardless of this setting. */
  inter_dimension_operator?: Maybe<Scalars['String']>;
  /** OpenX Contextual Targeting Service keywords targeting. At least one keyword has to be provided in either `includes` or `excludes` list. */
  keywords?: Maybe<TargetingMetaCategoryKeywords>;
};

export type TargetingMetaCategoryCreateParams = {
  /** If true, traffic should be blocked for inventory tagged with mfa metacategory */
  exclude_mfa?: InputMaybe<Scalars['Boolean']>;
  /** The list of metacategory exclusions */
  excludes?: InputMaybe<Array<Scalars['String']>>;
  /** The list of metacategory inclusions */
  includes?: InputMaybe<Array<Scalars['String']>>;
  /** Logical connective (operator) between metacategory (`includes` and `excludes`) clauses and `keywords`. Can be `OR` (default) or `AND`. NOTE: `exclude_mfa` is always applied with `AND` operator, regardless of this setting. */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** OpenX Contextual Targeting Service keywords targeting. At least one keyword has to be provided in either `includes` or `excludes` list. */
  keywords?: InputMaybe<TargetingMetaCategoryKeywordsCreateParams>;
};

/** OpenX Contextual Targeting Service keywords targeting. At least one keyword has to be provided in either `includes` or `excludes` list. */
export type TargetingMetaCategoryKeywords = {
  __typename?: 'TargetingMetaCategoryKeywords';
  /**
   * List of keywords to block. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  excludes?: Maybe<Array<Scalars['String']>>;
  /**
   * List of keywords to target. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  includes?: Maybe<Array<Scalars['String']>>;
};

export type TargetingMetaCategoryKeywordsCreateParams = {
  /**
   * List of keywords to block. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  excludes?: InputMaybe<Array<Scalars['String']>>;
  /**
   * List of keywords to target. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  includes?: InputMaybe<Array<Scalars['String']>>;
};

export type TargetingMetaCategoryKeywordsUpdateParams = {
  /**
   * List of keywords to block. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  excludes?: InputMaybe<Array<Scalars['String']>>;
  /**
   * List of keywords to target. Must contain alphabetic characters and hyphens only.
   *
   * Use a comma-delimited list to specify multiple selections, up to 2000 items.
   */
  includes?: InputMaybe<Array<Scalars['String']>>;
};

export type TargetingMetaCategoryUpdateParams = {
  /** If true, traffic should be blocked for inventory tagged with mfa metacategory */
  exclude_mfa?: InputMaybe<Scalars['Boolean']>;
  /** The list of metacategory exclusions */
  excludes?: InputMaybe<Array<Scalars['String']>>;
  /** The list of metacategory inclusions */
  includes?: InputMaybe<Array<Scalars['String']>>;
  /** Logical connective (operator) between metacategory (`includes` and `excludes`) clauses and `keywords`. Can be `OR` (default) or `AND`. NOTE: `exclude_mfa` is always applied with `AND` operator, regardless of this setting. */
  inter_dimension_operator?: InputMaybe<Scalars['String']>;
  /** OpenX Contextual Targeting Service keywords targeting. At least one keyword has to be provided in either `includes` or `excludes` list. */
  keywords?: InputMaybe<TargetingMetaCategoryKeywordsUpdateParams>;
};

/** Represents an audience created in the OpenAudience platform, when used for deal targeting. */
export type TargetingOpenaudienceCustom = {
  __typename?: 'TargetingOpenaudienceCustom';
  /** Must be "INTERSECTS" or "INVERSE IN". */
  op: Scalars['String'];
  /**
   * The OpenAudience Audience UUID, prefixed with "openaudience-".  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.audience.openaudience_custom")`.
   */
  val?: Maybe<Scalars['String']>;
};

export type TargetingOpenaudienceCustomCreateParams = {
  /** Must be "INTERSECTS" or "INVERSE IN". */
  op: Scalars['String'];
  /**
   * The OpenAudience Audience UUID, prefixed with "openaudience-".  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.audience.openaudience_custom")`.
   */
  val?: InputMaybe<Scalars['String']>;
};

export type TargetingOpenaudienceCustomUpdateParams = {
  /** Must be "INTERSECTS" or "INVERSE IN". */
  op: Scalars['String'];
  /**
   * The OpenAudience Audience UUID, prefixed with "openaudience-".  Must match the `id` field returned
   * from `optionsByPath(path: "deal.package.targeting.audience.openaudience_custom")`.
   */
  val?: InputMaybe<Scalars['String']>;
};

/** Specifies a device operating system for targeting, derived from User-Agent headers or similar client hints. */
export type TargetingOs = {
  __typename?: 'TargetingOs';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more operating systems.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.os")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingOsCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more operating systems.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.os")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingOsUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more operating systems.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.technographic.os")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/** Specifies rewarded video inventory. */
export type TargetingRewardedVideo = {
  __typename?: 'TargetingRewardedVideo';
  /** Must be "==" or "!=". */
  op: Scalars['String'];
  /** Must be the string "true" to target rewarded video inventory. */
  val?: Maybe<Scalars['String']>;
};

export type TargetingRewardedVideoCreateParams = {
  /** Must be "==" or "!=". */
  op: Scalars['String'];
  /** Must be the string "true" to target rewarded video inventory. */
  val?: InputMaybe<Scalars['String']>;
};

export type TargetingRewardedVideoUpdateParams = {
  /** Must be "==" or "!=". */
  op: Scalars['String'];
  /** Must be the string "true" to target rewarded video inventory. */
  val?: InputMaybe<Scalars['String']>;
};

/** Specifies screen locations for video inventory (pre-roll, mid-roll, post-roll). */
export type TargetingScreenLocation = {
  __typename?: 'TargetingScreenLocation';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more screen locations.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.screen_location")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingScreenLocationCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more screen locations.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.screen_location")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingScreenLocationUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more screen locations.  Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.screen_location")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/**
 * Specifies video skipability. A value of "0" indicates non-skippable video,
 * where a value of "1" indicates the video is skippable.
 */
export type TargetingSkip = {
  __typename?: 'TargetingSkip';
  /** Must be "==". */
  op: Scalars['String'];
  /**
   * A value of "0" indicates non-skippable video,
   * where a value of "1" indicates the video is skippable.
   */
  val?: Maybe<Scalars['String']>;
};

/**
 * Specifies video skipability. A value of "0" indicates non-skippable video,
 * where a value of "1" indicates the video is skippable.
 */
export type TargetingSkipCreateParams = {
  /** Must be "==". */
  op: Scalars['String'];
  /**
   * A value of "0" indicates non-skippable video,
   * where a value of "1" indicates the video is skippable.
   */
  val: Scalars['String'];
};

/**
 * Specifies video skipability. A value of "0" indicates non-skippable video,
 * where a value of "1" indicates the video is skippable.
 */
export type TargetingSkipUpdateParams = {
  /** Must be "==". */
  op: Scalars['String'];
  /**
   * A value of "0" indicates non-skippable video,
   * where a value of "1" indicates the video is skippable.
   */
  val: Scalars['String'];
};

/** Targets technographic characteristics of the user's device. */
export type TargetingTechnographic = {
  __typename?: 'TargetingTechnographic';
  /** Target by API framework. */
  api_framework?: Maybe<TargetingApiFramework>;
  /** Target by browser, derived from User-Agent headers or similar client hints. */
  browser?: Maybe<TargetingBrowser>;
  /** Target by device type. */
  device_type?: Maybe<TargetingDeviceType>;
  /** Target by language, derived from request headers or similar client hints. */
  language?: Maybe<TargetingLanguage>;
  /** Target by operating system, derived from User-Agent headers or similar client hints. */
  os?: Maybe<TargetingOs>;
};

export type TargetingTechnographicCreateParams = {
  /** Target by API framework. */
  api_framework?: InputMaybe<TargetingApiFrameworkCreateParams>;
  /** Target by browser, derived from User-Agent headers or similar client hints. */
  browser?: InputMaybe<TargetingBrowserCreateParams>;
  /** Target by device type. */
  device_type?: InputMaybe<TargetingDeviceTypeCreateParams>;
  /** Target by language, derived from request headers or similar client hints. */
  language?: InputMaybe<TargetingLanguageCreateParams>;
  /** Target by operating system, derived from User-Agent headers or similar client hints. */
  os?: InputMaybe<TargetingOsCreateParams>;
};

export type TargetingTechnographicUpdateParams = {
  /** Target by API framework. */
  api_framework?: InputMaybe<TargetingApiFrameworkUpdateParams>;
  /** Target by browser, derived from User-Agent headers or similar client hints. */
  browser?: InputMaybe<TargetingBrowserUpdateParams>;
  /** Target by device type. */
  device_type?: InputMaybe<TargetingDeviceTypeUpdateParams>;
  /** Target by language, derived from request headers or similar client hints. */
  language?: InputMaybe<TargetingLanguageUpdateParams>;
  /** Target by operating system, derived from User-Agent headers or similar client hints. */
  os?: InputMaybe<TargetingOsUpdateParams>;
};

export type TargetingUpdateParams = {
  /** Targeting object specifying an Audience. */
  audience?: InputMaybe<TargetingAudienceUpdateParams>;
  /** Targeting object specifying some supply-side inventory content (adunit, page, site, etc). */
  content?: InputMaybe<TargetingContentUpdateParams>;
  /** Targeting object specifying custom key/value pairs. */
  custom?: InputMaybe<TargetingCustomUpdateParams>;
  /** Targeting object specifying one or more domain categories. */
  domain?: InputMaybe<TargetingDomainUpdateParams>;
  /** Boolean if true, the package will not be matched against indirect inventory */
  exclude_non_direct?: InputMaybe<Scalars['Boolean']>;
  /** Targeting object specifying inferred geolocation at request time. */
  geographic?: InputMaybe<TargetingGeographicUpdateParams>;
  /** Value of 1, the package will include open bidding inventory */
  include_open_bidding_inventory?: InputMaybe<Scalars['StringOrInt']>;
  /** Attributes used for Content object targeting defined in OpenRTB v2.5 spec */
  instream_content?: InputMaybe<TargetingInstreamContentUpdateParams>;
  /** Specifies whether targeting specs must all match ('AND') or if only one is required ('OR'). */
  inter_dimension_operator: Scalars['String'];
  /** Targeting object specifying inventory formats. */
  inventory_format?: InputMaybe<TargetingInventoryFormatUpdateParams>;
  /** OpenX additional categories and keywords - allows to target or block specific inventory tagged with internal metacategories, and to target/block specific keywords on pages, based on OpenX Contextual service. */
  metacategory?: InputMaybe<TargetingMetaCategoryUpdateParams>;
  /** Targeting object specifying technographic characteristics of the request. */
  technographic?: InputMaybe<TargetingTechnographicUpdateParams>;
  /** Targeting object specifying video characteristics. */
  video?: InputMaybe<TargetingVideoUpdateParams>;
  /** Targeting object specifying viewability characteristics. */
  viewability?: InputMaybe<TargetingViewabilityUpdateParams>;
  /** Targeting object specifying VTR characteristics. */
  vtr?: InputMaybe<TargetingVtrUpdateParams>;
};

/** Targets video inventory attributes. */
export type TargetingVideo = {
  __typename?: 'TargetingVideo';
  /** Target by the ad unit's maximum duration. */
  adunit_max_duration?: Maybe<TargetingAdunitMaxDuration>;
  /** Target rewarded video inventory. */
  rewarded_video?: Maybe<TargetingRewardedVideo>;
  /** Target by screen location. */
  screen_location?: Maybe<TargetingScreenLocation>;
  /** Target by video skipability. */
  skip?: Maybe<TargetingSkip>;
  /** Target by video format (pre-OpenRTB 2.x "placement" subtypes). */
  video_format?: Maybe<TargetingVideoFormat>;
  /** Target by video placement (OpenRTB 2.x "plcmt" subtypes). */
  video_plcmt?: Maybe<TargetingVideoPlcmt>;
};

export type TargetingVideoCreateParams = {
  /** Target by the ad unit's maximum duration. */
  adunit_max_duration?: InputMaybe<TargetingAdunitMaxDurationCreateParams>;
  /** Target rewarded video inventory. */
  rewarded_video?: InputMaybe<TargetingRewardedVideoCreateParams>;
  /** Target by screen location. */
  screen_location?: InputMaybe<TargetingScreenLocationCreateParams>;
  /** Target by video skipability */
  skip?: InputMaybe<TargetingSkipCreateParams>;
  /** Target by video format (pre-OpenRTB 2.0 "placement" subtypes). */
  video_format?: InputMaybe<TargetingVideoFormatCreateParams>;
  /** Target by video placement (OpenRTB 2.x "plcmt" subtypes). */
  video_plcmt?: InputMaybe<TargetingVideoPlcmtCreateParams>;
};

/**
 * Specifies video presentation formats (instream, interstitial, out-stream) using
 * the pre-OpenRTB 2.x definition of "placement" subtypes.
 */
export type TargetingVideoFormat = {
  __typename?: 'TargetingVideoFormat';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video formats (pre-OpenRTB 2.x "placement" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_format")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingVideoFormatCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video formats (pre-OpenRTB 2.x "placement" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_format")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingVideoFormatUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video formats (pre-OpenRTB 2.x "placement" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_format")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

/**
 * Specifies video placement (instream, interstitial, accompanying content, standalone)
 * using the OpenRTB 2.x definition of "plcmt" subtypes.
 */
export type TargetingVideoPlcmt = {
  __typename?: 'TargetingVideoPlcmt';
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video placements (OpenRTB 2.x "plcmt" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_plcmt")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingVideoPlcmtCreateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video placements (OpenRTB 2.x "plcmt" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_plcmt")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingVideoPlcmtUpdateParams = {
  /** Must be "INTERSECTS" or "NOT INTERSECTS". */
  op: Scalars['String'];
  /**
   * Target one or more video placements (OpenRTB 2.x "plcmt" subtypes).
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.video.video_plcmt")`.)
   * Use a comma-delimited list to specify multiple selections.
   */
  val: Scalars['String'];
};

export type TargetingVideoUpdateParams = {
  /** Target by the ad unit's maximum duration. */
  adunit_max_duration?: InputMaybe<TargetingAdunitMaxDurationUpdateParams>;
  /** Target rewarded video inventory. */
  rewarded_video?: InputMaybe<TargetingRewardedVideoUpdateParams>;
  /** Target by screen location. */
  screen_location?: InputMaybe<TargetingScreenLocationUpdateParams>;
  /** Target by video skipability */
  skip?: InputMaybe<TargetingSkipUpdateParams>;
  /** Target by video format (pre-OpenRTB 2.x "placement" subtypes). */
  video_format?: InputMaybe<TargetingVideoFormatUpdateParams>;
  /** Target by video format (OpenRTB 2.x "plcmt" subtypes). */
  video_plcmt?: InputMaybe<TargetingVideoPlcmtUpdateParams>;
};

/** Targets inventory at or above a defined viewability metric. */
export type TargetingViewability = {
  __typename?: 'TargetingViewability';
  /** Specify the viewability score threshold */
  viewability_score: TargetingViewabilityScore;
};

export type TargetingViewabilityCreateParams = {
  /** Specify the viewability score threshold */
  viewability_score: TargetingViewabilityScoreCreateParams;
};

/** Targets inventory at or above a defined viewability metric. */
export type TargetingViewabilityScore = {
  __typename?: 'TargetingViewabilityScore';
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a viewability score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.viewability.viewability_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingViewabilityScoreCreateParams = {
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a viewability score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.viewability.viewability_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingViewabilityScoreUpdateParams = {
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a viewability score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.viewability.viewability_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingViewabilityUpdateParams = {
  /** Specify the viewability score threshold */
  viewability_score: TargetingViewabilityScoreUpdateParams;
};

/** Targets inventory at or above a defined VTR (view-through rate) metric. */
export type TargetingVtr = {
  __typename?: 'TargetingVtr';
  /** Specify the VTR score threshold */
  vtr_score: TargetingVtrScore;
};

export type TargetingVtrCreateParams = {
  /** Specify the VTR (view-through rate) score threshold */
  vtr_score: TargetingVtrScoreCreateParams;
};

/** Targets inventory at or above a defined VTR (view-through rate) metric. */
export type TargetingVtrScore = {
  __typename?: 'TargetingVtrScore';
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a VTR score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.vtr.vtr_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingVtrScoreCreateParams = {
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a VTR score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.vtr.vtr_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingVtrScoreUpdateParams = {
  /** Must be ">=". */
  op: Scalars['String'];
  /**
   * Target a VTR score expressed as a decimal fraction (ie, 50% should be expressed as "0.50").
   * Must match the `id` field returned from
   * `optionsByPath(path: "deal.package.targeting.vtr.vtr_score")`.)
   */
  val: Scalars['String'];
};

export type TargetingVtrUpdateParams = {
  /** Specify the VTR (view-through rate) score threshold */
  vtr_score: TargetingVtrScoreUpdateParams;
};

export type ThirdPartyFeesConfigInput = {
  gross_cpm_cap?: InputMaybe<Scalars['String']>;
  gross_share?: InputMaybe<Scalars['String']>;
  partner_id: Scalars['String'];
  platform_partner_id?: InputMaybe<Scalars['String']>;
  platform_share?: InputMaybe<Scalars['String']>;
  revenue_method: Scalars['String'];
};

export type TroubleshootingOutput = {
  __typename?: 'TroubleshootingOutput';
  stats: TroubleshootingOutputStats;
  timestamp: Scalars['timestamptz'];
};

export type TroubleshootingOutputStats = {
  __typename?: 'TroubleshootingOutputStats';
  bid_requests: Scalars['Float'];
  bid_responses: Scalars['Float'];
  impressions: Scalars['Float'];
};

/** URLTargeting is used to specify an allow-list or block-list of URLs for the deal. */
export type UrlTargeting = {
  __typename?: 'URLTargeting';
  /** Must be "whitelist" or "blacklist". */
  type?: Maybe<Scalars['String']>;
  /** One or more domain names. */
  urls?: Maybe<Array<Scalars['String']>>;
};

export type UrlTargetingCreateParams = {
  /** Must be "whitelist" or "blacklist". */
  type?: InputMaybe<Scalars['String']>;
  /** One or more domain names. */
  urls?: InputMaybe<Array<Scalars['String']>>;
};

export type UrlTargetingUpdateParams = {
  /** Must be "whitelist" or "blacklist". */
  type?: InputMaybe<Scalars['String']>;
  /** One or more domain names. */
  urls?: InputMaybe<Array<Scalars['String']>>;
};

export type UnknownError = BaseError & {
  __typename?: 'UnknownError';
  message: Scalars['String'];
};

export type UserDataInput = {
  data: Array<InputMaybe<Array<Scalars['String']>>>;
  headers: Array<InputMaybe<Scalars['String']>>;
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "account" */
export type Account = {
  __typename?: 'account';
  allowed_countries: Scalars['jsonb'];
  allowed_domains?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  allowed_providers: Array<Account_Provider>;
  /** An aggregate relationship */
  allowed_providers_aggregate: Account_Provider_Aggregate;
  /** An array relationship */
  audiences: Array<Audience>;
  /** An aggregate relationship */
  audiences_aggregate: Audience_Aggregate;
  created_date: Scalars['timestamptz'];
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  /** Default Platform Share */
  default_platform_share?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exchange_account_network?: Maybe<Scalars['jsonb']>;
  /** A computed field, executes function "exchange_account_network_currency" */
  exchange_account_network_currency?: Maybe<Scalars['String']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  exchange_account_network_revision_applied?: Maybe<Scalars['Int']>;
  exchange_account_partner?: Maybe<Scalars['jsonb']>;
  exchange_account_partner_id?: Maybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Int']>;
  /** An array relationship */
  exports: Array<Export>;
  /** An aggregate relationship */
  exports_aggregate: Export_Aggregate;
  /** An array relationship */
  feature_flags: Array<Account_Feature_Flags>;
  /** An aggregate relationship */
  feature_flags_aggregate: Account_Feature_Flags_Aggregate;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  /** Default Max CPM cap */
  max_cpm_cap?: Maybe<Scalars['String']>;
  /** Default Max Share */
  max_share?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  parent_account?: Maybe<Account>;
  parent_account_id?: Maybe<Scalars['uuid']>;
  path_to_root: Scalars['_text'];
  resource_id: Scalars['String'];
  /** An array relationship */
  segments: Array<Segment>;
  /** An aggregate relationship */
  segments_aggregate: Segment_Aggregate;
  uds_account_id?: Maybe<Scalars['String']>;
  updated_date: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<User>;
  /** An aggregate relationship */
  users_aggregate: User_Aggregate;
};


/** columns and relationships of "account" */
export type AccountAllowed_CountriesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountAllowed_DomainsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountAllowed_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAllowed_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAudiencesArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountAudiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountDealsArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountExchange_Account_NetworkArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountExchange_Account_PartnerArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "account" */
export type AccountExportsArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountExports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountFeature_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountFeature_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Feature_Flags_Order_By>>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountSegmentsArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountSegments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By>>;
  where?: InputMaybe<Segment_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountUsersArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


/** columns and relationships of "account" */
export type AccountUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** aggregated selection of "account" */
export type Account_Aggregate = {
  __typename?: 'account_aggregate';
  aggregate?: Maybe<Account_Aggregate_Fields>;
  nodes: Array<Account>;
};

/** aggregate fields of "account" */
export type Account_Aggregate_Fields = {
  __typename?: 'account_aggregate_fields';
  avg?: Maybe<Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Account_Max_Fields>;
  min?: Maybe<Account_Min_Fields>;
  stddev?: Maybe<Account_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Sum_Fields>;
  var_pop?: Maybe<Account_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Var_Samp_Fields>;
  variance?: Maybe<Account_Variance_Fields>;
};


/** aggregate fields of "account" */
export type Account_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Account_Append_Input = {
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  allowed_domains?: InputMaybe<Scalars['jsonb']>;
  exchange_account_network?: InputMaybe<Scalars['jsonb']>;
  exchange_account_partner?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Account_Avg_Fields = {
  __typename?: 'account_avg_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "account". All fields are combined with a logical 'AND'. */
export type Account_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Bool_Exp>>;
  _not?: InputMaybe<Account_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Bool_Exp>>;
  allowed_countries?: InputMaybe<Jsonb_Comparison_Exp>;
  allowed_domains?: InputMaybe<Jsonb_Comparison_Exp>;
  allowed_providers?: InputMaybe<Account_Provider_Bool_Exp>;
  allowed_providers_aggregate?: InputMaybe<Account_Provider_Aggregate_Bool_Exp>;
  audiences?: InputMaybe<Audience_Bool_Exp>;
  audiences_aggregate?: InputMaybe<Audience_Aggregate_Bool_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  exchange_account_network?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_account_network_currency?: InputMaybe<String_Comparison_Exp>;
  exchange_account_network_id?: InputMaybe<String_Comparison_Exp>;
  exchange_account_network_revision_applied?: InputMaybe<Int_Comparison_Exp>;
  exchange_account_partner?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_account_partner_id?: InputMaybe<String_Comparison_Exp>;
  exchange_account_partner_revision_applied?: InputMaybe<Int_Comparison_Exp>;
  exports?: InputMaybe<Export_Bool_Exp>;
  exports_aggregate?: InputMaybe<Export_Aggregate_Bool_Exp>;
  feature_flags?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
  feature_flags_aggregate?: InputMaybe<Account_Feature_Flags_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_account?: InputMaybe<Account_Bool_Exp>;
  parent_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  path_to_root?: InputMaybe<_Text_Comparison_Exp>;
  resource_id?: InputMaybe<String_Comparison_Exp>;
  segments?: InputMaybe<Segment_Bool_Exp>;
  segments_aggregate?: InputMaybe<Segment_Aggregate_Bool_Exp>;
  uds_account_id?: InputMaybe<String_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<User_Bool_Exp>;
  users_aggregate?: InputMaybe<User_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "account" */
export enum Account_Constraint {
  /** unique or primary key constraint on columns "exchange_account_network_id" */
  AccountExchangeAccountNetworkIdKey = 'account_exchange_account_network_id_key',
  /** unique or primary key constraint on columns "exchange_account_partner_id" */
  AccountExchangeAccountPartnerIdKey = 'account_exchange_account_partner_id_key',
  /** unique or primary key constraint on columns "name" */
  AccountNameKey = 'account_name_key',
  /** unique or primary key constraint on columns "id" */
  AccountPkey = 'account_pkey',
  /** unique or primary key constraint on columns "resource_id" */
  AccountResourceIdKey = 'account_resource_id_key',
  /** unique or primary key constraint on columns "uds_account_id" */
  AccountUdsAccountIdKey = 'account_uds_account_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Account_Delete_At_Path_Input = {
  allowed_countries?: InputMaybe<Array<Scalars['String']>>;
  allowed_domains?: InputMaybe<Array<Scalars['String']>>;
  exchange_account_network?: InputMaybe<Array<Scalars['String']>>;
  exchange_account_partner?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Account_Delete_Elem_Input = {
  allowed_countries?: InputMaybe<Scalars['Int']>;
  allowed_domains?: InputMaybe<Scalars['Int']>;
  exchange_account_network?: InputMaybe<Scalars['Int']>;
  exchange_account_partner?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Account_Delete_Key_Input = {
  allowed_countries?: InputMaybe<Scalars['String']>;
  allowed_domains?: InputMaybe<Scalars['String']>;
  exchange_account_network?: InputMaybe<Scalars['String']>;
  exchange_account_partner?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "account_feature_flags" */
export type Account_Feature_Flags = {
  __typename?: 'account_feature_flags';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  id: Scalars['uuid'];
  value: Feature_Flags_Enum;
};

/** aggregated selection of "account_feature_flags" */
export type Account_Feature_Flags_Aggregate = {
  __typename?: 'account_feature_flags_aggregate';
  aggregate?: Maybe<Account_Feature_Flags_Aggregate_Fields>;
  nodes: Array<Account_Feature_Flags>;
};

export type Account_Feature_Flags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_Feature_Flags_Aggregate_Bool_Exp_Count>;
};

export type Account_Feature_Flags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_feature_flags" */
export type Account_Feature_Flags_Aggregate_Fields = {
  __typename?: 'account_feature_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Feature_Flags_Max_Fields>;
  min?: Maybe<Account_Feature_Flags_Min_Fields>;
};


/** aggregate fields of "account_feature_flags" */
export type Account_Feature_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Feature_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_feature_flags" */
export type Account_Feature_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Feature_Flags_Max_Order_By>;
  min?: InputMaybe<Account_Feature_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account_feature_flags" */
export type Account_Feature_Flags_Arr_Rel_Insert_Input = {
  data: Array<Account_Feature_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Feature_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_feature_flags". All fields are combined with a logical 'AND'. */
export type Account_Feature_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Feature_Flags_Bool_Exp>>;
  _not?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Feature_Flags_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Feature_Flags_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_feature_flags" */
export enum Account_Feature_Flags_Constraint {
  /** unique or primary key constraint on columns "account_id", "value" */
  AccountFeatureFlagsAccountIdValueKey = 'account_feature_flags_account_id_value_key',
  /** unique or primary key constraint on columns "id" */
  AccountFeatureFlagsPkey = 'account_feature_flags_pkey'
}

/** input type for inserting data into table "account_feature_flags" */
export type Account_Feature_Flags_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Feature_Flags_Enum>;
};

/** aggregate max on columns */
export type Account_Feature_Flags_Max_Fields = {
  __typename?: 'account_feature_flags_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "account_feature_flags" */
export type Account_Feature_Flags_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Feature_Flags_Min_Fields = {
  __typename?: 'account_feature_flags_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "account_feature_flags" */
export type Account_Feature_Flags_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_feature_flags" */
export type Account_Feature_Flags_Mutation_Response = {
  __typename?: 'account_feature_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Feature_Flags>;
};

/** on_conflict condition type for table "account_feature_flags" */
export type Account_Feature_Flags_On_Conflict = {
  constraint: Account_Feature_Flags_Constraint;
  update_columns?: Array<Account_Feature_Flags_Update_Column>;
  where?: InputMaybe<Account_Feature_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "account_feature_flags". */
export type Account_Feature_Flags_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_feature_flags */
export type Account_Feature_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account_feature_flags" */
export enum Account_Feature_Flags_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "account_feature_flags" */
export type Account_Feature_Flags_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Feature_Flags_Enum>;
};

/** Streaming cursor of the table "account_feature_flags" */
export type Account_Feature_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Feature_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Feature_Flags_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Feature_Flags_Enum>;
};

/** update columns of table "account_feature_flags" */
export enum Account_Feature_Flags_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

export type Account_Feature_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Feature_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Feature_Flags_Bool_Exp;
};

/** input type for incrementing numeric columns in table "account" */
export type Account_Inc_Input = {
  exchange_account_network_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_account_partner_revision_applied?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "account" */
export type Account_Insert_Input = {
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  allowed_domains?: InputMaybe<Scalars['jsonb']>;
  allowed_providers?: InputMaybe<Account_Provider_Arr_Rel_Insert_Input>;
  audiences?: InputMaybe<Audience_Arr_Rel_Insert_Input>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  /** Default Platform Share */
  default_platform_share?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exchange_account_network?: InputMaybe<Scalars['jsonb']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  exchange_account_network_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_account_partner?: InputMaybe<Scalars['jsonb']>;
  exchange_account_partner_id?: InputMaybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: InputMaybe<Scalars['Int']>;
  exports?: InputMaybe<Export_Arr_Rel_Insert_Input>;
  feature_flags?: InputMaybe<Account_Feature_Flags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** Default Max CPM cap */
  max_cpm_cap?: InputMaybe<Scalars['String']>;
  /** Default Max Share */
  max_share?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  parent_account_id?: InputMaybe<Scalars['uuid']>;
  path_to_root?: InputMaybe<Scalars['_text']>;
  resource_id?: InputMaybe<Scalars['String']>;
  segments?: InputMaybe<Segment_Arr_Rel_Insert_Input>;
  uds_account_id?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<User_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Account_Max_Fields = {
  __typename?: 'account_max_fields';
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  exchange_account_network_revision_applied?: Maybe<Scalars['Int']>;
  exchange_account_partner_id?: Maybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_account_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['String']>;
  uds_account_id?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Account_Min_Fields = {
  __typename?: 'account_min_fields';
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  exchange_account_network_revision_applied?: Maybe<Scalars['Int']>;
  exchange_account_partner_id?: Maybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_account_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['String']>;
  uds_account_id?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "account" */
export type Account_Mutation_Response = {
  __typename?: 'account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account>;
};

/** input type for inserting object relation for remote table "account" */
export type Account_Obj_Rel_Insert_Input = {
  data: Account_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_On_Conflict>;
};

/** on_conflict condition type for table "account" */
export type Account_On_Conflict = {
  constraint: Account_Constraint;
  update_columns?: Array<Account_Update_Column>;
  where?: InputMaybe<Account_Bool_Exp>;
};

/** Ordering options when selecting data from "account". */
export type Account_Order_By = {
  allowed_countries?: InputMaybe<Order_By>;
  allowed_domains?: InputMaybe<Order_By>;
  allowed_providers_aggregate?: InputMaybe<Account_Provider_Aggregate_Order_By>;
  audiences_aggregate?: InputMaybe<Audience_Aggregate_Order_By>;
  created_date?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  exchange_account_network?: InputMaybe<Order_By>;
  exchange_account_network_currency?: InputMaybe<Order_By>;
  exchange_account_network_id?: InputMaybe<Order_By>;
  exchange_account_network_revision_applied?: InputMaybe<Order_By>;
  exchange_account_partner?: InputMaybe<Order_By>;
  exchange_account_partner_id?: InputMaybe<Order_By>;
  exchange_account_partner_revision_applied?: InputMaybe<Order_By>;
  exports_aggregate?: InputMaybe<Export_Aggregate_Order_By>;
  feature_flags_aggregate?: InputMaybe<Account_Feature_Flags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_account?: InputMaybe<Account_Order_By>;
  parent_account_id?: InputMaybe<Order_By>;
  path_to_root?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  segments_aggregate?: InputMaybe<Segment_Aggregate_Order_By>;
  uds_account_id?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<User_Aggregate_Order_By>;
};

/** primary key columns input for table: account */
export type Account_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Account_Prepend_Input = {
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  allowed_domains?: InputMaybe<Scalars['jsonb']>;
  exchange_account_network?: InputMaybe<Scalars['jsonb']>;
  exchange_account_partner?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "account_provider" */
export type Account_Provider = {
  __typename?: 'account_provider';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Provider;
  provider_id: Scalars['String'];
};

/** aggregated selection of "account_provider" */
export type Account_Provider_Aggregate = {
  __typename?: 'account_provider_aggregate';
  aggregate?: Maybe<Account_Provider_Aggregate_Fields>;
  nodes: Array<Account_Provider>;
};

export type Account_Provider_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_Provider_Aggregate_Bool_Exp_Count>;
};

export type Account_Provider_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Account_Provider_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_provider" */
export type Account_Provider_Aggregate_Fields = {
  __typename?: 'account_provider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Account_Provider_Max_Fields>;
  min?: Maybe<Account_Provider_Min_Fields>;
};


/** aggregate fields of "account_provider" */
export type Account_Provider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_provider" */
export type Account_Provider_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Provider_Max_Order_By>;
  min?: InputMaybe<Account_Provider_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account_provider" */
export type Account_Provider_Arr_Rel_Insert_Input = {
  data: Array<Account_Provider_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Provider_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_provider". All fields are combined with a logical 'AND'. */
export type Account_Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Provider_Bool_Exp>>;
  _not?: InputMaybe<Account_Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Provider_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_provider" */
export enum Account_Provider_Constraint {
  /** unique or primary key constraint on columns "provider_id", "account_id" */
  AccountProviderAccountIdProviderIdKey = 'account_provider_account_id_provider_id_key',
  /** unique or primary key constraint on columns "id" */
  AccountProviderPkey = 'account_provider_pkey'
}

/** input type for inserting data into table "account_provider" */
export type Account_Provider_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Account_Provider_Max_Fields = {
  __typename?: 'account_provider_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "account_provider" */
export type Account_Provider_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Provider_Min_Fields = {
  __typename?: 'account_provider_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  provider_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "account_provider" */
export type Account_Provider_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_provider" */
export type Account_Provider_Mutation_Response = {
  __typename?: 'account_provider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Provider>;
};

/** on_conflict condition type for table "account_provider" */
export type Account_Provider_On_Conflict = {
  constraint: Account_Provider_Constraint;
  update_columns?: Array<Account_Provider_Update_Column>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "account_provider". */
export type Account_Provider_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_provider */
export type Account_Provider_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "account_provider" */
export enum Account_Provider_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id'
}

/** input type for updating data in table "account_provider" */
export type Account_Provider_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "account_provider" */
export type Account_Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Provider_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "account_provider" */
export enum Account_Provider_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'provider_id'
}

export type Account_Provider_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Provider_Bool_Exp;
};

/** select columns of table "account" */
export enum Account_Select_Column {
  /** column name */
  AllowedCountries = 'allowed_countries',
  /** column name */
  AllowedDomains = 'allowed_domains',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeAccountNetwork = 'exchange_account_network',
  /** column name */
  ExchangeAccountNetworkId = 'exchange_account_network_id',
  /** column name */
  ExchangeAccountNetworkRevisionApplied = 'exchange_account_network_revision_applied',
  /** column name */
  ExchangeAccountPartner = 'exchange_account_partner',
  /** column name */
  ExchangeAccountPartnerId = 'exchange_account_partner_id',
  /** column name */
  ExchangeAccountPartnerRevisionApplied = 'exchange_account_partner_revision_applied',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentAccountId = 'parent_account_id',
  /** column name */
  PathToRoot = 'path_to_root',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UdsAccountId = 'uds_account_id',
  /** column name */
  UpdatedDate = 'updated_date'
}

/** input type for updating data in table "account" */
export type Account_Set_Input = {
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  allowed_domains?: InputMaybe<Scalars['jsonb']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  /** Default Platform Share */
  default_platform_share?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  exchange_account_network?: InputMaybe<Scalars['jsonb']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  exchange_account_network_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_account_partner?: InputMaybe<Scalars['jsonb']>;
  exchange_account_partner_id?: InputMaybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  /** Default Max CPM cap */
  max_cpm_cap?: InputMaybe<Scalars['String']>;
  /** Default Max Share */
  max_share?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent_account_id?: InputMaybe<Scalars['uuid']>;
  path_to_root?: InputMaybe<Scalars['_text']>;
  resource_id?: InputMaybe<Scalars['String']>;
  uds_account_id?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Account_Stddev_Fields = {
  __typename?: 'account_stddev_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Account_Stddev_Pop_Fields = {
  __typename?: 'account_stddev_pop_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Account_Stddev_Samp_Fields = {
  __typename?: 'account_stddev_samp_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "account" */
export type Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Stream_Cursor_Value_Input = {
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  allowed_domains?: InputMaybe<Scalars['jsonb']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  exchange_account_network?: InputMaybe<Scalars['jsonb']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  exchange_account_network_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_account_partner?: InputMaybe<Scalars['jsonb']>;
  exchange_account_partner_id?: InputMaybe<Scalars['String']>;
  exchange_account_partner_revision_applied?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent_account_id?: InputMaybe<Scalars['uuid']>;
  path_to_root?: InputMaybe<Scalars['_text']>;
  resource_id?: InputMaybe<Scalars['String']>;
  uds_account_id?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Account_Sum_Fields = {
  __typename?: 'account_sum_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Int']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Int']>;
};

/** update columns of table "account" */
export enum Account_Update_Column {
  /** column name */
  AllowedCountries = 'allowed_countries',
  /** column name */
  AllowedDomains = 'allowed_domains',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  ExchangeAccountNetwork = 'exchange_account_network',
  /** column name */
  ExchangeAccountNetworkId = 'exchange_account_network_id',
  /** column name */
  ExchangeAccountNetworkRevisionApplied = 'exchange_account_network_revision_applied',
  /** column name */
  ExchangeAccountPartner = 'exchange_account_partner',
  /** column name */
  ExchangeAccountPartnerId = 'exchange_account_partner_id',
  /** column name */
  ExchangeAccountPartnerRevisionApplied = 'exchange_account_partner_revision_applied',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ParentAccountId = 'parent_account_id',
  /** column name */
  PathToRoot = 'path_to_root',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  UdsAccountId = 'uds_account_id',
  /** column name */
  UpdatedDate = 'updated_date'
}

export type Account_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Account_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Account_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Account_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Account_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Account_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Var_Pop_Fields = {
  __typename?: 'account_var_pop_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Account_Var_Samp_Fields = {
  __typename?: 'account_var_samp_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Account_Variance_Fields = {
  __typename?: 'account_variance_fields';
  exchange_account_network_revision_applied?: Maybe<Scalars['Float']>;
  exchange_account_partner_revision_applied?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "audience" */
export type Audience = {
  __typename?: 'audience';
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['uuid']>;
  addressable_reach?: Maybe<Scalars['Int']>;
  /** An array relationship */
  audience_segments: Array<Audience_Segment>;
  /** An aggregate relationship */
  audience_segments_aggregate: Audience_Segment_Aggregate;
  audience_share: Array<Maybe<AudienceShare>>;
  bq_reach_table?: Maybe<Scalars['String']>;
  bq_table?: Maybe<Scalars['String']>;
  created_date: Scalars['timestamptz'];
  created_with?: Maybe<Scalars['String']>;
  data_source: Scalars['String'];
  /** complies to the current AST format and the default value passed in from UI */
  definition?: Maybe<Scalars['jsonb']>;
  description?: Maybe<Scalars['String']>;
  direct_audience_provider: Scalars['String'];
  error?: Maybe<Scalars['jsonb']>;
  error_code?: Maybe<Scalars['String']>;
  /** A computed field, executes function "calc_expiry_date" */
  estimated_expiry_date?: Maybe<Scalars['timestamptz']>;
  estimated_reach?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_expired_date_if_expired" */
  expired_date?: Maybe<Scalars['timestamptz']>;
  export_targets?: Maybe<Scalars['jsonb']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type: Scalars['String'];
  /** An array relationship */
  exports: Array<Export>;
  /** An aggregate relationship */
  exports_aggregate: Export_Aggregate;
  id: Scalars['uuid'];
  info?: Maybe<Scalars['jsonb']>;
  is_archived: Scalars['Boolean'];
  job_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  last_export?: Maybe<Export>;
  last_export_id?: Maybe<Scalars['uuid']>;
  last_uds_timestamp?: Maybe<Scalars['timestamptz']>;
  name: Scalars['String'];
  normalized_definition?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  provider: Provider;
  reach?: Maybe<Scalars['Int']>;
  region: Scalars['String'];
  report_status?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tags?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  updated_date: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "audience" */
export type AudienceAudience_SegmentsArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


/** columns and relationships of "audience" */
export type AudienceAudience_Segments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


/** columns and relationships of "audience" */
export type AudienceAudience_ShareArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** columns and relationships of "audience" */
export type AudienceDefinitionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceErrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceExport_TargetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceExportsArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


/** columns and relationships of "audience" */
export type AudienceExports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Order_By>>;
  where?: InputMaybe<Export_Bool_Exp>;
};


/** columns and relationships of "audience" */
export type AudienceInfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceNormalized_DefinitionArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "audience" */
export type AudienceTagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audience" */
export type Audience_Aggregate = {
  __typename?: 'audience_aggregate';
  aggregate?: Maybe<Audience_Aggregate_Fields>;
  nodes: Array<Audience>;
};

export type Audience_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Audience_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Audience_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Audience_Aggregate_Bool_Exp_Count>;
};

export type Audience_Aggregate_Bool_Exp_Bool_And = {
  arguments: Audience_Select_Column_Audience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Audience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Audience_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Audience_Select_Column_Audience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Audience_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Audience_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Audience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Audience_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "audience" */
export type Audience_Aggregate_Fields = {
  __typename?: 'audience_aggregate_fields';
  avg?: Maybe<Audience_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Audience_Max_Fields>;
  min?: Maybe<Audience_Min_Fields>;
  stddev?: Maybe<Audience_Stddev_Fields>;
  stddev_pop?: Maybe<Audience_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audience_Stddev_Samp_Fields>;
  sum?: Maybe<Audience_Sum_Fields>;
  var_pop?: Maybe<Audience_Var_Pop_Fields>;
  var_samp?: Maybe<Audience_Var_Samp_Fields>;
  variance?: Maybe<Audience_Variance_Fields>;
};


/** aggregate fields of "audience" */
export type Audience_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audience_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audience" */
export type Audience_Aggregate_Order_By = {
  avg?: InputMaybe<Audience_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Audience_Max_Order_By>;
  min?: InputMaybe<Audience_Min_Order_By>;
  stddev?: InputMaybe<Audience_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Audience_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Audience_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Audience_Sum_Order_By>;
  var_pop?: InputMaybe<Audience_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Audience_Var_Samp_Order_By>;
  variance?: InputMaybe<Audience_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audience_Append_Input = {
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['jsonb']>;
  error?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  info?: InputMaybe<Scalars['jsonb']>;
  normalized_definition?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "audience" */
export type Audience_Arr_Rel_Insert_Input = {
  data: Array<Audience_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};

/** aggregate avg on columns */
export type Audience_Avg_Fields = {
  __typename?: 'audience_avg_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "audience" */
export type Audience_Avg_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "audience". All fields are combined with a logical 'AND'. */
export type Audience_Bool_Exp = {
  _and?: InputMaybe<Array<Audience_Bool_Exp>>;
  _not?: InputMaybe<Audience_Bool_Exp>;
  _or?: InputMaybe<Array<Audience_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  addressable_reach?: InputMaybe<Int_Comparison_Exp>;
  audience_segments?: InputMaybe<Audience_Segment_Bool_Exp>;
  audience_segments_aggregate?: InputMaybe<Audience_Segment_Aggregate_Bool_Exp>;
  bq_reach_table?: InputMaybe<String_Comparison_Exp>;
  bq_table?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_with?: InputMaybe<String_Comparison_Exp>;
  data_source?: InputMaybe<String_Comparison_Exp>;
  definition?: InputMaybe<Jsonb_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  direct_audience_provider?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<Jsonb_Comparison_Exp>;
  error_code?: InputMaybe<String_Comparison_Exp>;
  estimated_expiry_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  estimated_reach?: InputMaybe<Int_Comparison_Exp>;
  expired_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  export_targets?: InputMaybe<Jsonb_Comparison_Exp>;
  export_type?: InputMaybe<String_Comparison_Exp>;
  exports?: InputMaybe<Export_Bool_Exp>;
  exports_aggregate?: InputMaybe<Export_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Jsonb_Comparison_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<String_Comparison_Exp>;
  last_export?: InputMaybe<Export_Bool_Exp>;
  last_export_id?: InputMaybe<Uuid_Comparison_Exp>;
  last_uds_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  normalized_definition?: InputMaybe<Jsonb_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  reach?: InputMaybe<Int_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  report_status?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audience" */
export enum Audience_Constraint {
  /** unique or primary key constraint on columns "name", "account_id" */
  AudienceAccountIdNameKey = 'audience_account_id_name_key',
  /** unique or primary key constraint on columns "id" */
  AudiencePkey = 'audience_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audience_Delete_At_Path_Input = {
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Array<Scalars['String']>>;
  error?: InputMaybe<Array<Scalars['String']>>;
  export_targets?: InputMaybe<Array<Scalars['String']>>;
  info?: InputMaybe<Array<Scalars['String']>>;
  normalized_definition?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audience_Delete_Elem_Input = {
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['Int']>;
  error?: InputMaybe<Scalars['Int']>;
  export_targets?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['Int']>;
  normalized_definition?: InputMaybe<Scalars['Int']>;
  tags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audience_Delete_Key_Input = {
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  export_targets?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  normalized_definition?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "audience" */
export type Audience_Inc_Input = {
  addressable_reach?: InputMaybe<Scalars['Int']>;
  estimated_reach?: InputMaybe<Scalars['Int']>;
  reach?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "audience" */
export type Audience_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  addressable_reach?: InputMaybe<Scalars['Int']>;
  audience_segments?: InputMaybe<Audience_Segment_Arr_Rel_Insert_Input>;
  bq_reach_table?: InputMaybe<Scalars['String']>;
  bq_table?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  data_source?: InputMaybe<Scalars['String']>;
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  error_code?: InputMaybe<Scalars['String']>;
  estimated_reach?: InputMaybe<Scalars['Int']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: InputMaybe<Scalars['String']>;
  exports?: InputMaybe<Export_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['String']>;
  last_export?: InputMaybe<Export_Obj_Rel_Insert_Input>;
  last_export_id?: InputMaybe<Scalars['uuid']>;
  last_uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  normalized_definition?: InputMaybe<Scalars['jsonb']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  reach?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  report_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audience_Max_Fields = {
  __typename?: 'audience_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  addressable_reach?: Maybe<Scalars['Int']>;
  bq_reach_table?: Maybe<Scalars['String']>;
  bq_table?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  created_with?: Maybe<Scalars['String']>;
  data_source?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  direct_audience_provider?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  estimated_reach?: Maybe<Scalars['Int']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['String']>;
  last_export_id?: Maybe<Scalars['uuid']>;
  last_uds_timestamp?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  report_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "audience" */
export type Audience_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  addressable_reach?: InputMaybe<Order_By>;
  bq_reach_table?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  created_with?: InputMaybe<Order_By>;
  data_source?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_export_id?: InputMaybe<Order_By>;
  last_uds_timestamp?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  report_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Audience_Min_Fields = {
  __typename?: 'audience_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  addressable_reach?: Maybe<Scalars['Int']>;
  bq_reach_table?: Maybe<Scalars['String']>;
  bq_table?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  created_with?: Maybe<Scalars['String']>;
  data_source?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  direct_audience_provider?: Maybe<Scalars['String']>;
  error_code?: Maybe<Scalars['String']>;
  estimated_reach?: Maybe<Scalars['Int']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['String']>;
  last_export_id?: Maybe<Scalars['uuid']>;
  last_uds_timestamp?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  report_status?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "audience" */
export type Audience_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  addressable_reach?: InputMaybe<Order_By>;
  bq_reach_table?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  created_with?: InputMaybe<Order_By>;
  data_source?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_export_id?: InputMaybe<Order_By>;
  last_uds_timestamp?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  report_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "audience" */
export type Audience_Mutation_Response = {
  __typename?: 'audience_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audience>;
};

/** input type for inserting object relation for remote table "audience" */
export type Audience_Obj_Rel_Insert_Input = {
  data: Audience_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Audience_On_Conflict>;
};

/** on_conflict condition type for table "audience" */
export type Audience_On_Conflict = {
  constraint: Audience_Constraint;
  update_columns?: Array<Audience_Update_Column>;
  where?: InputMaybe<Audience_Bool_Exp>;
};

/** Ordering options when selecting data from "audience". */
export type Audience_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  addressable_reach?: InputMaybe<Order_By>;
  audience_segments_aggregate?: InputMaybe<Audience_Segment_Aggregate_Order_By>;
  bq_reach_table?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  created_with?: InputMaybe<Order_By>;
  data_source?: InputMaybe<Order_By>;
  definition?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  error_code?: InputMaybe<Order_By>;
  estimated_expiry_date?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  expired_date?: InputMaybe<Order_By>;
  export_targets?: InputMaybe<Order_By>;
  export_type?: InputMaybe<Order_By>;
  exports_aggregate?: InputMaybe<Export_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  is_archived?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  last_export?: InputMaybe<Export_Order_By>;
  last_export_id?: InputMaybe<Order_By>;
  last_uds_timestamp?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  normalized_definition?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  reach?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  report_status?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audience */
export type Audience_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audience_Prepend_Input = {
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['jsonb']>;
  error?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  info?: InputMaybe<Scalars['jsonb']>;
  normalized_definition?: InputMaybe<Scalars['jsonb']>;
  tags?: InputMaybe<Scalars['jsonb']>;
};

export type Audience_Reach = {
  __typename?: 'audience_reach';
  Cookies_With_Requests?: Maybe<Scalars['GraphQLUBigInt64']>;
  Mobile_Devices_With_Requests?: Maybe<Scalars['GraphQLUBigInt64']>;
  Tablet_Devices_With_Requests?: Maybe<Scalars['GraphQLUBigInt64']>;
  Unique_ConnectedTVDeviceIDs_With_Requests?: Maybe<Scalars['GraphQLUBigInt64']>;
};

/** columns and relationships of "audience_segment" */
export type Audience_Segment = {
  __typename?: 'audience_segment';
  /** An object relationship */
  audience: Audience;
  audience_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  segment: Segment;
  segment_id: Scalars['uuid'];
};

/** aggregated selection of "audience_segment" */
export type Audience_Segment_Aggregate = {
  __typename?: 'audience_segment_aggregate';
  aggregate?: Maybe<Audience_Segment_Aggregate_Fields>;
  nodes: Array<Audience_Segment>;
};

export type Audience_Segment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Audience_Segment_Aggregate_Bool_Exp_Count>;
};

export type Audience_Segment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Audience_Segment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "audience_segment" */
export type Audience_Segment_Aggregate_Fields = {
  __typename?: 'audience_segment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audience_Segment_Max_Fields>;
  min?: Maybe<Audience_Segment_Min_Fields>;
};


/** aggregate fields of "audience_segment" */
export type Audience_Segment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "audience_segment" */
export type Audience_Segment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Audience_Segment_Max_Order_By>;
  min?: InputMaybe<Audience_Segment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "audience_segment" */
export type Audience_Segment_Arr_Rel_Insert_Input = {
  data: Array<Audience_Segment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Audience_Segment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "audience_segment". All fields are combined with a logical 'AND'. */
export type Audience_Segment_Bool_Exp = {
  _and?: InputMaybe<Array<Audience_Segment_Bool_Exp>>;
  _not?: InputMaybe<Audience_Segment_Bool_Exp>;
  _or?: InputMaybe<Array<Audience_Segment_Bool_Exp>>;
  audience?: InputMaybe<Audience_Bool_Exp>;
  audience_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  segment?: InputMaybe<Segment_Bool_Exp>;
  segment_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "audience_segment" */
export enum Audience_Segment_Constraint {
  /** unique or primary key constraint on columns "segment_id", "audience_id" */
  AudienceSegmentAudienceIdSegmentIdKey = 'audience_segment_audience_id_segment_id_key',
  /** unique or primary key constraint on columns "id" */
  AudienceSegmentPkey = 'audience_segment_pkey'
}

/** input type for inserting data into table "audience_segment" */
export type Audience_Segment_Insert_Input = {
  audience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  segment?: InputMaybe<Segment_Obj_Rel_Insert_Input>;
  segment_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Audience_Segment_Max_Fields = {
  __typename?: 'audience_segment_max_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  segment_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "audience_segment" */
export type Audience_Segment_Max_Order_By = {
  audience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Audience_Segment_Min_Fields = {
  __typename?: 'audience_segment_min_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  segment_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "audience_segment" */
export type Audience_Segment_Min_Order_By = {
  audience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "audience_segment" */
export type Audience_Segment_Mutation_Response = {
  __typename?: 'audience_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audience_Segment>;
};

/** on_conflict condition type for table "audience_segment" */
export type Audience_Segment_On_Conflict = {
  constraint: Audience_Segment_Constraint;
  update_columns?: Array<Audience_Segment_Update_Column>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};

/** Ordering options when selecting data from "audience_segment". */
export type Audience_Segment_Order_By = {
  audience?: InputMaybe<Audience_Order_By>;
  audience_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment?: InputMaybe<Segment_Order_By>;
  segment_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audience_segment */
export type Audience_Segment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audience_segment" */
export enum Audience_Segment_Select_Column {
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id'
}

/** input type for updating data in table "audience_segment" */
export type Audience_Segment_Set_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  segment_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "audience_segment" */
export type Audience_Segment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audience_Segment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audience_Segment_Stream_Cursor_Value_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  segment_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "audience_segment" */
export enum Audience_Segment_Update_Column {
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id'
}

export type Audience_Segment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audience_Segment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audience_Segment_Bool_Exp;
};

/** select columns of table "audience" */
export enum Audience_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AddressableReach = 'addressable_reach',
  /** column name */
  BqReachTable = 'bq_reach_table',
  /** column name */
  BqTable = 'bq_table',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  CreatedWith = 'created_with',
  /** column name */
  DataSource = 'data_source',
  /** column name */
  Definition = 'definition',
  /** column name */
  Description = 'description',
  /** column name */
  DirectAudienceProvider = 'direct_audience_provider',
  /** column name */
  Error = 'error',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  EstimatedReach = 'estimated_reach',
  /** column name */
  ExportTargets = 'export_targets',
  /** column name */
  ExportType = 'export_type',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastExportId = 'last_export_id',
  /** column name */
  LastUdsTimestamp = 'last_uds_timestamp',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedDefinition = 'normalized_definition',
  /** column name */
  Reach = 'reach',
  /** column name */
  Region = 'region',
  /** column name */
  ReportStatus = 'report_status',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date',
  /** column name */
  UserId = 'user_id'
}

/** select "audience_aggregate_bool_exp_bool_and_arguments_columns" columns of table "audience" */
export enum Audience_Select_Column_Audience_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived'
}

/** select "audience_aggregate_bool_exp_bool_or_arguments_columns" columns of table "audience" */
export enum Audience_Select_Column_Audience_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived'
}

/** input type for updating data in table "audience" */
export type Audience_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  addressable_reach?: InputMaybe<Scalars['Int']>;
  bq_reach_table?: InputMaybe<Scalars['String']>;
  bq_table?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  data_source?: InputMaybe<Scalars['String']>;
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  error_code?: InputMaybe<Scalars['String']>;
  estimated_reach?: InputMaybe<Scalars['Int']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['String']>;
  last_export_id?: InputMaybe<Scalars['uuid']>;
  last_uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  normalized_definition?: InputMaybe<Scalars['jsonb']>;
  reach?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  report_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Audience_Stddev_Fields = {
  __typename?: 'audience_stddev_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "audience" */
export type Audience_Stddev_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Audience_Stddev_Pop_Fields = {
  __typename?: 'audience_stddev_pop_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "audience" */
export type Audience_Stddev_Pop_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Audience_Stddev_Samp_Fields = {
  __typename?: 'audience_stddev_samp_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "audience" */
export type Audience_Stddev_Samp_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "audience" */
export type Audience_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audience_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audience_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  addressable_reach?: InputMaybe<Scalars['Int']>;
  bq_reach_table?: InputMaybe<Scalars['String']>;
  bq_table?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  data_source?: InputMaybe<Scalars['String']>;
  /** complies to the current AST format and the default value passed in from UI */
  definition?: InputMaybe<Scalars['jsonb']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  error_code?: InputMaybe<Scalars['String']>;
  estimated_reach?: InputMaybe<Scalars['Int']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  /** possible values: oa_match_union, passthrough, provider_edge_union applicable only if direct_audience_provider provided */
  export_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['String']>;
  last_export_id?: InputMaybe<Scalars['uuid']>;
  last_uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  normalized_definition?: InputMaybe<Scalars['jsonb']>;
  reach?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Scalars['String']>;
  report_status?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Audience_Sum_Fields = {
  __typename?: 'audience_sum_fields';
  addressable_reach?: Maybe<Scalars['Int']>;
  estimated_reach?: Maybe<Scalars['Int']>;
  reach?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "audience" */
export type Audience_Sum_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** update columns of table "audience" */
export enum Audience_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AddressableReach = 'addressable_reach',
  /** column name */
  BqReachTable = 'bq_reach_table',
  /** column name */
  BqTable = 'bq_table',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  CreatedWith = 'created_with',
  /** column name */
  DataSource = 'data_source',
  /** column name */
  Definition = 'definition',
  /** column name */
  Description = 'description',
  /** column name */
  DirectAudienceProvider = 'direct_audience_provider',
  /** column name */
  Error = 'error',
  /** column name */
  ErrorCode = 'error_code',
  /** column name */
  EstimatedReach = 'estimated_reach',
  /** column name */
  ExportTargets = 'export_targets',
  /** column name */
  ExportType = 'export_type',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  JobId = 'job_id',
  /** column name */
  LastExportId = 'last_export_id',
  /** column name */
  LastUdsTimestamp = 'last_uds_timestamp',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedDefinition = 'normalized_definition',
  /** column name */
  Reach = 'reach',
  /** column name */
  Region = 'region',
  /** column name */
  ReportStatus = 'report_status',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date',
  /** column name */
  UserId = 'user_id'
}

export type Audience_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audience_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audience_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audience_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audience_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audience_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audience_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audience_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audience_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audience_Var_Pop_Fields = {
  __typename?: 'audience_var_pop_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "audience" */
export type Audience_Var_Pop_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Audience_Var_Samp_Fields = {
  __typename?: 'audience_var_samp_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "audience" */
export type Audience_Var_Samp_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Audience_Variance_Fields = {
  __typename?: 'audience_variance_fields';
  addressable_reach?: Maybe<Scalars['Float']>;
  estimated_reach?: Maybe<Scalars['Float']>;
  reach?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "audience" */
export type Audience_Variance_Order_By = {
  addressable_reach?: InputMaybe<Order_By>;
  estimated_reach?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Describes any resource that we have involving BQ tables to indicate their location */
export type Bq_Resource = {
  __typename?: 'bq_resource';
  bq_location: Scalars['String'];
  created_date: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  partition_date: Scalars['timestamptz'];
  type: Scalars['String'];
  updated_date: Scalars['timestamptz'];
};

/** aggregated selection of "bq_resource" */
export type Bq_Resource_Aggregate = {
  __typename?: 'bq_resource_aggregate';
  aggregate?: Maybe<Bq_Resource_Aggregate_Fields>;
  nodes: Array<Bq_Resource>;
};

/** aggregate fields of "bq_resource" */
export type Bq_Resource_Aggregate_Fields = {
  __typename?: 'bq_resource_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bq_Resource_Max_Fields>;
  min?: Maybe<Bq_Resource_Min_Fields>;
};


/** aggregate fields of "bq_resource" */
export type Bq_Resource_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bq_Resource_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "bq_resource". All fields are combined with a logical 'AND'. */
export type Bq_Resource_Bool_Exp = {
  _and?: InputMaybe<Array<Bq_Resource_Bool_Exp>>;
  _not?: InputMaybe<Bq_Resource_Bool_Exp>;
  _or?: InputMaybe<Array<Bq_Resource_Bool_Exp>>;
  bq_location?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  partition_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "bq_resource" */
export enum Bq_Resource_Constraint {
  /** unique or primary key constraint on columns "name", "type" */
  BqResourceNameTypeKey = 'bq_resource_name_type_key',
  /** unique or primary key constraint on columns "id" */
  BqResourcePkey = 'bq_resource_pkey'
}

/** input type for inserting data into table "bq_resource" */
export type Bq_Resource_Insert_Input = {
  bq_location?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  partition_date?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Bq_Resource_Max_Fields = {
  __typename?: 'bq_resource_max_fields';
  bq_location?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  partition_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Bq_Resource_Min_Fields = {
  __typename?: 'bq_resource_min_fields';
  bq_location?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  partition_date?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "bq_resource" */
export type Bq_Resource_Mutation_Response = {
  __typename?: 'bq_resource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bq_Resource>;
};

/** on_conflict condition type for table "bq_resource" */
export type Bq_Resource_On_Conflict = {
  constraint: Bq_Resource_Constraint;
  update_columns?: Array<Bq_Resource_Update_Column>;
  where?: InputMaybe<Bq_Resource_Bool_Exp>;
};

/** Ordering options when selecting data from "bq_resource". */
export type Bq_Resource_Order_By = {
  bq_location?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  partition_date?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: bq_resource */
export type Bq_Resource_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bq_resource" */
export enum Bq_Resource_Select_Column {
  /** column name */
  BqLocation = 'bq_location',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PartitionDate = 'partition_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date'
}

/** input type for updating data in table "bq_resource" */
export type Bq_Resource_Set_Input = {
  bq_location?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  partition_date?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "bq_resource" */
export type Bq_Resource_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bq_Resource_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bq_Resource_Stream_Cursor_Value_Input = {
  bq_location?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  partition_date?: InputMaybe<Scalars['timestamptz']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "bq_resource" */
export enum Bq_Resource_Update_Column {
  /** column name */
  BqLocation = 'bq_location',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PartitionDate = 'partition_date',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date'
}

export type Bq_Resource_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Bq_Resource_Set_Input>;
  /** filter the rows which have to be updated */
  where: Bq_Resource_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "deal" */
export type Deal = {
  __typename?: 'deal';
  /** An object relationship */
  _package?: Maybe<Hasura_Package>;
  /** An object relationship */
  account?: Maybe<Account>;
  account_id: Scalars['uuid'];
  /** A computed field, executes function "deal_created_date" */
  created_date?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "deal_created_with" */
  created_with?: Maybe<Scalars['String']>;
  /** A computed field, executes function "deal_end_date" */
  end_date?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['jsonb']>;
  exchange_deal?: Maybe<Scalars['jsonb']>;
  /** A computed field, executes function "deal_deal_id" */
  exchange_deal_id?: Maybe<Scalars['String']>;
  exchange_deal_revision_applied?: Maybe<Scalars['Int']>;
  exchange_id?: Maybe<Scalars['String']>;
  /** The Package object as it exists in MGMT API */
  exchange_package?: Maybe<Scalars['JSON']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_revision_applied?: Maybe<Scalars['Int']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_archived?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "deal_modified_date" */
  modified_date?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "deal_name" */
  name?: Maybe<Scalars['String']>;
  /** The associated Package object. */
  package?: Maybe<Package>;
  /** A computed field, executes function "deal_start_date" */
  start_date?: Maybe<Scalars['timestamptz']>;
  status: Scalars['String'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "deal" */
export type DealErrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "deal" */
export type DealExchange_DealArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "deal" */
export type Deal_Aggregate = {
  __typename?: 'deal_aggregate';
  aggregate?: Maybe<Deal_Aggregate_Fields>;
  nodes: Array<Deal>;
};

export type Deal_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Deal_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Deal_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Deal_Aggregate_Bool_Exp_Count>;
};

export type Deal_Aggregate_Bool_Exp_Bool_And = {
  arguments: Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Deal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Deal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "deal" */
export type Deal_Aggregate_Fields = {
  __typename?: 'deal_aggregate_fields';
  avg?: Maybe<Deal_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Deal_Max_Fields>;
  min?: Maybe<Deal_Min_Fields>;
  stddev?: Maybe<Deal_Stddev_Fields>;
  stddev_pop?: Maybe<Deal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Deal_Stddev_Samp_Fields>;
  sum?: Maybe<Deal_Sum_Fields>;
  var_pop?: Maybe<Deal_Var_Pop_Fields>;
  var_samp?: Maybe<Deal_Var_Samp_Fields>;
  variance?: Maybe<Deal_Variance_Fields>;
};


/** aggregate fields of "deal" */
export type Deal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "deal" */
export type Deal_Aggregate_Order_By = {
  avg?: InputMaybe<Deal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Deal_Max_Order_By>;
  min?: InputMaybe<Deal_Min_Order_By>;
  stddev?: InputMaybe<Deal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Deal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Deal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Deal_Sum_Order_By>;
  var_pop?: InputMaybe<Deal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Deal_Var_Samp_Order_By>;
  variance?: InputMaybe<Deal_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Append_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "deal" */
export type Deal_Arr_Rel_Insert_Input = {
  data: Array<Deal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Deal_On_Conflict>;
};

/** aggregate avg on columns */
export type Deal_Avg_Fields = {
  __typename?: 'deal_avg_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "deal" */
export type Deal_Avg_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "deal". All fields are combined with a logical 'AND'. */
export type Deal_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Bool_Exp>>;
  _not?: InputMaybe<Deal_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Bool_Exp>>;
  _package?: InputMaybe<Hasura_Package_Bool_Exp>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_with?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_deal?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_deal_id?: InputMaybe<String_Comparison_Exp>;
  exchange_deal_revision_applied?: InputMaybe<Int_Comparison_Exp>;
  exchange_id?: InputMaybe<String_Comparison_Exp>;
  exchange_package_id?: InputMaybe<String_Comparison_Exp>;
  exchange_package_revision_applied?: InputMaybe<Int_Comparison_Exp>;
  exchange_package_uid?: InputMaybe<String_Comparison_Exp>;
  exchange_uid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "deal" */
export enum Deal_Constraint {
  /** unique or primary key constraint on columns "id" */
  DealPkey = 'deal_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Delete_At_Path_Input = {
  error?: InputMaybe<Array<Scalars['String']>>;
  exchange_deal?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Delete_Elem_Input = {
  error?: InputMaybe<Scalars['Int']>;
  exchange_deal?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Delete_Key_Input = {
  error?: InputMaybe<Scalars['String']>;
  exchange_deal?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "deal" */
export type Deal_Inc_Input = {
  exchange_deal_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_package_revision_applied?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "deal" */
export type Deal_Insert_Input = {
  _package?: InputMaybe<Hasura_Package_Obj_Rel_Insert_Input>;
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deal_Max_Fields = {
  __typename?: 'deal_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  exchange_deal_revision_applied?: Maybe<Scalars['Int']>;
  exchange_id?: Maybe<Scalars['String']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_revision_applied?: Maybe<Scalars['Int']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "deal" */
export type Deal_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  exchange_package_id?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
  exchange_package_uid?: InputMaybe<Order_By>;
  exchange_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Deal_Min_Fields = {
  __typename?: 'deal_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  exchange_deal_revision_applied?: Maybe<Scalars['Int']>;
  exchange_id?: Maybe<Scalars['String']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_revision_applied?: Maybe<Scalars['Int']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "deal" */
export type Deal_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  exchange_package_id?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
  exchange_package_uid?: InputMaybe<Order_By>;
  exchange_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "deal" */
export type Deal_Mutation_Response = {
  __typename?: 'deal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal>;
};

/** on_conflict condition type for table "deal" */
export type Deal_On_Conflict = {
  constraint: Deal_Constraint;
  update_columns?: Array<Deal_Update_Column>;
  where?: InputMaybe<Deal_Bool_Exp>;
};

/** Ordering options when selecting data from "deal". */
export type Deal_Order_By = {
  _package?: InputMaybe<Hasura_Package_Order_By>;
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  created_with?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  exchange_deal?: InputMaybe<Order_By>;
  exchange_deal_id?: InputMaybe<Order_By>;
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  exchange_package_id?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
  exchange_package_uid?: InputMaybe<Order_By>;
  exchange_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_archived?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "deal_package" */
export type Deal_Package = {
  __typename?: 'deal_package';
  /** An object relationship */
  _package?: Maybe<Hasura_Package>;
  /** An object relationship */
  account?: Maybe<Account>;
  account_id?: Maybe<Scalars['uuid']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  created_with?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['jsonb']>;
  exchange_deal?: Maybe<Scalars['jsonb']>;
  exchange_deal_id?: Maybe<Scalars['String']>;
  exchange_id?: Maybe<Scalars['String']>;
  /** The Package object as it exists in MGMT API */
  exchange_package?: Maybe<Scalars['JSON']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  modified_date?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  /** The associated Package object. */
  package?: Maybe<Package>;
  start_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "deal_package" */
export type Deal_PackageErrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "deal_package" */
export type Deal_PackageExchange_DealArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "deal_package" */
export type Deal_Package_Aggregate = {
  __typename?: 'deal_package_aggregate';
  aggregate?: Maybe<Deal_Package_Aggregate_Fields>;
  nodes: Array<Deal_Package>;
};

/** aggregate fields of "deal_package" */
export type Deal_Package_Aggregate_Fields = {
  __typename?: 'deal_package_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Deal_Package_Max_Fields>;
  min?: Maybe<Deal_Package_Min_Fields>;
};


/** aggregate fields of "deal_package" */
export type Deal_Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Deal_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Package_Append_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "deal_package". All fields are combined with a logical 'AND'. */
export type Deal_Package_Bool_Exp = {
  _and?: InputMaybe<Array<Deal_Package_Bool_Exp>>;
  _not?: InputMaybe<Deal_Package_Bool_Exp>;
  _or?: InputMaybe<Array<Deal_Package_Bool_Exp>>;
  _package?: InputMaybe<Hasura_Package_Bool_Exp>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_with?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_deal?: InputMaybe<Jsonb_Comparison_Exp>;
  exchange_deal_id?: InputMaybe<String_Comparison_Exp>;
  exchange_id?: InputMaybe<String_Comparison_Exp>;
  exchange_package_id?: InputMaybe<String_Comparison_Exp>;
  exchange_package_uid?: InputMaybe<String_Comparison_Exp>;
  exchange_uid?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  modified_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Package_Delete_At_Path_Input = {
  error?: InputMaybe<Array<Scalars['String']>>;
  exchange_deal?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Package_Delete_Elem_Input = {
  error?: InputMaybe<Scalars['Int']>;
  exchange_deal?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Package_Delete_Key_Input = {
  error?: InputMaybe<Scalars['String']>;
  exchange_deal?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "deal_package" */
export type Deal_Package_Insert_Input = {
  _package?: InputMaybe<Hasura_Package_Obj_Rel_Insert_Input>;
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_id?: InputMaybe<Scalars['String']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  modified_date?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Deal_Package_Max_Fields = {
  __typename?: 'deal_package_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  created_with?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  exchange_deal_id?: Maybe<Scalars['String']>;
  exchange_id?: Maybe<Scalars['String']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  modified_date?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Deal_Package_Min_Fields = {
  __typename?: 'deal_package_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  created_with?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  exchange_deal_id?: Maybe<Scalars['String']>;
  exchange_id?: Maybe<Scalars['String']>;
  exchange_package_id?: Maybe<Scalars['String']>;
  exchange_package_uid?: Maybe<Scalars['String']>;
  exchange_uid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  modified_date?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "deal_package" */
export type Deal_Package_Mutation_Response = {
  __typename?: 'deal_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Package>;
};

/** Ordering options when selecting data from "deal_package". */
export type Deal_Package_Order_By = {
  _package?: InputMaybe<Hasura_Package_Order_By>;
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  created_with?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  exchange_deal?: InputMaybe<Order_By>;
  exchange_deal_id?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  exchange_package_id?: InputMaybe<Order_By>;
  exchange_package_uid?: InputMaybe<Order_By>;
  exchange_uid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_archived?: InputMaybe<Order_By>;
  modified_date?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Package_Prepend_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "deal_package" */
export enum Deal_Package_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  CreatedWith = 'created_with',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Error = 'error',
  /** column name */
  ExchangeDeal = 'exchange_deal',
  /** column name */
  ExchangeDealId = 'exchange_deal_id',
  /** column name */
  ExchangeId = 'exchange_id',
  /** column name */
  ExchangePackageId = 'exchange_package_id',
  /** column name */
  ExchangePackageUid = 'exchange_package_uid',
  /** column name */
  ExchangeUid = 'exchange_uid',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  Name = 'name',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "deal_package" */
export type Deal_Package_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_id?: InputMaybe<Scalars['String']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  modified_date?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "deal_package" */
export type Deal_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Package_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  created_with?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_id?: InputMaybe<Scalars['String']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  modified_date?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

export type Deal_Package_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Deal_Package_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Deal_Package_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Deal_Package_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Deal_Package_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Deal_Package_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Package_Bool_Exp;
};

/** primary key columns input for table: deal */
export type Deal_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Prepend_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "deal" */
export enum Deal_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Error = 'error',
  /** column name */
  ExchangeDeal = 'exchange_deal',
  /** column name */
  ExchangeDealRevisionApplied = 'exchange_deal_revision_applied',
  /** column name */
  ExchangeId = 'exchange_id',
  /** column name */
  ExchangePackageId = 'exchange_package_id',
  /** column name */
  ExchangePackageRevisionApplied = 'exchange_package_revision_applied',
  /** column name */
  ExchangePackageUid = 'exchange_package_uid',
  /** column name */
  ExchangeUid = 'exchange_uid',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** select "deal_aggregate_bool_exp_bool_and_arguments_columns" columns of table "deal" */
export enum Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived'
}

/** select "deal_aggregate_bool_exp_bool_or_arguments_columns" columns of table "deal" */
export enum Deal_Select_Column_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived'
}

/** input type for updating data in table "deal" */
export type Deal_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Deal_Stddev_Fields = {
  __typename?: 'deal_stddev_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "deal" */
export type Deal_Stddev_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Deal_Stddev_Pop_Fields = {
  __typename?: 'deal_stddev_pop_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "deal" */
export type Deal_Stddev_Pop_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Deal_Stddev_Samp_Fields = {
  __typename?: 'deal_stddev_samp_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "deal" */
export type Deal_Stddev_Samp_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "deal" */
export type Deal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deal_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  error?: InputMaybe<Scalars['jsonb']>;
  exchange_deal?: InputMaybe<Scalars['jsonb']>;
  exchange_deal_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_id?: InputMaybe<Scalars['String']>;
  exchange_package_id?: InputMaybe<Scalars['String']>;
  exchange_package_revision_applied?: InputMaybe<Scalars['Int']>;
  exchange_package_uid?: InputMaybe<Scalars['String']>;
  exchange_uid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Deal_Sum_Fields = {
  __typename?: 'deal_sum_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Int']>;
  exchange_package_revision_applied?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "deal" */
export type Deal_Sum_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** update columns of table "deal" */
export enum Deal_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Error = 'error',
  /** column name */
  ExchangeDeal = 'exchange_deal',
  /** column name */
  ExchangeDealRevisionApplied = 'exchange_deal_revision_applied',
  /** column name */
  ExchangeId = 'exchange_id',
  /** column name */
  ExchangePackageId = 'exchange_package_id',
  /** column name */
  ExchangePackageRevisionApplied = 'exchange_package_revision_applied',
  /** column name */
  ExchangePackageUid = 'exchange_package_uid',
  /** column name */
  ExchangeUid = 'exchange_uid',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

export type Deal_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Deal_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Deal_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Deal_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Deal_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Deal_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Deal_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Deal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Deal_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Deal_Var_Pop_Fields = {
  __typename?: 'deal_var_pop_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "deal" */
export type Deal_Var_Pop_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Deal_Var_Samp_Fields = {
  __typename?: 'deal_var_samp_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "deal" */
export type Deal_Var_Samp_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Deal_Variance_Fields = {
  __typename?: 'deal_variance_fields';
  exchange_deal_revision_applied?: Maybe<Scalars['Float']>;
  exchange_package_revision_applied?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "deal" */
export type Deal_Variance_Order_By = {
  exchange_deal_revision_applied?: InputMaybe<Order_By>;
  exchange_package_revision_applied?: InputMaybe<Order_By>;
};

/** Describes the export of an audience to other customers */
export type Export = {
  __typename?: 'export';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  account_name?: Maybe<Scalars['String']>;
  account_resource_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  audience: Audience;
  audience_id: Scalars['uuid'];
  bq_job_id?: Maybe<Scalars['String']>;
  bq_resource_id?: Maybe<Scalars['uuid']>;
  bq_table: Scalars['String'];
  bucket: Scalars['String'];
  created_date: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  direct_audience_provider?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['jsonb']>;
  export_id_types: Scalars['jsonb'];
  /** An array relationship */
  export_status_timelines: Array<Export_Status_Timeline>;
  /** An aggregate relationship */
  export_status_timelines_aggregate: Export_Status_Timeline_Aggregate;
  export_targets?: Maybe<Scalars['jsonb']>;
  export_type?: Maybe<Scalars['String']>;
  file_path: Scalars['String'];
  gcs_destination_uri_prefix?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  info?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  oauid_bq_table?: Maybe<Scalars['jsonb']>;
  source_bq_table?: Maybe<Scalars['jsonb']>;
  status: Scalars['String'];
  uds_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_date: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportErrorArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportExport_Id_TypesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportExport_Status_TimelinesArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


/** Describes the export of an audience to other customers */
export type ExportExport_Status_Timelines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Export_Status_Timeline_Order_By>>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};


/** Describes the export of an audience to other customers */
export type ExportExport_TargetsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportInfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportOauid_Bq_TableArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Describes the export of an audience to other customers */
export type ExportSource_Bq_TableArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "export" */
export type Export_Aggregate = {
  __typename?: 'export_aggregate';
  aggregate?: Maybe<Export_Aggregate_Fields>;
  nodes: Array<Export>;
};

export type Export_Aggregate_Bool_Exp = {
  count?: InputMaybe<Export_Aggregate_Bool_Exp_Count>;
};

export type Export_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Export_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Export_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "export" */
export type Export_Aggregate_Fields = {
  __typename?: 'export_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Export_Max_Fields>;
  min?: Maybe<Export_Min_Fields>;
};


/** aggregate fields of "export" */
export type Export_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Export_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "export" */
export type Export_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Export_Max_Order_By>;
  min?: InputMaybe<Export_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Export_Append_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  export_id_types?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  info?: InputMaybe<Scalars['jsonb']>;
  oauid_bq_table?: InputMaybe<Scalars['jsonb']>;
  source_bq_table?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "export" */
export type Export_Arr_Rel_Insert_Input = {
  data: Array<Export_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Export_On_Conflict>;
};

export type Export_Audience_Config_Input = {
  export_id_types?: InputMaybe<Scalars['JSON']>;
  file_path?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "export". All fields are combined with a logical 'AND'. */
export type Export_Bool_Exp = {
  _and?: InputMaybe<Array<Export_Bool_Exp>>;
  _not?: InputMaybe<Export_Bool_Exp>;
  _or?: InputMaybe<Array<Export_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  account_name?: InputMaybe<String_Comparison_Exp>;
  account_resource_id?: InputMaybe<String_Comparison_Exp>;
  audience?: InputMaybe<Audience_Bool_Exp>;
  audience_id?: InputMaybe<Uuid_Comparison_Exp>;
  bq_job_id?: InputMaybe<String_Comparison_Exp>;
  bq_resource_id?: InputMaybe<Uuid_Comparison_Exp>;
  bq_table?: InputMaybe<String_Comparison_Exp>;
  bucket?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  direct_audience_provider?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<Jsonb_Comparison_Exp>;
  export_id_types?: InputMaybe<Jsonb_Comparison_Exp>;
  export_status_timelines?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
  export_status_timelines_aggregate?: InputMaybe<Export_Status_Timeline_Aggregate_Bool_Exp>;
  export_targets?: InputMaybe<Jsonb_Comparison_Exp>;
  export_type?: InputMaybe<String_Comparison_Exp>;
  file_path?: InputMaybe<String_Comparison_Exp>;
  gcs_destination_uri_prefix?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oauid_bq_table?: InputMaybe<Jsonb_Comparison_Exp>;
  source_bq_table?: InputMaybe<Jsonb_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  uds_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

export type Export_Bulk_Audience_Config_Input = {
  audienceId?: InputMaybe<Scalars['String']>;
  export_id_types?: InputMaybe<Scalars['JSON']>;
  file_path?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

/** unique or primary key constraints on table "export" */
export enum Export_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExportPkey = 'export_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Export_Delete_At_Path_Input = {
  error?: InputMaybe<Array<Scalars['String']>>;
  export_id_types?: InputMaybe<Array<Scalars['String']>>;
  export_targets?: InputMaybe<Array<Scalars['String']>>;
  info?: InputMaybe<Array<Scalars['String']>>;
  oauid_bq_table?: InputMaybe<Array<Scalars['String']>>;
  source_bq_table?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Export_Delete_Elem_Input = {
  error?: InputMaybe<Scalars['Int']>;
  export_id_types?: InputMaybe<Scalars['Int']>;
  export_targets?: InputMaybe<Scalars['Int']>;
  info?: InputMaybe<Scalars['Int']>;
  oauid_bq_table?: InputMaybe<Scalars['Int']>;
  source_bq_table?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Export_Delete_Key_Input = {
  error?: InputMaybe<Scalars['String']>;
  export_id_types?: InputMaybe<Scalars['String']>;
  export_targets?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  oauid_bq_table?: InputMaybe<Scalars['String']>;
  source_bq_table?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "export" */
export type Export_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  account_name?: InputMaybe<Scalars['String']>;
  account_resource_id?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  bq_job_id?: InputMaybe<Scalars['String']>;
  bq_resource_id?: InputMaybe<Scalars['uuid']>;
  bq_table?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  export_id_types?: InputMaybe<Scalars['jsonb']>;
  export_status_timelines?: InputMaybe<Export_Status_Timeline_Arr_Rel_Insert_Input>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  export_type?: InputMaybe<Scalars['String']>;
  file_path?: InputMaybe<Scalars['String']>;
  gcs_destination_uri_prefix?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oauid_bq_table?: InputMaybe<Scalars['jsonb']>;
  source_bq_table?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Export_Max_Fields = {
  __typename?: 'export_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  account_resource_id?: Maybe<Scalars['String']>;
  audience_id?: Maybe<Scalars['uuid']>;
  bq_job_id?: Maybe<Scalars['String']>;
  bq_resource_id?: Maybe<Scalars['uuid']>;
  bq_table?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  direct_audience_provider?: Maybe<Scalars['String']>;
  export_type?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  gcs_destination_uri_prefix?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uds_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "export" */
export type Export_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  account_name?: InputMaybe<Order_By>;
  account_resource_id?: InputMaybe<Order_By>;
  audience_id?: InputMaybe<Order_By>;
  bq_job_id?: InputMaybe<Order_By>;
  bq_resource_id?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  export_type?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  gcs_destination_uri_prefix?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uds_timestamp?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Export_Min_Fields = {
  __typename?: 'export_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  account_name?: Maybe<Scalars['String']>;
  account_resource_id?: Maybe<Scalars['String']>;
  audience_id?: Maybe<Scalars['uuid']>;
  bq_job_id?: Maybe<Scalars['String']>;
  bq_resource_id?: Maybe<Scalars['uuid']>;
  bq_table?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  direct_audience_provider?: Maybe<Scalars['String']>;
  export_type?: Maybe<Scalars['String']>;
  file_path?: Maybe<Scalars['String']>;
  gcs_destination_uri_prefix?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uds_timestamp?: Maybe<Scalars['timestamptz']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "export" */
export type Export_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  account_name?: InputMaybe<Order_By>;
  account_resource_id?: InputMaybe<Order_By>;
  audience_id?: InputMaybe<Order_By>;
  bq_job_id?: InputMaybe<Order_By>;
  bq_resource_id?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  export_type?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  gcs_destination_uri_prefix?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uds_timestamp?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "export" */
export type Export_Mutation_Response = {
  __typename?: 'export_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Export>;
};

/** input type for inserting object relation for remote table "export" */
export type Export_Obj_Rel_Insert_Input = {
  data: Export_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Export_On_Conflict>;
};

/** on_conflict condition type for table "export" */
export type Export_On_Conflict = {
  constraint: Export_Constraint;
  update_columns?: Array<Export_Update_Column>;
  where?: InputMaybe<Export_Bool_Exp>;
};

/** Ordering options when selecting data from "export". */
export type Export_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  account_name?: InputMaybe<Order_By>;
  account_resource_id?: InputMaybe<Order_By>;
  audience?: InputMaybe<Audience_Order_By>;
  audience_id?: InputMaybe<Order_By>;
  bq_job_id?: InputMaybe<Order_By>;
  bq_resource_id?: InputMaybe<Order_By>;
  bq_table?: InputMaybe<Order_By>;
  bucket?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  direct_audience_provider?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  export_id_types?: InputMaybe<Order_By>;
  export_status_timelines_aggregate?: InputMaybe<Export_Status_Timeline_Aggregate_Order_By>;
  export_targets?: InputMaybe<Order_By>;
  export_type?: InputMaybe<Order_By>;
  file_path?: InputMaybe<Order_By>;
  gcs_destination_uri_prefix?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  info?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oauid_bq_table?: InputMaybe<Order_By>;
  source_bq_table?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  uds_timestamp?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: export */
export type Export_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Export_Prepend_Input = {
  error?: InputMaybe<Scalars['jsonb']>;
  export_id_types?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  info?: InputMaybe<Scalars['jsonb']>;
  oauid_bq_table?: InputMaybe<Scalars['jsonb']>;
  source_bq_table?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "export" */
export enum Export_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountResourceId = 'account_resource_id',
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  BqJobId = 'bq_job_id',
  /** column name */
  BqResourceId = 'bq_resource_id',
  /** column name */
  BqTable = 'bq_table',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  DirectAudienceProvider = 'direct_audience_provider',
  /** column name */
  Error = 'error',
  /** column name */
  ExportIdTypes = 'export_id_types',
  /** column name */
  ExportTargets = 'export_targets',
  /** column name */
  ExportType = 'export_type',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  GcsDestinationUriPrefix = 'gcs_destination_uri_prefix',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  OauidBqTable = 'oauid_bq_table',
  /** column name */
  SourceBqTable = 'source_bq_table',
  /** column name */
  Status = 'status',
  /** column name */
  UdsTimestamp = 'uds_timestamp',
  /** column name */
  UpdatedDate = 'updated_date',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "export" */
export type Export_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  account_name?: InputMaybe<Scalars['String']>;
  account_resource_id?: InputMaybe<Scalars['String']>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  bq_job_id?: InputMaybe<Scalars['String']>;
  bq_resource_id?: InputMaybe<Scalars['uuid']>;
  bq_table?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  export_id_types?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  export_type?: InputMaybe<Scalars['String']>;
  file_path?: InputMaybe<Scalars['String']>;
  gcs_destination_uri_prefix?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oauid_bq_table?: InputMaybe<Scalars['jsonb']>;
  source_bq_table?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "export_status_timeline" */
export type Export_Status_Timeline = {
  __typename?: 'export_status_timeline';
  /** An object relationship */
  export: Export;
  export_id: Scalars['uuid'];
  export_status: Scalars['String'];
  export_timestamp: Scalars['timestamptz'];
  id: Scalars['uuid'];
};

/** aggregated selection of "export_status_timeline" */
export type Export_Status_Timeline_Aggregate = {
  __typename?: 'export_status_timeline_aggregate';
  aggregate?: Maybe<Export_Status_Timeline_Aggregate_Fields>;
  nodes: Array<Export_Status_Timeline>;
};

export type Export_Status_Timeline_Aggregate_Bool_Exp = {
  count?: InputMaybe<Export_Status_Timeline_Aggregate_Bool_Exp_Count>;
};

export type Export_Status_Timeline_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "export_status_timeline" */
export type Export_Status_Timeline_Aggregate_Fields = {
  __typename?: 'export_status_timeline_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Export_Status_Timeline_Max_Fields>;
  min?: Maybe<Export_Status_Timeline_Min_Fields>;
};


/** aggregate fields of "export_status_timeline" */
export type Export_Status_Timeline_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Export_Status_Timeline_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "export_status_timeline" */
export type Export_Status_Timeline_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Export_Status_Timeline_Max_Order_By>;
  min?: InputMaybe<Export_Status_Timeline_Min_Order_By>;
};

/** input type for inserting array relation for remote table "export_status_timeline" */
export type Export_Status_Timeline_Arr_Rel_Insert_Input = {
  data: Array<Export_Status_Timeline_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Export_Status_Timeline_On_Conflict>;
};

/** Boolean expression to filter rows from the table "export_status_timeline". All fields are combined with a logical 'AND'. */
export type Export_Status_Timeline_Bool_Exp = {
  _and?: InputMaybe<Array<Export_Status_Timeline_Bool_Exp>>;
  _not?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
  _or?: InputMaybe<Array<Export_Status_Timeline_Bool_Exp>>;
  export?: InputMaybe<Export_Bool_Exp>;
  export_id?: InputMaybe<Uuid_Comparison_Exp>;
  export_status?: InputMaybe<String_Comparison_Exp>;
  export_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "export_status_timeline" */
export enum Export_Status_Timeline_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExportStatusTimelinePkey = 'export_status_timeline_pkey'
}

/** input type for inserting data into table "export_status_timeline" */
export type Export_Status_Timeline_Insert_Input = {
  export?: InputMaybe<Export_Obj_Rel_Insert_Input>;
  export_id?: InputMaybe<Scalars['uuid']>;
  export_status?: InputMaybe<Scalars['String']>;
  export_timestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Export_Status_Timeline_Max_Fields = {
  __typename?: 'export_status_timeline_max_fields';
  export_id?: Maybe<Scalars['uuid']>;
  export_status?: Maybe<Scalars['String']>;
  export_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "export_status_timeline" */
export type Export_Status_Timeline_Max_Order_By = {
  export_id?: InputMaybe<Order_By>;
  export_status?: InputMaybe<Order_By>;
  export_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Export_Status_Timeline_Min_Fields = {
  __typename?: 'export_status_timeline_min_fields';
  export_id?: Maybe<Scalars['uuid']>;
  export_status?: Maybe<Scalars['String']>;
  export_timestamp?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "export_status_timeline" */
export type Export_Status_Timeline_Min_Order_By = {
  export_id?: InputMaybe<Order_By>;
  export_status?: InputMaybe<Order_By>;
  export_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "export_status_timeline" */
export type Export_Status_Timeline_Mutation_Response = {
  __typename?: 'export_status_timeline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Export_Status_Timeline>;
};

/** on_conflict condition type for table "export_status_timeline" */
export type Export_Status_Timeline_On_Conflict = {
  constraint: Export_Status_Timeline_Constraint;
  update_columns?: Array<Export_Status_Timeline_Update_Column>;
  where?: InputMaybe<Export_Status_Timeline_Bool_Exp>;
};

/** Ordering options when selecting data from "export_status_timeline". */
export type Export_Status_Timeline_Order_By = {
  export?: InputMaybe<Export_Order_By>;
  export_id?: InputMaybe<Order_By>;
  export_status?: InputMaybe<Order_By>;
  export_timestamp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: export_status_timeline */
export type Export_Status_Timeline_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "export_status_timeline" */
export enum Export_Status_Timeline_Select_Column {
  /** column name */
  ExportId = 'export_id',
  /** column name */
  ExportStatus = 'export_status',
  /** column name */
  ExportTimestamp = 'export_timestamp',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "export_status_timeline" */
export type Export_Status_Timeline_Set_Input = {
  export_id?: InputMaybe<Scalars['uuid']>;
  export_status?: InputMaybe<Scalars['String']>;
  export_timestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "export_status_timeline" */
export type Export_Status_Timeline_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Export_Status_Timeline_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Export_Status_Timeline_Stream_Cursor_Value_Input = {
  export_id?: InputMaybe<Scalars['uuid']>;
  export_status?: InputMaybe<Scalars['String']>;
  export_timestamp?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "export_status_timeline" */
export enum Export_Status_Timeline_Update_Column {
  /** column name */
  ExportId = 'export_id',
  /** column name */
  ExportStatus = 'export_status',
  /** column name */
  ExportTimestamp = 'export_timestamp',
  /** column name */
  Id = 'id'
}

export type Export_Status_Timeline_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Export_Status_Timeline_Set_Input>;
  /** filter the rows which have to be updated */
  where: Export_Status_Timeline_Bool_Exp;
};

/** Streaming cursor of the table "export" */
export type Export_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Export_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Export_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  account_name?: InputMaybe<Scalars['String']>;
  account_resource_id?: InputMaybe<Scalars['String']>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  bq_job_id?: InputMaybe<Scalars['String']>;
  bq_resource_id?: InputMaybe<Scalars['uuid']>;
  bq_table?: InputMaybe<Scalars['String']>;
  bucket?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  direct_audience_provider?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['jsonb']>;
  export_id_types?: InputMaybe<Scalars['jsonb']>;
  export_targets?: InputMaybe<Scalars['jsonb']>;
  export_type?: InputMaybe<Scalars['String']>;
  file_path?: InputMaybe<Scalars['String']>;
  gcs_destination_uri_prefix?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  info?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oauid_bq_table?: InputMaybe<Scalars['jsonb']>;
  source_bq_table?: InputMaybe<Scalars['jsonb']>;
  status?: InputMaybe<Scalars['String']>;
  uds_timestamp?: InputMaybe<Scalars['timestamptz']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "export" */
export enum Export_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountResourceId = 'account_resource_id',
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  BqJobId = 'bq_job_id',
  /** column name */
  BqResourceId = 'bq_resource_id',
  /** column name */
  BqTable = 'bq_table',
  /** column name */
  Bucket = 'bucket',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  DirectAudienceProvider = 'direct_audience_provider',
  /** column name */
  Error = 'error',
  /** column name */
  ExportIdTypes = 'export_id_types',
  /** column name */
  ExportTargets = 'export_targets',
  /** column name */
  ExportType = 'export_type',
  /** column name */
  FilePath = 'file_path',
  /** column name */
  GcsDestinationUriPrefix = 'gcs_destination_uri_prefix',
  /** column name */
  Id = 'id',
  /** column name */
  Info = 'info',
  /** column name */
  Name = 'name',
  /** column name */
  OauidBqTable = 'oauid_bq_table',
  /** column name */
  SourceBqTable = 'source_bq_table',
  /** column name */
  Status = 'status',
  /** column name */
  UdsTimestamp = 'uds_timestamp',
  /** column name */
  UpdatedDate = 'updated_date',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

export type Export_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Export_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Export_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Export_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Export_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Export_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Export_Set_Input>;
  /** filter the rows which have to be updated */
  where: Export_Bool_Exp;
};

/** columns and relationships of "feature_flags" */
export type Feature_Flags = {
  __typename?: 'feature_flags';
  description: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "feature_flags" */
export type Feature_Flags_Aggregate = {
  __typename?: 'feature_flags_aggregate';
  aggregate?: Maybe<Feature_Flags_Aggregate_Fields>;
  nodes: Array<Feature_Flags>;
};

/** aggregate fields of "feature_flags" */
export type Feature_Flags_Aggregate_Fields = {
  __typename?: 'feature_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Feature_Flags_Max_Fields>;
  min?: Maybe<Feature_Flags_Min_Fields>;
};


/** aggregate fields of "feature_flags" */
export type Feature_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feature_flags". All fields are combined with a logical 'AND'. */
export type Feature_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Flags_Bool_Exp>>;
  _not?: InputMaybe<Feature_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Flags_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature_flags" */
export enum Feature_Flags_Constraint {
  /** unique or primary key constraint on columns "value" */
  FeatureFlagsPkey = 'feature_flags_pkey'
}

export enum Feature_Flags_Enum {
  /** Fee splitting functionality for partner */
  FeeSplittingPartner = 'FEE_SPLITTING_PARTNER'
}

/** Boolean expression to compare columns of type "feature_flags_enum". All fields are combined with logical 'AND'. */
export type Feature_Flags_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Feature_Flags_Enum>;
  _in?: InputMaybe<Array<Feature_Flags_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Feature_Flags_Enum>;
  _nin?: InputMaybe<Array<Feature_Flags_Enum>>;
};

/** input type for inserting data into table "feature_flags" */
export type Feature_Flags_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feature_Flags_Max_Fields = {
  __typename?: 'feature_flags_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feature_Flags_Min_Fields = {
  __typename?: 'feature_flags_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feature_flags" */
export type Feature_Flags_Mutation_Response = {
  __typename?: 'feature_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Flags>;
};

/** on_conflict condition type for table "feature_flags" */
export type Feature_Flags_On_Conflict = {
  constraint: Feature_Flags_Constraint;
  update_columns?: Array<Feature_Flags_Update_Column>;
  where?: InputMaybe<Feature_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_flags". */
export type Feature_Flags_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature_flags */
export type Feature_Flags_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "feature_flags" */
export enum Feature_Flags_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "feature_flags" */
export type Feature_Flags_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "feature_flags" */
export type Feature_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Flags_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "feature_flags" */
export enum Feature_Flags_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

export type Feature_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Flags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Flags_Bool_Exp;
};

/** Cache of package objects from MGMT DB */
export type Hasura_Package = {
  __typename?: 'hasura_package';
  /** An object relationship */
  audience?: Maybe<Audience>;
  audience_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "hasura_package_discounted_cpm" */
  discounted_cpm?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "exchange_account_network_id" */
  exchange_account_network_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** A computed field, executes function "hasura_package_name" */
  name?: Maybe<Scalars['String']>;
  obj: Scalars['jsonb'];
  /** A computed field, executes function "hasura_package_rate_card_cpm" */
  rate_card_cpm?: Maybe<Scalars['numeric']>;
  revision: Scalars['Int'];
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** Cache of package objects from MGMT DB */
export type Hasura_PackageObjArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_package" */
export type Hasura_Package_Aggregate = {
  __typename?: 'hasura_package_aggregate';
  aggregate?: Maybe<Hasura_Package_Aggregate_Fields>;
  nodes: Array<Hasura_Package>;
};

/** aggregate fields of "hasura_package" */
export type Hasura_Package_Aggregate_Fields = {
  __typename?: 'hasura_package_aggregate_fields';
  avg?: Maybe<Hasura_Package_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hasura_Package_Max_Fields>;
  min?: Maybe<Hasura_Package_Min_Fields>;
  stddev?: Maybe<Hasura_Package_Stddev_Fields>;
  stddev_pop?: Maybe<Hasura_Package_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hasura_Package_Stddev_Samp_Fields>;
  sum?: Maybe<Hasura_Package_Sum_Fields>;
  var_pop?: Maybe<Hasura_Package_Var_Pop_Fields>;
  var_samp?: Maybe<Hasura_Package_Var_Samp_Fields>;
  variance?: Maybe<Hasura_Package_Variance_Fields>;
};


/** aggregate fields of "hasura_package" */
export type Hasura_Package_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Package_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Package_Append_Input = {
  obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hasura_Package_Avg_Fields = {
  __typename?: 'hasura_package_avg_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hasura_package". All fields are combined with a logical 'AND'. */
export type Hasura_Package_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Package_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Package_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Package_Bool_Exp>>;
  audience?: InputMaybe<Audience_Bool_Exp>;
  audience_id?: InputMaybe<Uuid_Comparison_Exp>;
  discounted_cpm?: InputMaybe<Numeric_Comparison_Exp>;
  exchange_account_network_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  obj?: InputMaybe<Jsonb_Comparison_Exp>;
  rate_card_cpm?: InputMaybe<Numeric_Comparison_Exp>;
  revision?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hasura_package" */
export enum Hasura_Package_Constraint {
  /** unique or primary key constraint on columns "id" */
  PackagePkey = 'package_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Package_Delete_At_Path_Input = {
  obj?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Package_Delete_Elem_Input = {
  obj?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Package_Delete_Key_Input = {
  obj?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hasura_package" */
export type Hasura_Package_Inc_Input = {
  revision?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hasura_package" */
export type Hasura_Package_Insert_Input = {
  audience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  revision?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "hasura_package_list" */
export type Hasura_Package_List = {
  __typename?: 'hasura_package_list';
  /** An object relationship */
  audience?: Maybe<Audience>;
  audience_id?: Maybe<Scalars['uuid']>;
  discounted_cpm?: Maybe<Scalars['numeric']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  obj?: Maybe<Scalars['jsonb']>;
  rate_card_cpm?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "hasura_package_list" */
export type Hasura_Package_ListObjArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_package_list" */
export type Hasura_Package_List_Aggregate = {
  __typename?: 'hasura_package_list_aggregate';
  aggregate?: Maybe<Hasura_Package_List_Aggregate_Fields>;
  nodes: Array<Hasura_Package_List>;
};

/** aggregate fields of "hasura_package_list" */
export type Hasura_Package_List_Aggregate_Fields = {
  __typename?: 'hasura_package_list_aggregate_fields';
  avg?: Maybe<Hasura_Package_List_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hasura_Package_List_Max_Fields>;
  min?: Maybe<Hasura_Package_List_Min_Fields>;
  stddev?: Maybe<Hasura_Package_List_Stddev_Fields>;
  stddev_pop?: Maybe<Hasura_Package_List_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hasura_Package_List_Stddev_Samp_Fields>;
  sum?: Maybe<Hasura_Package_List_Sum_Fields>;
  var_pop?: Maybe<Hasura_Package_List_Var_Pop_Fields>;
  var_samp?: Maybe<Hasura_Package_List_Var_Samp_Fields>;
  variance?: Maybe<Hasura_Package_List_Variance_Fields>;
};


/** aggregate fields of "hasura_package_list" */
export type Hasura_Package_List_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Package_List_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Package_List_Append_Input = {
  obj?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hasura_Package_List_Avg_Fields = {
  __typename?: 'hasura_package_list_avg_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hasura_package_list". All fields are combined with a logical 'AND'. */
export type Hasura_Package_List_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Package_List_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Package_List_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Package_List_Bool_Exp>>;
  audience?: InputMaybe<Audience_Bool_Exp>;
  audience_id?: InputMaybe<Uuid_Comparison_Exp>;
  discounted_cpm?: InputMaybe<Numeric_Comparison_Exp>;
  exchange_account_network_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  obj?: InputMaybe<Jsonb_Comparison_Exp>;
  rate_card_cpm?: InputMaybe<Numeric_Comparison_Exp>;
  user?: InputMaybe<User_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Package_List_Delete_At_Path_Input = {
  obj?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Package_List_Delete_Elem_Input = {
  obj?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Package_List_Delete_Key_Input = {
  obj?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hasura_package_list" */
export type Hasura_Package_List_Inc_Input = {
  discounted_cpm?: InputMaybe<Scalars['numeric']>;
  rate_card_cpm?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "hasura_package_list" */
export type Hasura_Package_List_Insert_Input = {
  audience?: InputMaybe<Audience_Obj_Rel_Insert_Input>;
  audience_id?: InputMaybe<Scalars['uuid']>;
  discounted_cpm?: InputMaybe<Scalars['numeric']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  rate_card_cpm?: InputMaybe<Scalars['numeric']>;
  user?: InputMaybe<User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hasura_Package_List_Max_Fields = {
  __typename?: 'hasura_package_list_max_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  discounted_cpm?: Maybe<Scalars['numeric']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate_card_cpm?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hasura_Package_List_Min_Fields = {
  __typename?: 'hasura_package_list_min_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  discounted_cpm?: Maybe<Scalars['numeric']>;
  exchange_account_network_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rate_card_cpm?: Maybe<Scalars['numeric']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hasura_package_list" */
export type Hasura_Package_List_Mutation_Response = {
  __typename?: 'hasura_package_list_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Package_List>;
};

/** Ordering options when selecting data from "hasura_package_list". */
export type Hasura_Package_List_Order_By = {
  audience?: InputMaybe<Audience_Order_By>;
  audience_id?: InputMaybe<Order_By>;
  discounted_cpm?: InputMaybe<Order_By>;
  exchange_account_network_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  obj?: InputMaybe<Order_By>;
  rate_card_cpm?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Package_List_Prepend_Input = {
  obj?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_package_list" */
export enum Hasura_Package_List_Select_Column {
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  DiscountedCpm = 'discounted_cpm',
  /** column name */
  ExchangeAccountNetworkId = 'exchange_account_network_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Obj = 'obj',
  /** column name */
  RateCardCpm = 'rate_card_cpm',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "hasura_package_list" */
export type Hasura_Package_List_Set_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  discounted_cpm?: InputMaybe<Scalars['numeric']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  rate_card_cpm?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Hasura_Package_List_Stddev_Fields = {
  __typename?: 'hasura_package_list_stddev_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Package_List_Stddev_Pop_Fields = {
  __typename?: 'hasura_package_list_stddev_pop_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Package_List_Stddev_Samp_Fields = {
  __typename?: 'hasura_package_list_stddev_samp_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hasura_package_list" */
export type Hasura_Package_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Package_List_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Package_List_Stream_Cursor_Value_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  discounted_cpm?: InputMaybe<Scalars['numeric']>;
  exchange_account_network_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  rate_card_cpm?: InputMaybe<Scalars['numeric']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Hasura_Package_List_Sum_Fields = {
  __typename?: 'hasura_package_list_sum_fields';
  discounted_cpm?: Maybe<Scalars['numeric']>;
  rate_card_cpm?: Maybe<Scalars['numeric']>;
};

export type Hasura_Package_List_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Package_List_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hasura_Package_List_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Package_List_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Package_List_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hasura_Package_List_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Package_List_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Package_List_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Package_List_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hasura_Package_List_Var_Pop_Fields = {
  __typename?: 'hasura_package_list_var_pop_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hasura_Package_List_Var_Samp_Fields = {
  __typename?: 'hasura_package_list_var_samp_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hasura_Package_List_Variance_Fields = {
  __typename?: 'hasura_package_list_variance_fields';
  discounted_cpm?: Maybe<Scalars['Float']>;
  rate_card_cpm?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Hasura_Package_Max_Fields = {
  __typename?: 'hasura_package_max_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hasura_Package_Min_Fields = {
  __typename?: 'hasura_package_min_fields';
  audience_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hasura_package" */
export type Hasura_Package_Mutation_Response = {
  __typename?: 'hasura_package_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Package>;
};

/** input type for inserting object relation for remote table "hasura_package" */
export type Hasura_Package_Obj_Rel_Insert_Input = {
  data: Hasura_Package_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hasura_Package_On_Conflict>;
};

/** on_conflict condition type for table "hasura_package" */
export type Hasura_Package_On_Conflict = {
  constraint: Hasura_Package_Constraint;
  update_columns?: Array<Hasura_Package_Update_Column>;
  where?: InputMaybe<Hasura_Package_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_package". */
export type Hasura_Package_Order_By = {
  audience?: InputMaybe<Audience_Order_By>;
  audience_id?: InputMaybe<Order_By>;
  discounted_cpm?: InputMaybe<Order_By>;
  exchange_account_network_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  obj?: InputMaybe<Order_By>;
  rate_card_cpm?: InputMaybe<Order_By>;
  revision?: InputMaybe<Order_By>;
  user?: InputMaybe<User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hasura_package */
export type Hasura_Package_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Package_Prepend_Input = {
  obj?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_package" */
export enum Hasura_Package_Select_Column {
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  Id = 'id',
  /** column name */
  Obj = 'obj',
  /** column name */
  Revision = 'revision',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "hasura_package" */
export type Hasura_Package_Set_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  revision?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Hasura_Package_Stddev_Fields = {
  __typename?: 'hasura_package_stddev_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Package_Stddev_Pop_Fields = {
  __typename?: 'hasura_package_stddev_pop_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Package_Stddev_Samp_Fields = {
  __typename?: 'hasura_package_stddev_samp_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hasura_package" */
export type Hasura_Package_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Package_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Package_Stream_Cursor_Value_Input = {
  audience_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  obj?: InputMaybe<Scalars['jsonb']>;
  revision?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Hasura_Package_Sum_Fields = {
  __typename?: 'hasura_package_sum_fields';
  revision?: Maybe<Scalars['Int']>;
};

/** update columns of table "hasura_package" */
export enum Hasura_Package_Update_Column {
  /** column name */
  AudienceId = 'audience_id',
  /** column name */
  Id = 'id',
  /** column name */
  Obj = 'obj',
  /** column name */
  Revision = 'revision',
  /** column name */
  UserId = 'user_id'
}

export type Hasura_Package_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Package_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hasura_Package_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Package_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Package_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hasura_Package_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Package_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Package_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Package_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hasura_Package_Var_Pop_Fields = {
  __typename?: 'hasura_package_var_pop_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hasura_Package_Var_Samp_Fields = {
  __typename?: 'hasura_package_var_samp_fields';
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hasura_Package_Variance_Fields = {
  __typename?: 'hasura_package_variance_fields';
  revision?: Maybe<Scalars['Float']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type LogResponse = {
  __typename?: 'logResponse';
  msg?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "provider" */
export type Provider = {
  __typename?: 'provider';
  accept_audience_files_path?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_match?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: Maybe<Scalars['String']>;
  accept_taxonomy_files_path?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: Maybe<Scalars['String']>;
  airflow_aws_conn_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  allowed_accounts: Array<Account_Provider>;
  /** An aggregate relationship */
  allowed_accounts_aggregate: Account_Provider_Aggregate;
  allowed_export_types: Scalars['jsonb'];
  check_audience_files_path?: Maybe<Scalars['String']>;
  check_taxonomy_files_path?: Maybe<Scalars['String']>;
  direct_oaid_mapping_params?: Maybe<Scalars['jsonb']>;
  field_delimiter: Scalars['String'];
  file_format: Scalars['String'];
  file_includes_header: Scalars['Boolean'];
  id: Scalars['String'];
  joiner_provider_account_id?: Maybe<Scalars['uuid']>;
  load_account_identifier?: Maybe<Scalars['String']>;
  load_audience_files_source_types?: Maybe<Scalars['String']>;
  load_audience_source_files_matching?: Maybe<Scalars['String']>;
  load_audience_source_files_pattern?: Maybe<Scalars['String']>;
  load_audience_source_partition_prefix?: Maybe<Scalars['String']>;
  load_enable_validate_audience_headers?: Maybe<Scalars['Int']>;
  load_folder_account_identifier_map?: Maybe<Scalars['String']>;
  load_lookback_days?: Maybe<Scalars['Int']>;
  load_taxonomy_file_path?: Maybe<Scalars['String']>;
  load_taxonomy_files_matching?: Maybe<Scalars['String']>;
  load_taxonomy_files_pattern?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  oaid_namespace?: Maybe<Scalars['jsonb']>;
  provider_diff_config?: Maybe<Scalars['jsonb']>;
  provider_salt?: Maybe<Scalars['uuid']>;
  raw_gcs_bucket?: Maybe<Scalars['String']>;
  ready_gcs_bucket?: Maybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Int']>;
  segment_creation_method: Scalars['String'];
  segment_delimiter?: Maybe<Scalars['String']>;
  segment_external_id_prefix?: Maybe<Scalars['String']>;
  segment_inbox_drop?: Maybe<Scalars['Int']>;
  segment_inbox_matches?: Maybe<Scalars['String']>;
  segment_inbox_path?: Maybe<Scalars['String']>;
  segment_inbox_taxonomy?: Maybe<Scalars['Boolean']>;
  source_bucket?: Maybe<Scalars['String']>;
  source_line_regexp_replace?: Maybe<Scalars['jsonb']>;
  source_prefix?: Maybe<Scalars['String']>;
  source_region?: Maybe<Scalars['String']>;
  universe_inbox_drop?: Maybe<Scalars['Int']>;
  universe_inbox_matches?: Maybe<Scalars['String']>;
  universe_inbox_path?: Maybe<Scalars['String']>;
  universe_outbox_drop?: Maybe<Scalars['Int']>;
  universe_outbox_matches?: Maybe<Scalars['String']>;
  universe_outbox_path?: Maybe<Scalars['String']>;
  update_type: Scalars['String'];
};


/** columns and relationships of "provider" */
export type ProviderAllowed_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderAllowed_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Provider_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Account_Provider_Order_By>>;
  where?: InputMaybe<Account_Provider_Bool_Exp>;
};


/** columns and relationships of "provider" */
export type ProviderAllowed_Export_TypesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "provider" */
export type ProviderDirect_Oaid_Mapping_ParamsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "provider" */
export type ProviderOaid_NamespaceArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "provider" */
export type ProviderProvider_Diff_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "provider" */
export type ProviderSource_Line_Regexp_ReplaceArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "provider" */
export type Provider_Aggregate = {
  __typename?: 'provider_aggregate';
  aggregate?: Maybe<Provider_Aggregate_Fields>;
  nodes: Array<Provider>;
};

/** aggregate fields of "provider" */
export type Provider_Aggregate_Fields = {
  __typename?: 'provider_aggregate_fields';
  avg?: Maybe<Provider_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Provider_Max_Fields>;
  min?: Maybe<Provider_Min_Fields>;
  stddev?: Maybe<Provider_Stddev_Fields>;
  stddev_pop?: Maybe<Provider_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Provider_Stddev_Samp_Fields>;
  sum?: Maybe<Provider_Sum_Fields>;
  var_pop?: Maybe<Provider_Var_Pop_Fields>;
  var_samp?: Maybe<Provider_Var_Samp_Fields>;
  variance?: Maybe<Provider_Variance_Fields>;
};


/** aggregate fields of "provider" */
export type Provider_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provider_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Provider_Append_Input = {
  allowed_export_types?: InputMaybe<Scalars['jsonb']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['jsonb']>;
  oaid_namespace?: InputMaybe<Scalars['jsonb']>;
  provider_diff_config?: InputMaybe<Scalars['jsonb']>;
  source_line_regexp_replace?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Provider_Avg_Fields = {
  __typename?: 'provider_avg_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "provider". All fields are combined with a logical 'AND'. */
export type Provider_Bool_Exp = {
  _and?: InputMaybe<Array<Provider_Bool_Exp>>;
  _not?: InputMaybe<Provider_Bool_Exp>;
  _or?: InputMaybe<Array<Provider_Bool_Exp>>;
  accept_audience_files_path?: InputMaybe<String_Comparison_Exp>;
  accept_audience_files_pattern_match?: InputMaybe<String_Comparison_Exp>;
  accept_audience_files_pattern_replace?: InputMaybe<String_Comparison_Exp>;
  accept_taxonomy_files_path?: InputMaybe<String_Comparison_Exp>;
  accept_taxonomy_files_pattern_match?: InputMaybe<String_Comparison_Exp>;
  accept_taxonomy_files_pattern_replace?: InputMaybe<String_Comparison_Exp>;
  airflow_aws_conn_id?: InputMaybe<String_Comparison_Exp>;
  allowed_accounts?: InputMaybe<Account_Provider_Bool_Exp>;
  allowed_accounts_aggregate?: InputMaybe<Account_Provider_Aggregate_Bool_Exp>;
  allowed_export_types?: InputMaybe<Jsonb_Comparison_Exp>;
  check_audience_files_path?: InputMaybe<String_Comparison_Exp>;
  check_taxonomy_files_path?: InputMaybe<String_Comparison_Exp>;
  direct_oaid_mapping_params?: InputMaybe<Jsonb_Comparison_Exp>;
  field_delimiter?: InputMaybe<String_Comparison_Exp>;
  file_format?: InputMaybe<String_Comparison_Exp>;
  file_includes_header?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  joiner_provider_account_id?: InputMaybe<Uuid_Comparison_Exp>;
  load_account_identifier?: InputMaybe<String_Comparison_Exp>;
  load_audience_files_source_types?: InputMaybe<String_Comparison_Exp>;
  load_audience_source_files_matching?: InputMaybe<String_Comparison_Exp>;
  load_audience_source_files_pattern?: InputMaybe<String_Comparison_Exp>;
  load_audience_source_partition_prefix?: InputMaybe<String_Comparison_Exp>;
  load_enable_validate_audience_headers?: InputMaybe<Int_Comparison_Exp>;
  load_folder_account_identifier_map?: InputMaybe<String_Comparison_Exp>;
  load_lookback_days?: InputMaybe<Int_Comparison_Exp>;
  load_taxonomy_file_path?: InputMaybe<String_Comparison_Exp>;
  load_taxonomy_files_matching?: InputMaybe<String_Comparison_Exp>;
  load_taxonomy_files_pattern?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oaid_namespace?: InputMaybe<Jsonb_Comparison_Exp>;
  provider_diff_config?: InputMaybe<Jsonb_Comparison_Exp>;
  provider_salt?: InputMaybe<Uuid_Comparison_Exp>;
  raw_gcs_bucket?: InputMaybe<String_Comparison_Exp>;
  ready_gcs_bucket?: InputMaybe<String_Comparison_Exp>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Int_Comparison_Exp>;
  segment_creation_method?: InputMaybe<String_Comparison_Exp>;
  segment_delimiter?: InputMaybe<String_Comparison_Exp>;
  segment_external_id_prefix?: InputMaybe<String_Comparison_Exp>;
  segment_inbox_drop?: InputMaybe<Int_Comparison_Exp>;
  segment_inbox_matches?: InputMaybe<String_Comparison_Exp>;
  segment_inbox_path?: InputMaybe<String_Comparison_Exp>;
  segment_inbox_taxonomy?: InputMaybe<Boolean_Comparison_Exp>;
  source_bucket?: InputMaybe<String_Comparison_Exp>;
  source_line_regexp_replace?: InputMaybe<Jsonb_Comparison_Exp>;
  source_prefix?: InputMaybe<String_Comparison_Exp>;
  source_region?: InputMaybe<String_Comparison_Exp>;
  universe_inbox_drop?: InputMaybe<Int_Comparison_Exp>;
  universe_inbox_matches?: InputMaybe<String_Comparison_Exp>;
  universe_inbox_path?: InputMaybe<String_Comparison_Exp>;
  universe_outbox_drop?: InputMaybe<Int_Comparison_Exp>;
  universe_outbox_matches?: InputMaybe<String_Comparison_Exp>;
  universe_outbox_path?: InputMaybe<String_Comparison_Exp>;
  update_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider" */
export enum Provider_Constraint {
  /** unique or primary key constraint on columns  */
  ProviderNameLowerIdx = 'provider_name_lower_idx',
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Provider_Delete_At_Path_Input = {
  allowed_export_types?: InputMaybe<Array<Scalars['String']>>;
  direct_oaid_mapping_params?: InputMaybe<Array<Scalars['String']>>;
  oaid_namespace?: InputMaybe<Array<Scalars['String']>>;
  provider_diff_config?: InputMaybe<Array<Scalars['String']>>;
  source_line_regexp_replace?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Provider_Delete_Elem_Input = {
  allowed_export_types?: InputMaybe<Scalars['Int']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['Int']>;
  oaid_namespace?: InputMaybe<Scalars['Int']>;
  provider_diff_config?: InputMaybe<Scalars['Int']>;
  source_line_regexp_replace?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Provider_Delete_Key_Input = {
  allowed_export_types?: InputMaybe<Scalars['String']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['String']>;
  oaid_namespace?: InputMaybe<Scalars['String']>;
  provider_diff_config?: InputMaybe<Scalars['String']>;
  source_line_regexp_replace?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "provider" */
export type Provider_Inc_Input = {
  load_enable_validate_audience_headers?: InputMaybe<Scalars['Int']>;
  load_lookback_days?: InputMaybe<Scalars['Int']>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Scalars['Int']>;
  segment_inbox_drop?: InputMaybe<Scalars['Int']>;
  universe_inbox_drop?: InputMaybe<Scalars['Int']>;
  universe_outbox_drop?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "provider" */
export type Provider_Insert_Input = {
  accept_audience_files_path?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: InputMaybe<Scalars['String']>;
  airflow_aws_conn_id?: InputMaybe<Scalars['String']>;
  allowed_accounts?: InputMaybe<Account_Provider_Arr_Rel_Insert_Input>;
  allowed_export_types?: InputMaybe<Scalars['jsonb']>;
  check_audience_files_path?: InputMaybe<Scalars['String']>;
  check_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['jsonb']>;
  field_delimiter?: InputMaybe<Scalars['String']>;
  file_format?: InputMaybe<Scalars['String']>;
  file_includes_header?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  joiner_provider_account_id?: InputMaybe<Scalars['uuid']>;
  load_account_identifier?: InputMaybe<Scalars['String']>;
  load_audience_files_source_types?: InputMaybe<Scalars['String']>;
  load_audience_source_files_matching?: InputMaybe<Scalars['String']>;
  load_audience_source_files_pattern?: InputMaybe<Scalars['String']>;
  load_audience_source_partition_prefix?: InputMaybe<Scalars['String']>;
  load_enable_validate_audience_headers?: InputMaybe<Scalars['Int']>;
  load_folder_account_identifier_map?: InputMaybe<Scalars['String']>;
  load_lookback_days?: InputMaybe<Scalars['Int']>;
  load_taxonomy_file_path?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_matching?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_pattern?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oaid_namespace?: InputMaybe<Scalars['jsonb']>;
  provider_diff_config?: InputMaybe<Scalars['jsonb']>;
  provider_salt?: InputMaybe<Scalars['uuid']>;
  raw_gcs_bucket?: InputMaybe<Scalars['String']>;
  ready_gcs_bucket?: InputMaybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Scalars['Int']>;
  segment_creation_method?: InputMaybe<Scalars['String']>;
  segment_delimiter?: InputMaybe<Scalars['String']>;
  segment_external_id_prefix?: InputMaybe<Scalars['String']>;
  segment_inbox_drop?: InputMaybe<Scalars['Int']>;
  segment_inbox_matches?: InputMaybe<Scalars['String']>;
  segment_inbox_path?: InputMaybe<Scalars['String']>;
  segment_inbox_taxonomy?: InputMaybe<Scalars['Boolean']>;
  source_bucket?: InputMaybe<Scalars['String']>;
  source_line_regexp_replace?: InputMaybe<Scalars['jsonb']>;
  source_prefix?: InputMaybe<Scalars['String']>;
  source_region?: InputMaybe<Scalars['String']>;
  universe_inbox_drop?: InputMaybe<Scalars['Int']>;
  universe_inbox_matches?: InputMaybe<Scalars['String']>;
  universe_inbox_path?: InputMaybe<Scalars['String']>;
  universe_outbox_drop?: InputMaybe<Scalars['Int']>;
  universe_outbox_matches?: InputMaybe<Scalars['String']>;
  universe_outbox_path?: InputMaybe<Scalars['String']>;
  update_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provider_Max_Fields = {
  __typename?: 'provider_max_fields';
  accept_audience_files_path?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_match?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: Maybe<Scalars['String']>;
  accept_taxonomy_files_path?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: Maybe<Scalars['String']>;
  airflow_aws_conn_id?: Maybe<Scalars['String']>;
  check_audience_files_path?: Maybe<Scalars['String']>;
  check_taxonomy_files_path?: Maybe<Scalars['String']>;
  field_delimiter?: Maybe<Scalars['String']>;
  file_format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  joiner_provider_account_id?: Maybe<Scalars['uuid']>;
  load_account_identifier?: Maybe<Scalars['String']>;
  load_audience_files_source_types?: Maybe<Scalars['String']>;
  load_audience_source_files_matching?: Maybe<Scalars['String']>;
  load_audience_source_files_pattern?: Maybe<Scalars['String']>;
  load_audience_source_partition_prefix?: Maybe<Scalars['String']>;
  load_enable_validate_audience_headers?: Maybe<Scalars['Int']>;
  load_folder_account_identifier_map?: Maybe<Scalars['String']>;
  load_lookback_days?: Maybe<Scalars['Int']>;
  load_taxonomy_file_path?: Maybe<Scalars['String']>;
  load_taxonomy_files_matching?: Maybe<Scalars['String']>;
  load_taxonomy_files_pattern?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider_salt?: Maybe<Scalars['uuid']>;
  raw_gcs_bucket?: Maybe<Scalars['String']>;
  ready_gcs_bucket?: Maybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Int']>;
  segment_creation_method?: Maybe<Scalars['String']>;
  segment_delimiter?: Maybe<Scalars['String']>;
  segment_external_id_prefix?: Maybe<Scalars['String']>;
  segment_inbox_drop?: Maybe<Scalars['Int']>;
  segment_inbox_matches?: Maybe<Scalars['String']>;
  segment_inbox_path?: Maybe<Scalars['String']>;
  source_bucket?: Maybe<Scalars['String']>;
  source_prefix?: Maybe<Scalars['String']>;
  source_region?: Maybe<Scalars['String']>;
  universe_inbox_drop?: Maybe<Scalars['Int']>;
  universe_inbox_matches?: Maybe<Scalars['String']>;
  universe_inbox_path?: Maybe<Scalars['String']>;
  universe_outbox_drop?: Maybe<Scalars['Int']>;
  universe_outbox_matches?: Maybe<Scalars['String']>;
  universe_outbox_path?: Maybe<Scalars['String']>;
  update_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provider_Min_Fields = {
  __typename?: 'provider_min_fields';
  accept_audience_files_path?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_match?: Maybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: Maybe<Scalars['String']>;
  accept_taxonomy_files_path?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: Maybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: Maybe<Scalars['String']>;
  airflow_aws_conn_id?: Maybe<Scalars['String']>;
  check_audience_files_path?: Maybe<Scalars['String']>;
  check_taxonomy_files_path?: Maybe<Scalars['String']>;
  field_delimiter?: Maybe<Scalars['String']>;
  file_format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  joiner_provider_account_id?: Maybe<Scalars['uuid']>;
  load_account_identifier?: Maybe<Scalars['String']>;
  load_audience_files_source_types?: Maybe<Scalars['String']>;
  load_audience_source_files_matching?: Maybe<Scalars['String']>;
  load_audience_source_files_pattern?: Maybe<Scalars['String']>;
  load_audience_source_partition_prefix?: Maybe<Scalars['String']>;
  load_enable_validate_audience_headers?: Maybe<Scalars['Int']>;
  load_folder_account_identifier_map?: Maybe<Scalars['String']>;
  load_lookback_days?: Maybe<Scalars['Int']>;
  load_taxonomy_file_path?: Maybe<Scalars['String']>;
  load_taxonomy_files_matching?: Maybe<Scalars['String']>;
  load_taxonomy_files_pattern?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider_salt?: Maybe<Scalars['uuid']>;
  raw_gcs_bucket?: Maybe<Scalars['String']>;
  ready_gcs_bucket?: Maybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Int']>;
  segment_creation_method?: Maybe<Scalars['String']>;
  segment_delimiter?: Maybe<Scalars['String']>;
  segment_external_id_prefix?: Maybe<Scalars['String']>;
  segment_inbox_drop?: Maybe<Scalars['Int']>;
  segment_inbox_matches?: Maybe<Scalars['String']>;
  segment_inbox_path?: Maybe<Scalars['String']>;
  source_bucket?: Maybe<Scalars['String']>;
  source_prefix?: Maybe<Scalars['String']>;
  source_region?: Maybe<Scalars['String']>;
  universe_inbox_drop?: Maybe<Scalars['Int']>;
  universe_inbox_matches?: Maybe<Scalars['String']>;
  universe_inbox_path?: Maybe<Scalars['String']>;
  universe_outbox_drop?: Maybe<Scalars['Int']>;
  universe_outbox_matches?: Maybe<Scalars['String']>;
  universe_outbox_path?: Maybe<Scalars['String']>;
  update_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provider" */
export type Provider_Mutation_Response = {
  __typename?: 'provider_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provider>;
};

/** input type for inserting object relation for remote table "provider" */
export type Provider_Obj_Rel_Insert_Input = {
  data: Provider_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Provider_On_Conflict>;
};

/** on_conflict condition type for table "provider" */
export type Provider_On_Conflict = {
  constraint: Provider_Constraint;
  update_columns?: Array<Provider_Update_Column>;
  where?: InputMaybe<Provider_Bool_Exp>;
};

/** Ordering options when selecting data from "provider". */
export type Provider_Order_By = {
  accept_audience_files_path?: InputMaybe<Order_By>;
  accept_audience_files_pattern_match?: InputMaybe<Order_By>;
  accept_audience_files_pattern_replace?: InputMaybe<Order_By>;
  accept_taxonomy_files_path?: InputMaybe<Order_By>;
  accept_taxonomy_files_pattern_match?: InputMaybe<Order_By>;
  accept_taxonomy_files_pattern_replace?: InputMaybe<Order_By>;
  airflow_aws_conn_id?: InputMaybe<Order_By>;
  allowed_accounts_aggregate?: InputMaybe<Account_Provider_Aggregate_Order_By>;
  allowed_export_types?: InputMaybe<Order_By>;
  check_audience_files_path?: InputMaybe<Order_By>;
  check_taxonomy_files_path?: InputMaybe<Order_By>;
  direct_oaid_mapping_params?: InputMaybe<Order_By>;
  field_delimiter?: InputMaybe<Order_By>;
  file_format?: InputMaybe<Order_By>;
  file_includes_header?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  joiner_provider_account_id?: InputMaybe<Order_By>;
  load_account_identifier?: InputMaybe<Order_By>;
  load_audience_files_source_types?: InputMaybe<Order_By>;
  load_audience_source_files_matching?: InputMaybe<Order_By>;
  load_audience_source_files_pattern?: InputMaybe<Order_By>;
  load_audience_source_partition_prefix?: InputMaybe<Order_By>;
  load_enable_validate_audience_headers?: InputMaybe<Order_By>;
  load_folder_account_identifier_map?: InputMaybe<Order_By>;
  load_lookback_days?: InputMaybe<Order_By>;
  load_taxonomy_file_path?: InputMaybe<Order_By>;
  load_taxonomy_files_matching?: InputMaybe<Order_By>;
  load_taxonomy_files_pattern?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oaid_namespace?: InputMaybe<Order_By>;
  provider_diff_config?: InputMaybe<Order_By>;
  provider_salt?: InputMaybe<Order_By>;
  raw_gcs_bucket?: InputMaybe<Order_By>;
  ready_gcs_bucket?: InputMaybe<Order_By>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Order_By>;
  segment_creation_method?: InputMaybe<Order_By>;
  segment_delimiter?: InputMaybe<Order_By>;
  segment_external_id_prefix?: InputMaybe<Order_By>;
  segment_inbox_drop?: InputMaybe<Order_By>;
  segment_inbox_matches?: InputMaybe<Order_By>;
  segment_inbox_path?: InputMaybe<Order_By>;
  segment_inbox_taxonomy?: InputMaybe<Order_By>;
  source_bucket?: InputMaybe<Order_By>;
  source_line_regexp_replace?: InputMaybe<Order_By>;
  source_prefix?: InputMaybe<Order_By>;
  source_region?: InputMaybe<Order_By>;
  universe_inbox_drop?: InputMaybe<Order_By>;
  universe_inbox_matches?: InputMaybe<Order_By>;
  universe_inbox_path?: InputMaybe<Order_By>;
  universe_outbox_drop?: InputMaybe<Order_By>;
  universe_outbox_matches?: InputMaybe<Order_By>;
  universe_outbox_path?: InputMaybe<Order_By>;
  update_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: provider */
export type Provider_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Provider_Prepend_Input = {
  allowed_export_types?: InputMaybe<Scalars['jsonb']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['jsonb']>;
  oaid_namespace?: InputMaybe<Scalars['jsonb']>;
  provider_diff_config?: InputMaybe<Scalars['jsonb']>;
  source_line_regexp_replace?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "provider" */
export enum Provider_Select_Column {
  /** column name */
  AcceptAudienceFilesPath = 'accept_audience_files_path',
  /** column name */
  AcceptAudienceFilesPatternMatch = 'accept_audience_files_pattern_match',
  /** column name */
  AcceptAudienceFilesPatternReplace = 'accept_audience_files_pattern_replace',
  /** column name */
  AcceptTaxonomyFilesPath = 'accept_taxonomy_files_path',
  /** column name */
  AcceptTaxonomyFilesPatternMatch = 'accept_taxonomy_files_pattern_match',
  /** column name */
  AcceptTaxonomyFilesPatternReplace = 'accept_taxonomy_files_pattern_replace',
  /** column name */
  AirflowAwsConnId = 'airflow_aws_conn_id',
  /** column name */
  AllowedExportTypes = 'allowed_export_types',
  /** column name */
  CheckAudienceFilesPath = 'check_audience_files_path',
  /** column name */
  CheckTaxonomyFilesPath = 'check_taxonomy_files_path',
  /** column name */
  DirectOaidMappingParams = 'direct_oaid_mapping_params',
  /** column name */
  FieldDelimiter = 'field_delimiter',
  /** column name */
  FileFormat = 'file_format',
  /** column name */
  FileIncludesHeader = 'file_includes_header',
  /** column name */
  Id = 'id',
  /** column name */
  JoinerProviderAccountId = 'joiner_provider_account_id',
  /** column name */
  LoadAccountIdentifier = 'load_account_identifier',
  /** column name */
  LoadAudienceFilesSourceTypes = 'load_audience_files_source_types',
  /** column name */
  LoadAudienceSourceFilesMatching = 'load_audience_source_files_matching',
  /** column name */
  LoadAudienceSourceFilesPattern = 'load_audience_source_files_pattern',
  /** column name */
  LoadAudienceSourcePartitionPrefix = 'load_audience_source_partition_prefix',
  /** column name */
  LoadEnableValidateAudienceHeaders = 'load_enable_validate_audience_headers',
  /** column name */
  LoadFolderAccountIdentifierMap = 'load_folder_account_identifier_map',
  /** column name */
  LoadLookbackDays = 'load_lookback_days',
  /** column name */
  LoadTaxonomyFilePath = 'load_taxonomy_file_path',
  /** column name */
  LoadTaxonomyFilesMatching = 'load_taxonomy_files_matching',
  /** column name */
  LoadTaxonomyFilesPattern = 'load_taxonomy_files_pattern',
  /** column name */
  Name = 'name',
  /** column name */
  OaidNamespace = 'oaid_namespace',
  /** column name */
  ProviderDiffConfig = 'provider_diff_config',
  /** column name */
  ProviderSalt = 'provider_salt',
  /** column name */
  RawGcsBucket = 'raw_gcs_bucket',
  /** column name */
  ReadyGcsBucket = 'ready_gcs_bucket',
  /** column name */
  S3UploadInactivityPeriodSeconds = 's3_upload_inactivity_period_seconds',
  /** column name */
  SegmentCreationMethod = 'segment_creation_method',
  /** column name */
  SegmentDelimiter = 'segment_delimiter',
  /** column name */
  SegmentExternalIdPrefix = 'segment_external_id_prefix',
  /** column name */
  SegmentInboxDrop = 'segment_inbox_drop',
  /** column name */
  SegmentInboxMatches = 'segment_inbox_matches',
  /** column name */
  SegmentInboxPath = 'segment_inbox_path',
  /** column name */
  SegmentInboxTaxonomy = 'segment_inbox_taxonomy',
  /** column name */
  SourceBucket = 'source_bucket',
  /** column name */
  SourceLineRegexpReplace = 'source_line_regexp_replace',
  /** column name */
  SourcePrefix = 'source_prefix',
  /** column name */
  SourceRegion = 'source_region',
  /** column name */
  UniverseInboxDrop = 'universe_inbox_drop',
  /** column name */
  UniverseInboxMatches = 'universe_inbox_matches',
  /** column name */
  UniverseInboxPath = 'universe_inbox_path',
  /** column name */
  UniverseOutboxDrop = 'universe_outbox_drop',
  /** column name */
  UniverseOutboxMatches = 'universe_outbox_matches',
  /** column name */
  UniverseOutboxPath = 'universe_outbox_path',
  /** column name */
  UpdateType = 'update_type'
}

/** input type for updating data in table "provider" */
export type Provider_Set_Input = {
  accept_audience_files_path?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: InputMaybe<Scalars['String']>;
  airflow_aws_conn_id?: InputMaybe<Scalars['String']>;
  allowed_export_types?: InputMaybe<Scalars['jsonb']>;
  check_audience_files_path?: InputMaybe<Scalars['String']>;
  check_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['jsonb']>;
  field_delimiter?: InputMaybe<Scalars['String']>;
  file_format?: InputMaybe<Scalars['String']>;
  file_includes_header?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  joiner_provider_account_id?: InputMaybe<Scalars['uuid']>;
  load_account_identifier?: InputMaybe<Scalars['String']>;
  load_audience_files_source_types?: InputMaybe<Scalars['String']>;
  load_audience_source_files_matching?: InputMaybe<Scalars['String']>;
  load_audience_source_files_pattern?: InputMaybe<Scalars['String']>;
  load_audience_source_partition_prefix?: InputMaybe<Scalars['String']>;
  load_enable_validate_audience_headers?: InputMaybe<Scalars['Int']>;
  load_folder_account_identifier_map?: InputMaybe<Scalars['String']>;
  load_lookback_days?: InputMaybe<Scalars['Int']>;
  load_taxonomy_file_path?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_matching?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_pattern?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oaid_namespace?: InputMaybe<Scalars['jsonb']>;
  provider_diff_config?: InputMaybe<Scalars['jsonb']>;
  provider_salt?: InputMaybe<Scalars['uuid']>;
  raw_gcs_bucket?: InputMaybe<Scalars['String']>;
  ready_gcs_bucket?: InputMaybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Scalars['Int']>;
  segment_creation_method?: InputMaybe<Scalars['String']>;
  segment_delimiter?: InputMaybe<Scalars['String']>;
  segment_external_id_prefix?: InputMaybe<Scalars['String']>;
  segment_inbox_drop?: InputMaybe<Scalars['Int']>;
  segment_inbox_matches?: InputMaybe<Scalars['String']>;
  segment_inbox_path?: InputMaybe<Scalars['String']>;
  segment_inbox_taxonomy?: InputMaybe<Scalars['Boolean']>;
  source_bucket?: InputMaybe<Scalars['String']>;
  source_line_regexp_replace?: InputMaybe<Scalars['jsonb']>;
  source_prefix?: InputMaybe<Scalars['String']>;
  source_region?: InputMaybe<Scalars['String']>;
  universe_inbox_drop?: InputMaybe<Scalars['Int']>;
  universe_inbox_matches?: InputMaybe<Scalars['String']>;
  universe_inbox_path?: InputMaybe<Scalars['String']>;
  universe_outbox_drop?: InputMaybe<Scalars['Int']>;
  universe_outbox_matches?: InputMaybe<Scalars['String']>;
  universe_outbox_path?: InputMaybe<Scalars['String']>;
  update_type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Provider_Stddev_Fields = {
  __typename?: 'provider_stddev_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Provider_Stddev_Pop_Fields = {
  __typename?: 'provider_stddev_pop_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Provider_Stddev_Samp_Fields = {
  __typename?: 'provider_stddev_samp_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "provider" */
export type Provider_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Provider_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Provider_Stream_Cursor_Value_Input = {
  accept_audience_files_path?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_audience_files_pattern_replace?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_match?: InputMaybe<Scalars['String']>;
  accept_taxonomy_files_pattern_replace?: InputMaybe<Scalars['String']>;
  airflow_aws_conn_id?: InputMaybe<Scalars['String']>;
  allowed_export_types?: InputMaybe<Scalars['jsonb']>;
  check_audience_files_path?: InputMaybe<Scalars['String']>;
  check_taxonomy_files_path?: InputMaybe<Scalars['String']>;
  direct_oaid_mapping_params?: InputMaybe<Scalars['jsonb']>;
  field_delimiter?: InputMaybe<Scalars['String']>;
  file_format?: InputMaybe<Scalars['String']>;
  file_includes_header?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  joiner_provider_account_id?: InputMaybe<Scalars['uuid']>;
  load_account_identifier?: InputMaybe<Scalars['String']>;
  load_audience_files_source_types?: InputMaybe<Scalars['String']>;
  load_audience_source_files_matching?: InputMaybe<Scalars['String']>;
  load_audience_source_files_pattern?: InputMaybe<Scalars['String']>;
  load_audience_source_partition_prefix?: InputMaybe<Scalars['String']>;
  load_enable_validate_audience_headers?: InputMaybe<Scalars['Int']>;
  load_folder_account_identifier_map?: InputMaybe<Scalars['String']>;
  load_lookback_days?: InputMaybe<Scalars['Int']>;
  load_taxonomy_file_path?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_matching?: InputMaybe<Scalars['String']>;
  load_taxonomy_files_pattern?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  oaid_namespace?: InputMaybe<Scalars['jsonb']>;
  provider_diff_config?: InputMaybe<Scalars['jsonb']>;
  provider_salt?: InputMaybe<Scalars['uuid']>;
  raw_gcs_bucket?: InputMaybe<Scalars['String']>;
  ready_gcs_bucket?: InputMaybe<Scalars['String']>;
  s3_upload_inactivity_period_seconds?: InputMaybe<Scalars['Int']>;
  segment_creation_method?: InputMaybe<Scalars['String']>;
  segment_delimiter?: InputMaybe<Scalars['String']>;
  segment_external_id_prefix?: InputMaybe<Scalars['String']>;
  segment_inbox_drop?: InputMaybe<Scalars['Int']>;
  segment_inbox_matches?: InputMaybe<Scalars['String']>;
  segment_inbox_path?: InputMaybe<Scalars['String']>;
  segment_inbox_taxonomy?: InputMaybe<Scalars['Boolean']>;
  source_bucket?: InputMaybe<Scalars['String']>;
  source_line_regexp_replace?: InputMaybe<Scalars['jsonb']>;
  source_prefix?: InputMaybe<Scalars['String']>;
  source_region?: InputMaybe<Scalars['String']>;
  universe_inbox_drop?: InputMaybe<Scalars['Int']>;
  universe_inbox_matches?: InputMaybe<Scalars['String']>;
  universe_inbox_path?: InputMaybe<Scalars['String']>;
  universe_outbox_drop?: InputMaybe<Scalars['Int']>;
  universe_outbox_matches?: InputMaybe<Scalars['String']>;
  universe_outbox_path?: InputMaybe<Scalars['String']>;
  update_type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Provider_Sum_Fields = {
  __typename?: 'provider_sum_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Int']>;
  load_lookback_days?: Maybe<Scalars['Int']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Int']>;
  segment_inbox_drop?: Maybe<Scalars['Int']>;
  universe_inbox_drop?: Maybe<Scalars['Int']>;
  universe_outbox_drop?: Maybe<Scalars['Int']>;
};

/** update columns of table "provider" */
export enum Provider_Update_Column {
  /** column name */
  AcceptAudienceFilesPath = 'accept_audience_files_path',
  /** column name */
  AcceptAudienceFilesPatternMatch = 'accept_audience_files_pattern_match',
  /** column name */
  AcceptAudienceFilesPatternReplace = 'accept_audience_files_pattern_replace',
  /** column name */
  AcceptTaxonomyFilesPath = 'accept_taxonomy_files_path',
  /** column name */
  AcceptTaxonomyFilesPatternMatch = 'accept_taxonomy_files_pattern_match',
  /** column name */
  AcceptTaxonomyFilesPatternReplace = 'accept_taxonomy_files_pattern_replace',
  /** column name */
  AirflowAwsConnId = 'airflow_aws_conn_id',
  /** column name */
  AllowedExportTypes = 'allowed_export_types',
  /** column name */
  CheckAudienceFilesPath = 'check_audience_files_path',
  /** column name */
  CheckTaxonomyFilesPath = 'check_taxonomy_files_path',
  /** column name */
  DirectOaidMappingParams = 'direct_oaid_mapping_params',
  /** column name */
  FieldDelimiter = 'field_delimiter',
  /** column name */
  FileFormat = 'file_format',
  /** column name */
  FileIncludesHeader = 'file_includes_header',
  /** column name */
  Id = 'id',
  /** column name */
  JoinerProviderAccountId = 'joiner_provider_account_id',
  /** column name */
  LoadAccountIdentifier = 'load_account_identifier',
  /** column name */
  LoadAudienceFilesSourceTypes = 'load_audience_files_source_types',
  /** column name */
  LoadAudienceSourceFilesMatching = 'load_audience_source_files_matching',
  /** column name */
  LoadAudienceSourceFilesPattern = 'load_audience_source_files_pattern',
  /** column name */
  LoadAudienceSourcePartitionPrefix = 'load_audience_source_partition_prefix',
  /** column name */
  LoadEnableValidateAudienceHeaders = 'load_enable_validate_audience_headers',
  /** column name */
  LoadFolderAccountIdentifierMap = 'load_folder_account_identifier_map',
  /** column name */
  LoadLookbackDays = 'load_lookback_days',
  /** column name */
  LoadTaxonomyFilePath = 'load_taxonomy_file_path',
  /** column name */
  LoadTaxonomyFilesMatching = 'load_taxonomy_files_matching',
  /** column name */
  LoadTaxonomyFilesPattern = 'load_taxonomy_files_pattern',
  /** column name */
  Name = 'name',
  /** column name */
  OaidNamespace = 'oaid_namespace',
  /** column name */
  ProviderDiffConfig = 'provider_diff_config',
  /** column name */
  ProviderSalt = 'provider_salt',
  /** column name */
  RawGcsBucket = 'raw_gcs_bucket',
  /** column name */
  ReadyGcsBucket = 'ready_gcs_bucket',
  /** column name */
  S3UploadInactivityPeriodSeconds = 's3_upload_inactivity_period_seconds',
  /** column name */
  SegmentCreationMethod = 'segment_creation_method',
  /** column name */
  SegmentDelimiter = 'segment_delimiter',
  /** column name */
  SegmentExternalIdPrefix = 'segment_external_id_prefix',
  /** column name */
  SegmentInboxDrop = 'segment_inbox_drop',
  /** column name */
  SegmentInboxMatches = 'segment_inbox_matches',
  /** column name */
  SegmentInboxPath = 'segment_inbox_path',
  /** column name */
  SegmentInboxTaxonomy = 'segment_inbox_taxonomy',
  /** column name */
  SourceBucket = 'source_bucket',
  /** column name */
  SourceLineRegexpReplace = 'source_line_regexp_replace',
  /** column name */
  SourcePrefix = 'source_prefix',
  /** column name */
  SourceRegion = 'source_region',
  /** column name */
  UniverseInboxDrop = 'universe_inbox_drop',
  /** column name */
  UniverseInboxMatches = 'universe_inbox_matches',
  /** column name */
  UniverseInboxPath = 'universe_inbox_path',
  /** column name */
  UniverseOutboxDrop = 'universe_outbox_drop',
  /** column name */
  UniverseOutboxMatches = 'universe_outbox_matches',
  /** column name */
  UniverseOutboxPath = 'universe_outbox_path',
  /** column name */
  UpdateType = 'update_type'
}

export type Provider_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Provider_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Provider_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Provider_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Provider_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Provider_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Provider_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Provider_Set_Input>;
  /** filter the rows which have to be updated */
  where: Provider_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Provider_Var_Pop_Fields = {
  __typename?: 'provider_var_pop_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Provider_Var_Samp_Fields = {
  __typename?: 'provider_var_samp_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Provider_Variance_Fields = {
  __typename?: 'provider_variance_fields';
  load_enable_validate_audience_headers?: Maybe<Scalars['Float']>;
  load_lookback_days?: Maybe<Scalars['Float']>;
  s3_upload_inactivity_period_seconds?: Maybe<Scalars['Float']>;
  segment_inbox_drop?: Maybe<Scalars['Float']>;
  universe_inbox_drop?: Maybe<Scalars['Float']>;
  universe_outbox_drop?: Maybe<Scalars['Float']>;
};

/** Tracks the Audience Segments uploaded by an Organization */
export type Segment = {
  __typename?: 'segment';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  allowed_accounts?: Maybe<Scalars['jsonb']>;
  allowed_countries: Scalars['jsonb'];
  bq_location?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  expiration_date?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gcs_file_path?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_archived?: Maybe<Scalars['Boolean']>;
  is_leaf?: Maybe<Scalars['Boolean']>;
  is_nav_only?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  parent_segment?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['jsonb']>;
  propensity?: Maybe<Scalars['String']>;
  /** An object relationship */
  provider?: Maybe<Provider>;
  provider_id?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['Int']>;
  seed_segment?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  segment_audiences: Array<Audience_Segment>;
  /** An aggregate relationship */
  segment_audiences_aggregate: Audience_Segment_Aggregate;
  segment_category?: Maybe<Scalars['String']>;
  segment_group_id?: Maybe<Scalars['String']>;
  segment_source?: Maybe<Scalars['String']>;
  segment_source_description?: Maybe<Scalars['String']>;
  segment_sub_category?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_bq_tables: Scalars['jsonb'];
  source_matched_count?: Maybe<Scalars['bigint']>;
  source_provided_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_category?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentAllowed_AccountsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentAllowed_CountriesArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentPathArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentSegment_AudiencesArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentSegment_Audiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Segment_Order_By>>;
  where?: InputMaybe<Audience_Segment_Bool_Exp>;
};


/** Tracks the Audience Segments uploaded by an Organization */
export type SegmentSource_Bq_TablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "segment" */
export type Segment_Aggregate = {
  __typename?: 'segment_aggregate';
  aggregate?: Maybe<Segment_Aggregate_Fields>;
  nodes: Array<Segment>;
};

export type Segment_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Segment_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Segment_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Segment_Aggregate_Bool_Exp_Count>;
};

export type Segment_Aggregate_Bool_Exp_Bool_And = {
  arguments: Segment_Select_Column_Segment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Segment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Segment_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Segment_Select_Column_Segment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Segment_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Segment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Segment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "segment" */
export type Segment_Aggregate_Fields = {
  __typename?: 'segment_aggregate_fields';
  avg?: Maybe<Segment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Segment_Max_Fields>;
  min?: Maybe<Segment_Min_Fields>;
  stddev?: Maybe<Segment_Stddev_Fields>;
  stddev_pop?: Maybe<Segment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Segment_Stddev_Samp_Fields>;
  sum?: Maybe<Segment_Sum_Fields>;
  var_pop?: Maybe<Segment_Var_Pop_Fields>;
  var_samp?: Maybe<Segment_Var_Samp_Fields>;
  variance?: Maybe<Segment_Variance_Fields>;
};


/** aggregate fields of "segment" */
export type Segment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "segment" */
export type Segment_Aggregate_Order_By = {
  avg?: InputMaybe<Segment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Segment_Max_Order_By>;
  min?: InputMaybe<Segment_Min_Order_By>;
  stddev?: InputMaybe<Segment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Segment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Segment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Segment_Sum_Order_By>;
  var_pop?: InputMaybe<Segment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Segment_Var_Samp_Order_By>;
  variance?: InputMaybe<Segment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Segment_Append_Input = {
  allowed_accounts?: InputMaybe<Scalars['jsonb']>;
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  path?: InputMaybe<Scalars['jsonb']>;
  source_bq_tables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "segment" */
export type Segment_Arr_Rel_Insert_Input = {
  data: Array<Segment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Segment_On_Conflict>;
};

/** aggregate avg on columns */
export type Segment_Avg_Fields = {
  __typename?: 'segment_avg_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "segment" */
export type Segment_Avg_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "segment". All fields are combined with a logical 'AND'. */
export type Segment_Bool_Exp = {
  _and?: InputMaybe<Array<Segment_Bool_Exp>>;
  _not?: InputMaybe<Segment_Bool_Exp>;
  _or?: InputMaybe<Array<Segment_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  allowed_accounts?: InputMaybe<Jsonb_Comparison_Exp>;
  allowed_countries?: InputMaybe<Jsonb_Comparison_Exp>;
  bq_location?: InputMaybe<String_Comparison_Exp>;
  category?: InputMaybe<String_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  gcs_file_path?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_archived?: InputMaybe<Boolean_Comparison_Exp>;
  is_leaf?: InputMaybe<Boolean_Comparison_Exp>;
  is_nav_only?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_segment?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<Jsonb_Comparison_Exp>;
  propensity?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<Provider_Bool_Exp>;
  provider_id?: InputMaybe<String_Comparison_Exp>;
  reach?: InputMaybe<Int_Comparison_Exp>;
  seed_segment?: InputMaybe<Uuid_Comparison_Exp>;
  segment_audiences?: InputMaybe<Audience_Segment_Bool_Exp>;
  segment_audiences_aggregate?: InputMaybe<Audience_Segment_Aggregate_Bool_Exp>;
  segment_category?: InputMaybe<String_Comparison_Exp>;
  segment_group_id?: InputMaybe<String_Comparison_Exp>;
  segment_source?: InputMaybe<String_Comparison_Exp>;
  segment_source_description?: InputMaybe<String_Comparison_Exp>;
  segment_sub_category?: InputMaybe<String_Comparison_Exp>;
  segment_type?: InputMaybe<String_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  source_bq_tables?: InputMaybe<Jsonb_Comparison_Exp>;
  source_matched_count?: InputMaybe<Bigint_Comparison_Exp>;
  source_provided_count?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  sub_category?: InputMaybe<String_Comparison_Exp>;
  sub_type?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "segment" */
export enum Segment_Constraint {
  /** unique or primary key constraint on columns "external_id", "account_id" */
  AccountIdExternalId = 'account_id__external_id',
  /** unique or primary key constraint on columns "name", "account_id" */
  SegmentAccountIdNameKey = 'segment_account_id_name_key',
  /** unique or primary key constraint on columns "id" */
  SegmentPkey = 'segment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Segment_Delete_At_Path_Input = {
  allowed_accounts?: InputMaybe<Array<Scalars['String']>>;
  allowed_countries?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
  path?: InputMaybe<Array<Scalars['String']>>;
  source_bq_tables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Segment_Delete_Elem_Input = {
  allowed_accounts?: InputMaybe<Scalars['Int']>;
  allowed_countries?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['Int']>;
  source_bq_tables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Segment_Delete_Key_Input = {
  allowed_accounts?: InputMaybe<Scalars['String']>;
  allowed_countries?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  source_bq_tables?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "segment" */
export type Segment_Inc_Input = {
  reach?: InputMaybe<Scalars['Int']>;
  source_matched_count?: InputMaybe<Scalars['bigint']>;
  source_provided_count?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "segment" */
export type Segment_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  allowed_accounts?: InputMaybe<Scalars['jsonb']>;
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  bq_location?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gcs_file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  is_leaf?: InputMaybe<Scalars['Boolean']>;
  is_nav_only?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  parent_segment?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['jsonb']>;
  propensity?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Provider_Obj_Rel_Insert_Input>;
  provider_id?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['Int']>;
  seed_segment?: InputMaybe<Scalars['uuid']>;
  segment_audiences?: InputMaybe<Audience_Segment_Arr_Rel_Insert_Input>;
  segment_category?: InputMaybe<Scalars['String']>;
  segment_group_id?: InputMaybe<Scalars['String']>;
  segment_source?: InputMaybe<Scalars['String']>;
  segment_source_description?: InputMaybe<Scalars['String']>;
  segment_sub_category?: InputMaybe<Scalars['String']>;
  segment_type?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_bq_tables?: InputMaybe<Scalars['jsonb']>;
  source_matched_count?: InputMaybe<Scalars['bigint']>;
  source_provided_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_category?: InputMaybe<Scalars['String']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Segment_Max_Fields = {
  __typename?: 'segment_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bq_location?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gcs_file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_segment?: Maybe<Scalars['uuid']>;
  propensity?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['Int']>;
  seed_segment?: Maybe<Scalars['uuid']>;
  segment_category?: Maybe<Scalars['String']>;
  segment_group_id?: Maybe<Scalars['String']>;
  segment_source?: Maybe<Scalars['String']>;
  segment_source_description?: Maybe<Scalars['String']>;
  segment_sub_category?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_matched_count?: Maybe<Scalars['bigint']>;
  source_provided_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_category?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "segment" */
export type Segment_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  bq_location?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gcs_file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_segment?: InputMaybe<Order_By>;
  propensity?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  seed_segment?: InputMaybe<Order_By>;
  segment_category?: InputMaybe<Order_By>;
  segment_group_id?: InputMaybe<Order_By>;
  segment_source?: InputMaybe<Order_By>;
  segment_source_description?: InputMaybe<Order_By>;
  segment_sub_category?: InputMaybe<Order_By>;
  segment_type?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Segment_Min_Fields = {
  __typename?: 'segment_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  bq_location?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  gcs_file_path?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_segment?: Maybe<Scalars['uuid']>;
  propensity?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  reach?: Maybe<Scalars['Int']>;
  seed_segment?: Maybe<Scalars['uuid']>;
  segment_category?: Maybe<Scalars['String']>;
  segment_group_id?: Maybe<Scalars['String']>;
  segment_source?: Maybe<Scalars['String']>;
  segment_source_description?: Maybe<Scalars['String']>;
  segment_sub_category?: Maybe<Scalars['String']>;
  segment_type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  source_matched_count?: Maybe<Scalars['bigint']>;
  source_provided_count?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['String']>;
  sub_category?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "segment" */
export type Segment_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  bq_location?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gcs_file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_segment?: InputMaybe<Order_By>;
  propensity?: InputMaybe<Order_By>;
  provider_id?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  seed_segment?: InputMaybe<Order_By>;
  segment_category?: InputMaybe<Order_By>;
  segment_group_id?: InputMaybe<Order_By>;
  segment_source?: InputMaybe<Order_By>;
  segment_source_description?: InputMaybe<Order_By>;
  segment_sub_category?: InputMaybe<Order_By>;
  segment_type?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "segment" */
export type Segment_Mutation_Response = {
  __typename?: 'segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Segment>;
};

/** input type for inserting object relation for remote table "segment" */
export type Segment_Obj_Rel_Insert_Input = {
  data: Segment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Segment_On_Conflict>;
};

/** on_conflict condition type for table "segment" */
export type Segment_On_Conflict = {
  constraint: Segment_Constraint;
  update_columns?: Array<Segment_Update_Column>;
  where?: InputMaybe<Segment_Bool_Exp>;
};

/** Ordering options when selecting data from "segment". */
export type Segment_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  allowed_accounts?: InputMaybe<Order_By>;
  allowed_countries?: InputMaybe<Order_By>;
  bq_location?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  gcs_file_path?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_archived?: InputMaybe<Order_By>;
  is_leaf?: InputMaybe<Order_By>;
  is_nav_only?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_segment?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  propensity?: InputMaybe<Order_By>;
  provider?: InputMaybe<Provider_Order_By>;
  provider_id?: InputMaybe<Order_By>;
  reach?: InputMaybe<Order_By>;
  seed_segment?: InputMaybe<Order_By>;
  segment_audiences_aggregate?: InputMaybe<Audience_Segment_Aggregate_Order_By>;
  segment_category?: InputMaybe<Order_By>;
  segment_group_id?: InputMaybe<Order_By>;
  segment_source?: InputMaybe<Order_By>;
  segment_source_description?: InputMaybe<Order_By>;
  segment_sub_category?: InputMaybe<Order_By>;
  segment_type?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  source_bq_tables?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  sub_category?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: segment */
export type Segment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Segment_Prepend_Input = {
  allowed_accounts?: InputMaybe<Scalars['jsonb']>;
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  path?: InputMaybe<Scalars['jsonb']>;
  source_bq_tables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "segment" */
export enum Segment_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowedAccounts = 'allowed_accounts',
  /** column name */
  AllowedCountries = 'allowed_countries',
  /** column name */
  BqLocation = 'bq_location',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GcsFilePath = 'gcs_file_path',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsLeaf = 'is_leaf',
  /** column name */
  IsNavOnly = 'is_nav_only',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ParentSegment = 'parent_segment',
  /** column name */
  Path = 'path',
  /** column name */
  Propensity = 'propensity',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Reach = 'reach',
  /** column name */
  SeedSegment = 'seed_segment',
  /** column name */
  SegmentCategory = 'segment_category',
  /** column name */
  SegmentGroupId = 'segment_group_id',
  /** column name */
  SegmentSource = 'segment_source',
  /** column name */
  SegmentSourceDescription = 'segment_source_description',
  /** column name */
  SegmentSubCategory = 'segment_sub_category',
  /** column name */
  SegmentType = 'segment_type',
  /** column name */
  Source = 'source',
  /** column name */
  SourceBqTables = 'source_bq_tables',
  /** column name */
  SourceMatchedCount = 'source_matched_count',
  /** column name */
  SourceProvidedCount = 'source_provided_count',
  /** column name */
  Status = 'status',
  /** column name */
  SubCategory = 'sub_category',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date'
}

/** select "segment_aggregate_bool_exp_bool_and_arguments_columns" columns of table "segment" */
export enum Segment_Select_Column_Segment_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsLeaf = 'is_leaf',
  /** column name */
  IsNavOnly = 'is_nav_only'
}

/** select "segment_aggregate_bool_exp_bool_or_arguments_columns" columns of table "segment" */
export enum Segment_Select_Column_Segment_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsLeaf = 'is_leaf',
  /** column name */
  IsNavOnly = 'is_nav_only'
}

/** input type for updating data in table "segment" */
export type Segment_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  allowed_accounts?: InputMaybe<Scalars['jsonb']>;
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  bq_location?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gcs_file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  is_leaf?: InputMaybe<Scalars['Boolean']>;
  is_nav_only?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  parent_segment?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['jsonb']>;
  propensity?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['Int']>;
  seed_segment?: InputMaybe<Scalars['uuid']>;
  segment_category?: InputMaybe<Scalars['String']>;
  segment_group_id?: InputMaybe<Scalars['String']>;
  segment_source?: InputMaybe<Scalars['String']>;
  segment_source_description?: InputMaybe<Scalars['String']>;
  segment_sub_category?: InputMaybe<Scalars['String']>;
  segment_type?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_bq_tables?: InputMaybe<Scalars['jsonb']>;
  source_matched_count?: InputMaybe<Scalars['bigint']>;
  source_provided_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_category?: InputMaybe<Scalars['String']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Segment_Stddev_Fields = {
  __typename?: 'segment_stddev_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "segment" */
export type Segment_Stddev_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Segment_Stddev_Pop_Fields = {
  __typename?: 'segment_stddev_pop_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "segment" */
export type Segment_Stddev_Pop_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Segment_Stddev_Samp_Fields = {
  __typename?: 'segment_stddev_samp_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "segment" */
export type Segment_Stddev_Samp_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "segment" */
export type Segment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Segment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Segment_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  allowed_accounts?: InputMaybe<Scalars['jsonb']>;
  allowed_countries?: InputMaybe<Scalars['jsonb']>;
  bq_location?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['timestamptz']>;
  external_id?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  gcs_file_path?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_archived?: InputMaybe<Scalars['Boolean']>;
  is_leaf?: InputMaybe<Scalars['Boolean']>;
  is_nav_only?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  parent_segment?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['jsonb']>;
  propensity?: InputMaybe<Scalars['String']>;
  provider_id?: InputMaybe<Scalars['String']>;
  reach?: InputMaybe<Scalars['Int']>;
  seed_segment?: InputMaybe<Scalars['uuid']>;
  segment_category?: InputMaybe<Scalars['String']>;
  segment_group_id?: InputMaybe<Scalars['String']>;
  segment_source?: InputMaybe<Scalars['String']>;
  segment_source_description?: InputMaybe<Scalars['String']>;
  segment_sub_category?: InputMaybe<Scalars['String']>;
  segment_type?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  source_bq_tables?: InputMaybe<Scalars['jsonb']>;
  source_matched_count?: InputMaybe<Scalars['bigint']>;
  source_provided_count?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Scalars['String']>;
  sub_category?: InputMaybe<Scalars['String']>;
  sub_type?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Segment_Sum_Fields = {
  __typename?: 'segment_sum_fields';
  reach?: Maybe<Scalars['Int']>;
  source_matched_count?: Maybe<Scalars['bigint']>;
  source_provided_count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "segment" */
export type Segment_Sum_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** update columns of table "segment" */
export enum Segment_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowedAccounts = 'allowed_accounts',
  /** column name */
  AllowedCountries = 'allowed_countries',
  /** column name */
  BqLocation = 'bq_location',
  /** column name */
  Category = 'category',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Description = 'description',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  FullName = 'full_name',
  /** column name */
  GcsFilePath = 'gcs_file_path',
  /** column name */
  Id = 'id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsLeaf = 'is_leaf',
  /** column name */
  IsNavOnly = 'is_nav_only',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ParentSegment = 'parent_segment',
  /** column name */
  Path = 'path',
  /** column name */
  Propensity = 'propensity',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Reach = 'reach',
  /** column name */
  SeedSegment = 'seed_segment',
  /** column name */
  SegmentCategory = 'segment_category',
  /** column name */
  SegmentGroupId = 'segment_group_id',
  /** column name */
  SegmentSource = 'segment_source',
  /** column name */
  SegmentSourceDescription = 'segment_source_description',
  /** column name */
  SegmentSubCategory = 'segment_sub_category',
  /** column name */
  SegmentType = 'segment_type',
  /** column name */
  Source = 'source',
  /** column name */
  SourceBqTables = 'source_bq_tables',
  /** column name */
  SourceMatchedCount = 'source_matched_count',
  /** column name */
  SourceProvidedCount = 'source_provided_count',
  /** column name */
  Status = 'status',
  /** column name */
  SubCategory = 'sub_category',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedDate = 'updated_date'
}

export type Segment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Segment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Segment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Segment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Segment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Segment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Segment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Segment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Segment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Segment_Var_Pop_Fields = {
  __typename?: 'segment_var_pop_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "segment" */
export type Segment_Var_Pop_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Segment_Var_Samp_Fields = {
  __typename?: 'segment_var_samp_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "segment" */
export type Segment_Var_Samp_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Segment_Variance_Fields = {
  __typename?: 'segment_variance_fields';
  reach?: Maybe<Scalars['Float']>;
  source_matched_count?: Maybe<Scalars['Float']>;
  source_provided_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "segment" */
export type Segment_Variance_Order_By = {
  reach?: InputMaybe<Order_By>;
  source_matched_count?: InputMaybe<Order_By>;
  source_provided_count?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type User = {
  __typename?: 'user';
  /** An object relationship */
  account: Account;
  account_id: Scalars['uuid'];
  /** An array relationship */
  audiences: Array<Audience>;
  /** An aggregate relationship */
  audiences_aggregate: Audience_Aggregate;
  created_date: Scalars['timestamptz'];
  /** An array relationship */
  deals: Array<Deal>;
  /** An aggregate relationship */
  deals_aggregate: Deal_Aggregate;
  email_address: Scalars['String'];
  /** When the user (or in more cases the service account) is set to expire */
  expires_at?: Maybe<Scalars['String']>;
  feature_flags?: Maybe<Scalars['jsonb']>;
  firebase_id: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  /** Whether the user is active (eg, not disabled) */
  is_active: Scalars['Boolean'];
  is_service_account: Scalars['Boolean'];
  last_name: Scalars['String'];
  /** Last time the user signed on */
  last_signin_time?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  terms_and_conditions_acceptance_date?: Maybe<Scalars['timestamptz']>;
  terms_and_conditions_accepted: Scalars['Boolean'];
  updated_date?: Maybe<Scalars['timestamptz']>;
};


/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type UserAudiencesArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type UserAudiences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audience_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audience_Order_By>>;
  where?: InputMaybe<Audience_Bool_Exp>;
};


/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type UserDealsArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type UserDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deal_Order_By>>;
  where?: InputMaybe<Deal_Bool_Exp>;
};


/** OpenAudience users (1:1 to Firebase Auth / CICP) */
export type UserFeature_FlagsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

export type User_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Aggregate_Bool_Exp_Count>;
};

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Max_Order_By>;
  min?: InputMaybe<User_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  account?: InputMaybe<Account_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  audiences?: InputMaybe<Audience_Bool_Exp>;
  audiences_aggregate?: InputMaybe<Audience_Aggregate_Bool_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deals?: InputMaybe<Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Bool_Exp>;
  email_address?: InputMaybe<String_Comparison_Exp>;
  feature_flags?: InputMaybe<Jsonb_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_service_account?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  terms_and_conditions_acceptance_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  terms_and_conditions_accepted?: InputMaybe<Boolean_Comparison_Exp>;
  updated_date?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email_address" */
  UserEmailAddressKey = 'user_email_address_key',
  /** unique or primary key constraint on columns "firebase_id" */
  UserFirebaseIdKey = 'user_firebase_id_key',
  /** unique or primary key constraint on columns "id" */
  UserIdKey = 'user_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  feature_flags?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  feature_flags?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  feature_flags?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  account?: InputMaybe<Account_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  audiences?: InputMaybe<Audience_Arr_Rel_Insert_Input>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  deals?: InputMaybe<Deal_Arr_Rel_Insert_Input>;
  email_address?: InputMaybe<Scalars['String']>;
  feature_flags?: InputMaybe<Scalars['jsonb']>;
  firebase_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_service_account?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  terms_and_conditions_acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  terms_and_conditions_accepted?: InputMaybe<Scalars['Boolean']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  terms_and_conditions_acceptance_date?: Maybe<Scalars['timestamptz']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  terms_and_conditions_acceptance_date?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  email_address?: Maybe<Scalars['String']>;
  firebase_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  terms_and_conditions_acceptance_date?: Maybe<Scalars['timestamptz']>;
  updated_date?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  email_address?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  terms_and_conditions_acceptance_date?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  account?: InputMaybe<Account_Order_By>;
  account_id?: InputMaybe<Order_By>;
  audiences_aggregate?: InputMaybe<Audience_Aggregate_Order_By>;
  created_date?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Deal_Aggregate_Order_By>;
  email_address?: InputMaybe<Order_By>;
  feature_flags?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_service_account?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  terms_and_conditions_acceptance_date?: InputMaybe<Order_By>;
  terms_and_conditions_accepted?: InputMaybe<Order_By>;
  updated_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  feature_flags?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  TermsAndConditionsAcceptanceDate = 'terms_and_conditions_acceptance_date',
  /** column name */
  TermsAndConditionsAccepted = 'terms_and_conditions_accepted',
  /** column name */
  UpdatedDate = 'updated_date'
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  TermsAndConditionsAccepted = 'terms_and_conditions_accepted'
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  TermsAndConditionsAccepted = 'terms_and_conditions_accepted'
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  feature_flags?: InputMaybe<Scalars['jsonb']>;
  firebase_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_service_account?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  terms_and_conditions_acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  terms_and_conditions_accepted?: InputMaybe<Scalars['Boolean']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_date?: InputMaybe<Scalars['timestamptz']>;
  email_address?: InputMaybe<Scalars['String']>;
  feature_flags?: InputMaybe<Scalars['jsonb']>;
  firebase_id?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_service_account?: InputMaybe<Scalars['Boolean']>;
  last_name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  terms_and_conditions_acceptance_date?: InputMaybe<Scalars['timestamptz']>;
  terms_and_conditions_accepted?: InputMaybe<Scalars['Boolean']>;
  updated_date?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  EmailAddress = 'email_address',
  /** column name */
  FeatureFlags = 'feature_flags',
  /** column name */
  FirebaseId = 'firebase_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsServiceAccount = 'is_service_account',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  TermsAndConditionsAcceptanceDate = 'terms_and_conditions_acceptance_date',
  /** column name */
  TermsAndConditionsAccepted = 'terms_and_conditions_accepted',
  /** column name */
  UpdatedDate = 'updated_date'
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type AudienceFragmentFragment = { __typename?: 'audience', id: any, name: string, addressable_reach?: number | null, status: string, created_date: any, updated_date: any, direct_audience_provider: string, export_type: string, estimated_expiry_date?: any | null, last_export?: { __typename?: 'export', status: string } | null, audience_segments: Array<{ __typename?: 'audience_segment', segment: { __typename?: 'segment', name: string, expiration_date?: any | null, is_archived?: boolean | null, status?: string | null } }>, account?: { __typename?: 'account', name: string, uid?: any | null } | null };

export type ListingAudienceFragment = { __typename?: 'audience', id: any, account_id?: any | null, addressable_reach?: number | null, created_date: any, name: string, reach?: number | null, region: string, status: string, type: string, updated_date: any, is_archived: boolean, export_type: string, direct_audience_provider: string, user_id?: any | null, account?: { __typename?: 'account', name: string } | null };

export type TreeSegmentFragment = { __typename?: 'segment', account_id: any, id: any, name: string, description: string, full_name?: string | null, reach?: number | null, is_leaf?: boolean | null, is_archived?: boolean | null, is_nav_only?: boolean | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, external_id?: string | null, parent_segment?: any | null, bq_location?: string | null, source_bq_tables: any, propensity?: string | null, segment_group_id?: string | null, status?: string | null, expiration_date?: any | null };

export type ListingOrganizationFragment = { __typename?: 'account', name: string, description?: string | null, resource_id: string, parent_account_id?: any | null, allowed_countries: any, allowed_domains?: any | null, exchange_account_partner_id?: string | null, default_platform_share?: string | null, max_share?: string | null, max_cpm_cap?: string | null };

export type SegmentFragment = { __typename?: 'segment', id: any, name: string, reach?: number | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, status?: string | null, created_date?: any | null, updated_date?: any | null, expiration_date?: any | null, account: { __typename?: 'account', name: string }, provider?: { __typename?: 'provider', name: string } | null };

export type TaxonomyTreeSegmentFragment = { __typename?: 'segment', id: any, name: string, description: string, is_leaf?: boolean | null, is_archived?: boolean | null, is_nav_only?: boolean | null, segment_type?: string | null, segment_category?: string | null, segment_sub_category?: string | null, parent_segment?: any | null, path?: any | null, segment_source?: string | null, segment_source_description?: string | null };

export type ActivateAudienceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type ActivateAudienceMutation = { __typename?: 'Mutation', activateAudienceV2?: any | null };

export type ArchiveAudienceMutationVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type ArchiveAudienceMutation = { __typename?: 'Mutation', audience?: { __typename?: 'audience_mutation_response', returning: Array<{ __typename?: 'audience', id: any, is_archived: boolean }> } | null };

export type CreateAudienceMutationVariables = Exact<{
  object: Audience_Insert_Input;
}>;


export type CreateAudienceMutation = { __typename?: 'Mutation', insert_audience_one?: { __typename?: 'audience', id: any, account_id?: any | null, addressable_reach?: number | null, created_date: any, name: string, reach?: number | null, region: string, status: string, type: string, updated_date: any, is_archived: boolean, export_type: string, direct_audience_provider: string, user_id?: any | null, account?: { __typename?: 'account', name: string } | null } | null };

export type CreateAudienceSharesMutationVariables = Exact<{
  input: Array<AudienceShareCreateParams> | AudienceShareCreateParams;
}>;


export type CreateAudienceSharesMutation = { __typename?: 'Mutation', audienceShareCreate: Array<{ __typename?: 'AudienceShare', id?: string | null, uid?: string | null, cpm_cap?: string | null, revenue_method?: string | null, third_party_revenue?: string | null, recipient_account_id?: string | null, audience_external_openaudience_id?: string | null } | { __typename?: 'Error', message: string } | null> };

export type DeleteAudienceSharesMutationVariables = Exact<{
  input: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type DeleteAudienceSharesMutation = { __typename?: 'Mutation', audienceShareDelete: Array<{ __typename?: 'AudienceShareDeleted', deletedUID: any } | { __typename?: 'Error', message: string }> };

export type DuplicateAudienceMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  user_id: Scalars['uuid'];
}>;


export type DuplicateAudienceMutation = { __typename?: 'Mutation', cloneAudience?: { __typename?: 'audience', id: any, account_id?: any | null } | null };

export type UpdateAudienceMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Audience_Set_Input;
}>;


export type UpdateAudienceMutation = { __typename?: 'Mutation', audience?: { __typename?: 'audience_mutation_response', returning: Array<{ __typename?: 'audience', id: any, status: string }> } | null };

export type UpdateAudienceSharesMutationVariables = Exact<{
  input: Array<AudienceShareUpdateParams> | AudienceShareUpdateParams;
}>;


export type UpdateAudienceSharesMutation = { __typename?: 'Mutation', audienceShareUpdate: Array<{ __typename?: 'AudienceShare', id?: string | null, uid?: string | null, recipient_account_id?: string | null, third_party_revenue?: string | null } | { __typename?: 'Error', message: string } | null> };

export type UpdatePublisherReportStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UpdatePublisherReportStatusMutation = { __typename?: 'Mutation', generate_reach_report_publisher?: { __typename?: 'AudienceReportStatusResponse', id: any, report_status?: string | null } | null };

export type CreateLogMutationVariables = Exact<{
  msg?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;


export type CreateLogMutation = { __typename?: 'Mutation', createLog?: { __typename?: 'logResponse', status?: string | null, msg?: string | null } | null };

export type AddAccountFeatureFlagsMutationVariables = Exact<{
  objects: Array<Account_Feature_Flags_Insert_Input> | Account_Feature_Flags_Insert_Input;
}>;


export type AddAccountFeatureFlagsMutation = { __typename?: 'Mutation', insert_account_feature_flags?: { __typename?: 'account_feature_flags_mutation_response', affected_rows: number } | null };

export type CreateOrganizationMutationVariables = Exact<{
  object: Account_Insert_Input;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', insert_account_one?: { __typename?: 'account', name: string, description?: string | null, resource_id: string, parent_account_id?: any | null, allowed_countries: any, allowed_domains?: any | null, exchange_account_partner_id?: string | null, default_platform_share?: string | null, max_share?: string | null, max_cpm_cap?: string | null } | null };

export type DeleteFeatureFlagsMutationVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type DeleteFeatureFlagsMutation = { __typename?: 'Mutation', delete_account_feature_flags?: { __typename?: 'account_feature_flags_mutation_response', affected_rows: number } | null };

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['uuid'];
  data: Account_Set_Input;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', account?: { __typename?: 'account', id: any, description?: string | null, resource_id: string, parent_account_id?: any | null, allowed_countries: any, allowed_domains?: any | null, exchange_account_partner_id?: string | null, default_platform_share?: string | null, max_share?: string | null, max_cpm_cap?: string | null } | null };

export type CreateProviderMutationVariables = Exact<{
  object: Provider_Insert_Input;
}>;


export type CreateProviderMutation = { __typename?: 'Mutation', insert_provider_one?: { __typename?: 'provider', id: string, name: string, allowed_export_types: any } | null };

export type DeleteProviderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteProviderMutation = { __typename?: 'Mutation', delete_provider_by_pk?: { __typename?: 'provider', id: string } | null };

export type EditProviderMutationVariables = Exact<{
  id: Scalars['String'];
  data: Provider_Set_Input;
}>;


export type EditProviderMutation = { __typename?: 'Mutation', provider?: { __typename?: 'provider_mutation_response', returning: Array<{ __typename?: 'provider', id: string, name: string, allowed_export_types: any }> } | null };

export type LinkOrganizationToProviderMutationVariables = Exact<{
  objects: Array<Account_Provider_Insert_Input> | Account_Provider_Insert_Input;
}>;


export type LinkOrganizationToProviderMutation = { __typename?: 'Mutation', insert_account_provider?: { __typename?: 'account_provider_mutation_response', returning: Array<{ __typename?: 'account_provider', account_id: any, provider_id: string, id: any }> } | null };

export type UnlinkOrganizationFromProviderMutationVariables = Exact<{
  account_id: Scalars['uuid'];
  provider_id: Scalars['String'];
}>;


export type UnlinkOrganizationFromProviderMutation = { __typename?: 'Mutation', delete_account_provider?: { __typename?: 'account_provider_mutation_response', returning: Array<{ __typename?: 'account_provider', account_id: any, id: any, provider_id: string }> } | null };

export type AddUsersToSegmentMutationVariables = Exact<{
  segmentId: Scalars['uuid'];
  data: UserDataInput;
}>;


export type AddUsersToSegmentMutation = { __typename?: 'Mutation', res: { __typename?: 'ModifySegmentResponse', ok?: boolean | null } };

export type CreateSegmentMutationVariables = Exact<{
  data: Segment_Insert_Input;
}>;


export type CreateSegmentMutation = { __typename?: 'Mutation', segment?: { __typename?: 'segment_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'segment', id: any, name: string, reach?: number | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, status?: string | null, created_date?: any | null, updated_date?: any | null, expiration_date?: any | null, account: { __typename?: 'account', name: string }, provider?: { __typename?: 'provider', name: string } | null }> } | null };

export type UpdateSegmentMutationVariables = Exact<{
  where: Segment_Bool_Exp;
  set: Segment_Set_Input;
}>;


export type UpdateSegmentMutation = { __typename?: 'Mutation', segment?: { __typename?: 'segment_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'segment', id: any, name: string, reach?: number | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, status?: string | null, created_date?: any | null, updated_date?: any | null, expiration_date?: any | null, account: { __typename?: 'account', name: string }, provider?: { __typename?: 'provider', name: string } | null }> } | null };

export type CreateApiKeyMutationVariables = Exact<{
  expiresIn: Scalars['Duration'];
  name: Scalars['String'];
  parent_account_id: Scalars['String'];
}>;


export type CreateApiKeyMutation = { __typename?: 'Mutation', createServiceAccountUnderParentAccount?: any | null };

export type CreateUserUnderParentAccountMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email_address: Scalars['String'];
  parent_account_id: Scalars['String'];
}>;


export type CreateUserUnderParentAccountMutation = { __typename?: 'Mutation', createUserUnderParentAccount?: any | null };

export type CreateUserUnderRootAccountMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email_address: Scalars['String'];
}>;


export type CreateUserUnderRootAccountMutation = { __typename?: 'Mutation', createUserUnderRootAccount?: any | null };

export type SetTermsAndConditionsAcceptedMutationVariables = Exact<{
  id: Scalars['uuid'];
  accepted: Scalars['Boolean'];
  date: Scalars['timestamptz'];
}>;


export type SetTermsAndConditionsAcceptedMutation = { __typename?: 'Mutation', update_user_by_pk?: { __typename?: 'user', terms_and_conditions_accepted: boolean, terms_and_conditions_acceptance_date?: any | null } | null };

export type CheckUserPermissionsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type CheckUserPermissionsQuery = { __typename?: 'Query', checkUserPermissions?: { __typename?: 'CheckUserPermissionsResponse', forceRefreshToken?: boolean | null } | null };

export type SessionQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionQuery = { __typename?: 'Query', getSession?: any | null };

export type GetAssociatedDealsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAssociatedDealsQuery = { __typename?: 'Query', deal: Array<{ __typename?: 'deal', id: any, name?: string | null, exchange_deal_id?: string | null, exchange_id?: string | null, status: string, is_archived?: boolean | null, _package?: { __typename?: 'hasura_package', audience_id?: any | null } | null }> };

export type GetAudienceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetAudienceQuery = { __typename?: 'Query', audience_by_pk?: { __typename?: 'audience', id: any, is_archived: boolean, account_id?: any | null, name: string, status: string, description?: string | null, updated_date: any, export_type: string, report_status?: string | null, export_targets?: any | null, direct_audience_provider: string, normalized_definition?: any | null, estimated_expiry_date?: any | null, error_code?: string | null, account?: { __typename?: 'account', name: string } | null, audience_segments: Array<{ __typename?: 'audience_segment', segment: { __typename?: 'segment', id: any, name: string, status?: string | null, expiration_date?: any | null, account_id: any, description: string, external_id?: string | null, full_name?: string | null, is_archived?: boolean | null, is_leaf?: boolean | null, is_nav_only?: boolean | null, parent_segment?: any | null, propensity?: string | null, reach?: number | null, segment_group_id?: string | null, bq_location?: string | null, source_bq_tables: any, sub_type?: string | null, type?: string | null, category?: string | null, sub_category?: string | null, provider?: { __typename?: 'provider', name: string } | null } }>, audience_share: Array<{ __typename?: 'AudienceShare', id?: string | null, uid?: string | null, recipient_account_id?: string | null, recipient_account_name?: string | null, third_party_revenue?: string | null, revenue_method?: string | null, cpm_cap?: string | null } | null> } | null };

export type GetAudienceAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAudienceAccountsQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', id: any, name: string }> };

export type GetAudienceCategoriesReachQueryVariables = Exact<{
  id: Scalars['String'];
  sfAccountName?: InputMaybe<Scalars['String']>;
}>;


export type GetAudienceCategoriesReachQuery = { __typename?: 'Query', audience_categories_reach_all?: Array<{ __typename?: 'Audience_Categories_Reach_All', sfAccountName?: string | null, sfAccountId?: string | null, cookiesWithRequests?: any | null, total?: any | null, display?: any | null, video?: any | null, mobileapp?: any | null, mobileweb?: any | null, desktop?: any | null, connectedtv?: any | null, tablet?: any | null, mobileDevicesWithRequests?: any | null, totalmau?: any | null, idlXyWithRequests?: any | null, idlXiWithRequests?: any | null, uniqueUsersViaConnectedTVWithRequests?: any | null, uniqueConnectedTVDeviceIDsWithRequests?: any | null, ip?: any | null, totalmaip?: any | null, totalmaid5?: any | null } | null> | null, audience_reach: { __typename?: 'audience_reach', Mobile_Devices_With_Requests?: any | null, Tablet_Devices_With_Requests?: any | null, Unique_ConnectedTVDeviceIDs_With_Requests?: any | null, Cookies_With_Requests?: any | null } };

export type GetAudienceFeedQueryVariables = Exact<{
  where?: InputMaybe<Audience_Bool_Exp>;
  order_by?: InputMaybe<Array<Audience_Order_By> | Audience_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAudienceFeedQuery = { __typename?: 'Query', audienceFeed: Array<{ __typename?: 'audience', id: any, name: string, addressable_reach?: number | null, status: string, created_date: any, updated_date: any, direct_audience_provider: string, export_type: string, estimated_expiry_date?: any | null, last_export?: { __typename?: 'export', status: string } | null, audience_segments: Array<{ __typename?: 'audience_segment', segment: { __typename?: 'segment', name: string, expiration_date?: any | null, is_archived?: boolean | null, status?: string | null } }>, account?: { __typename?: 'account', name: string, uid?: any | null } | null }> };

export type GetAudienceSharesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAudienceSharesQuery = { __typename?: 'Query', audienceSharesByAudience: Array<{ __typename?: 'AudienceShare', id?: string | null, uid?: string | null, recipient_account_id?: string | null, recipient_account_name?: string | null, third_party_revenue?: string | null, revenue_method?: string | null, cpm_cap?: string | null } | null> };

export type GetAudienceTemplatesQueryVariables = Exact<{
  status?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
}>;


export type GetAudienceTemplatesQuery = { __typename?: 'Query', audience: Array<{ __typename?: 'audience', id: any, name: string }> };

export type GetAudiencesListQueryVariables = Exact<{
  where?: InputMaybe<Audience_Bool_Exp>;
  order_by?: InputMaybe<Array<Audience_Order_By> | Audience_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetAudiencesListQuery = { __typename?: 'Query', audience: Array<{ __typename?: 'audience', id: any, name: string, addressable_reach?: number | null, status: string, created_date: any, updated_date: any, direct_audience_provider: string, export_type: string, estimated_expiry_date?: any | null, last_export?: { __typename?: 'export', status: string } | null, audience_segments: Array<{ __typename?: 'audience_segment', segment: { __typename?: 'segment', name: string, expiration_date?: any | null, is_archived?: boolean | null, status?: string | null } }>, account?: { __typename?: 'account', name: string, uid?: any | null } | null }> };

export type GetAudiencesTotalQueryVariables = Exact<{
  where?: InputMaybe<Audience_Bool_Exp>;
}>;


export type GetAudiencesTotalQuery = { __typename?: 'Query', audience_aggregate: { __typename?: 'audience_aggregate', aggregate?: { __typename?: 'audience_aggregate_fields', count: number } | null } };

export type GetBulkShareAudiencesListQueryVariables = Exact<{
  ids: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetBulkShareAudiencesListQuery = { __typename?: 'Query', audience: Array<{ __typename?: 'audience', id: any, name: string, audience_share: Array<{ __typename?: 'AudienceShare', id?: string | null, uid?: string | null, recipient_account_id?: string | null, recipient_account_name?: string | null, third_party_revenue?: string | null, cpm_cap?: string | null, revenue_method?: string | null } | null> }> };

export type GetDistinctAudienceStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDistinctAudienceStatusesQuery = { __typename?: 'Query', audience_statuses: Array<{ __typename?: 'audience', status: string }> };

export type GetEnrichEstimateQueryVariables = Exact<{
  normalized_definition: Scalars['JSON'];
}>;


export type GetEnrichEstimateQuery = { __typename?: 'Query', enrichEstimate?: any | null };

export type GetExportsByAudienceQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetExportsByAudienceQuery = { __typename?: 'Query', audience_by_pk?: { __typename?: 'audience', last_export?: { __typename?: 'export', export_id_types: any } | null } | null };

export type GetProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProvidersQuery = { __typename?: 'Query', provider: Array<{ __typename?: 'provider', id: string, name: string, allowed_export_types: any }> };

export type GetSegmentsQueryVariables = Exact<{
  where?: InputMaybe<Segment_Bool_Exp>;
  order_by: Array<Segment_Order_By> | Segment_Order_By;
}>;


export type GetSegmentsQuery = { __typename?: 'Query', segment: Array<{ __typename?: 'segment', account_id: any, id: any, name: string, description: string, full_name?: string | null, reach?: number | null, is_leaf?: boolean | null, is_archived?: boolean | null, is_nav_only?: boolean | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, external_id?: string | null, parent_segment?: any | null, bq_location?: string | null, source_bq_tables: any, propensity?: string | null, segment_group_id?: string | null, status?: string | null, expiration_date?: any | null }> };

export type GetSfAccountNamesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSfAccountNamesQuery = { __typename?: 'Query', sf_account_names?: Array<string | null> | null };

export type GetDealsByAudiencesIdsQueryVariables = Exact<{
  audiencesIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type GetDealsByAudiencesIdsQuery = { __typename?: 'Query', deal: Array<{ __typename?: 'deal', id: any, name?: string | null, exchange_deal_id?: string | null, status: string, _package?: { __typename?: 'hasura_package', audience_id?: any | null } | null }> };

export type OptionsByPathQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type OptionsByPathQuery = { __typename?: 'Query', optionsByPath: Array<{ __typename?: 'OptionsByPathResult', id: string, name: string, path: string } | null> };

export type GetDefaultParentOrganizationIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultParentOrganizationIdQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', id: any }> };

export type GetFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeatureFlagsQuery = { __typename?: 'Query', feature_flags: Array<{ __typename?: 'feature_flags', value: string }> };

export type GetOrgApiKeysQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type GetOrgApiKeysQuery = { __typename?: 'Query', user: Array<{ __typename?: 'user', first_name: string, expires_at?: string | null, created_date: any, id: any }> };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', account_by_pk?: { __typename?: 'account', id: any, name: string, description?: string | null, resource_id: string, exchange_account_partner_id?: string | null, default_platform_share?: string | null, max_share?: string | null, max_cpm_cap?: string | null, allowed_countries: any, allowed_domains?: any | null, parent_account?: { __typename?: 'account', id: any, name: string } | null, allowed_providers: Array<{ __typename?: 'account_provider', provider: { __typename?: 'provider', id: string, name: string, allowed_export_types: any } }>, feature_flags: Array<{ __typename?: 'account_feature_flags', value: Feature_Flags_Enum }> } | null };

export type GetOrganizationsListQueryVariables = Exact<{
  order_by?: InputMaybe<Order_By>;
}>;


export type GetOrganizationsListQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', id: any, name: string, created_date: any, allowed_countries: any, allowed_domains?: any | null, uid?: any | null, parent_account?: { __typename?: 'account', id: any, name: string } | null }> };

export type GetParentOrganizationIdQueryVariables = Exact<{
  organizationName: Scalars['String'];
}>;


export type GetParentOrganizationIdQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', parent_account_id?: any | null }> };

export type GetAccountOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountOptionsQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', id: any, name: string }> };

export type GetProviderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetProviderQuery = { __typename?: 'Query', provider_by_pk?: { __typename?: 'provider', allowed_export_types: any, id: string, name: string, allowed_accounts: Array<{ __typename?: 'account_provider', account: { __typename?: 'account', id: any, name: string } }> } | null };

export type GetProvidersListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProvidersListQuery = { __typename?: 'Query', provider: Array<{ __typename?: 'provider', id: string, name: string, allowed_accounts: Array<{ __typename?: 'account_provider', id: any }> }> };

export type GetDistinctSegmentStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDistinctSegmentStatusesQuery = { __typename?: 'Query', segment_statuses: Array<{ __typename?: 'segment', status?: string | null }> };

export type GetSegmentByPkQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetSegmentByPkQuery = { __typename?: 'Query', segment_by_pk?: { __typename?: 'segment', source_provided_count?: any | null, source_matched_count?: any | null } | null };

export type GetSegmentFeedQueryVariables = Exact<{
  where?: InputMaybe<Segment_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By> | Segment_Order_By>;
}>;


export type GetSegmentFeedQuery = { __typename?: 'Query', segmentFeed: Array<{ __typename?: 'segment', id: any, name: string, reach?: number | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, status?: string | null, created_date?: any | null, updated_date?: any | null, expiration_date?: any | null, account: { __typename?: 'account', name: string }, provider?: { __typename?: 'provider', name: string } | null }> };

export type GetSegmentsAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSegmentsAccountsQuery = { __typename?: 'Query', account: Array<{ __typename?: 'account', id: any, name: string }> };

export type GetSegmentsListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Segment_Order_By> | Segment_Order_By>;
}>;


export type GetSegmentsListQuery = { __typename?: 'Query', segment: Array<{ __typename?: 'segment', id: any, name: string, reach?: number | null, type?: string | null, sub_type?: string | null, category?: string | null, sub_category?: string | null, status?: string | null, created_date?: any | null, updated_date?: any | null, expiration_date?: any | null, account: { __typename?: 'account', name: string }, provider?: { __typename?: 'provider', name: string } | null }> };

export type GetSegmentsTotalQueryVariables = Exact<{
  where?: InputMaybe<Segment_Bool_Exp>;
}>;


export type GetSegmentsTotalQuery = { __typename?: 'Query', segment_aggregate: { __typename?: 'segment_aggregate', aggregate?: { __typename?: 'segment_aggregate_fields', count: number } | null } };

export type GetSegmentsWithLinkedAudiencesQueryVariables = Exact<{
  segmentIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type GetSegmentsWithLinkedAudiencesQuery = { __typename?: 'Query', segments: Array<{ __typename?: 'segment', id: any, name: string, updated_date?: any | null, segment_audiences: Array<{ __typename?: 'audience_segment', audience: { __typename?: 'audience', id: any, name: string, status: string } }> }> };

export type GetTaxonomyQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Segment_Bool_Exp>;
  order_by: Array<Segment_Order_By> | Segment_Order_By;
}>;


export type GetTaxonomyQuery = { __typename?: 'Query', segment: Array<{ __typename?: 'segment', id: any, name: string, description: string, is_leaf?: boolean | null, is_archived?: boolean | null, is_nav_only?: boolean | null, segment_type?: string | null, segment_category?: string | null, segment_sub_category?: string | null, parent_segment?: any | null, path?: any | null, segment_source?: string | null, segment_source_description?: string | null }> };

export type GetTaxonomyDownloadQueryVariables = Exact<{
  where?: InputMaybe<Segment_Bool_Exp>;
  order_by: Array<Segment_Order_By> | Segment_Order_By;
}>;


export type GetTaxonomyDownloadQuery = { __typename?: 'Query', segment: Array<{ __typename?: 'segment', name: string, full_name?: string | null, description: string, segment_sub_category?: string | null, segment_category?: string | null, segment_type?: string | null, segment_source?: string | null, segment_source_description?: string | null }> };

export type GetTaxonomyMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTaxonomyMetadataQuery = { __typename?: 'Query', segment_aggregate: { __typename?: 'segment_aggregate', nodes: Array<{ __typename?: 'segment', segment_source?: string | null, segment_type?: string | null, segment_category?: string | null, segment_sub_category?: string | null }> } };

export type GetTaxonomySearchTotalQueryVariables = Exact<{
  where?: InputMaybe<Segment_Bool_Exp>;
}>;


export type GetTaxonomySearchTotalQuery = { __typename?: 'Query', segment_aggregate: { __typename?: 'segment_aggregate', aggregate?: { __typename?: 'segment_aggregate_fields', count: number } | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', delete_user_by_pk?: { __typename?: 'user', id: any } | null };

export type GetApiKeysQueryVariables = Exact<{
  organizationId: Scalars['uuid'];
}>;


export type GetApiKeysQuery = { __typename?: 'Query', user: Array<{ __typename?: 'user', first_name: string, expires_at?: string | null, created_date: any, id: any }> };

export type GetUsersListQueryVariables = Exact<{
  organizationIds?: InputMaybe<Array<Scalars['uuid']> | Scalars['uuid']>;
}>;


export type GetUsersListQuery = { __typename?: 'Query', user: Array<{ __typename?: 'user', id: any, first_name: string, last_name: string, email_address: string, role: string, is_active: boolean, firebase_id: string, last_signin_time?: string | null, created_date: any, account: { __typename?: 'account', id: any, name: string } }> };
